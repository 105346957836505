import React from "react";
import contactimg from "../images/contact.png";
import { FcPhone, FcInvite } from "react-icons/fc";
import { BiMap } from "react-icons/bi";
import Footer from "./Footer";
import { useForm } from "react-hook-form";
import { create } from "../Axios/axiosService";
import notify from "../Notify/notify";
import { FiAlertTriangle } from "react-icons/fi";

const Contact = () => {
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (data) => {
    create("/api/auth/contact", {
      fullName: data.fullName,
      email: data.email,
      subject: data.subject,
      message: data.message,
    })
      .then((res) => {
        notify.showSuccess(
          "Your query has been sent, wait for their response."
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <section id="contact-page">
        <h1 className="text-center pt-4 pb-4">HAVE SOME QUESTIONS?</h1>
        {/* <PublicSidebar /> */}
        <div className="contact-page text-center">
          <div className="contact-form col-lg-6 col-md-12 mb-4 animate__animated animate__fadeInLeft">
            <h3>Submit Your Queries!</h3>
            <hr />
            <form className="form-group pt-3" onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-3 form-group">
                <input
                  type="text"
                  className="form-control"
                  name="fullName"
                  id="fullName"
                  placeholder="Full Name"
                  ref={register({
                    required: "Required",
                  })}
                />
                {errors.fullName ? (
                  <p
                    style={{
                      color: "red",
                      marginBottom: "-10px",
                      fontSize: "12px",
                    }}
                  >
                    <FiAlertTriangle /> {errors.fullName.message}
                  </p>
                ) : null}
              </div>
              <div className="mb-3 form-group">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  id="email"
                  placeholder="Your Email Address"
                  ref={register({
                    required: "Required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  })}
                />
                {errors.email ? (
                  <p
                    style={{
                      color: "red",
                      marginBottom: "-10px",
                      fontSize: "12px",
                    }}
                  >
                    <FiAlertTriangle /> {errors.email.message}
                  </p>
                ) : null}
              </div>
              <div className="mb-3 form-group">
                <input
                  type="text"
                  className="form-control"
                  name="subject"
                  id="subject"
                  placeholder="Subject"
                  ref={register({
                    required: "Required",
                  })}
                />
                {errors.subject ? (
                  <p
                    style={{
                      color: "red",
                      marginBottom: "-10px",
                      fontSize: "12px",
                    }}
                  >
                    <FiAlertTriangle /> {errors.subject.message}
                  </p>
                ) : null}
              </div>
              <div className="mb-3 form-group">
                <textarea
                  type="text"
                  className="form-control"
                  name="message"
                  id="message"
                  placeholder="Message"
                  ref={register({
                    required: "Required",
                  })}
                />
                {errors.message ? (
                  <p
                    style={{
                      color: "red",
                      marginBottom: "-10px",
                      fontSize: "12px",
                    }}
                  >
                    <FiAlertTriangle /> {errors.message.message}
                  </p>
                ) : null}
              </div>
              <div className=" text-center">
                <button type="submit" className="signupbtnLogin w-100 mt-3">
                  SEND MESSAGE
                </button>
              </div>
            </form>
          </div>
          <div className="contacting col-lg-6 col-md-12  mb-3 animate__animated animate__fadeInRight">
            <img
              alt=""
              src={contactimg}
              className="img-fluid"
              style={{ height: "300px" }}
            />
          </div>
        </div>
      </section>

      <section id="contact-page1">
        <div className="contact-page1 pt-5">
          <div className="touch col-lg-5 col-md-12 ">
            <h1>GET IN TOUCH</h1>
            <p
              className="mt-4"
              style={{ fontSize: "20px", textAlign: "justify" }}
            >
              Got a question? Send us an email or chat to out contact persons
              when our offices are open.
            </p>
            <ul>
              <li>
                <FcInvite className="mr-2" /> infostozy@gmail.com
              </li>
              <li>
                <BiMap className="mr-2" /> Raffles Place, Singapore
              </li>
            </ul>
          </div>
          <div className="map-section col-lg-6 col-md-12">
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.81573698587!2d103.84915601426553!3d1.2844890621409624!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da1909455a0d1f%3A0x776490e9ea8fbd16!2sRaffles%20Pl%2C%20Singapore!5e0!3m2!1sen!2snp!4v1650175653028!5m2!1sen!2snp"
              width="90%"
              height="300"
              frameborder="0"
              aria-hidden="false"
              tabindex="0"
            ></iframe>
            
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
export default Contact;
