import React, { useEffect, useState } from "react";
import { create } from "../Axios/axiosService";
import { useParams, Link } from "react-router-dom";
import Footer from "../Public/Footer";
// /api/auth/email-verify
const Verify = (props) => {
  const [response, setResponse] = useState("");
  const { id } = useParams();

  useEffect(() => {
    Verify();
  // eslint-disable-next-line
  }, []);

  const Verify = () => {
    create("/api/auth/owner/email-verify", {
      token: id,
    })
      .then((res) => {
        setResponse("Your account has been verified");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="verify text-center p-5 mb-5">
        {" "}
        <h1 className="text-center pt-5 p-3"> {response}</h1>
        <h4 className="text-center">Go to Login Page to continue.</h4>
        <Link
          to="/login"
          className="signupbtnLogin1 mt-3"
          style={{ height: "min-content" }}
        >
          <span>Login Page</span>
        </Link>
      </div>
      <Footer />
    </>
  );
};

export default Verify;
