import React, { useState, useEffect } from "react";
import "../App.css";
import { Link } from "react-router-dom";
import group2 from "../images/Group2.png";
import Stozy1 from "../images/stozy1.png";
import Stozy2 from "../images/stozy2.png";
import Stozy3 from "../images/stozy3.png";
import { read } from "../Axios/axiosService";
import AOS from "aos";
import Ready from "./Ready";
import Footer from "./Footer";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { create } from "../Axios/axiosService";
import notify from "../Notify/notify";
import { FiAlertTriangle } from "react-icons/fi";
import {
  FaArrowAltCircleLeft,
  FaArrowRight,
  FaBoxOpen,
  FaLocationArrow,
  FaLongArrowAltRight,
} from "react-icons/fa";
import classNames from "classnames";
import Loader from "../service/loader/loader";
import NewMap from "../Map/NewMap";
import diff2 from "../images/diff2.png";
import diff1 from "../images/diff1.png";
import diff3 from "../images/diff3.png";
import diff4 from "../images/diff4.png";
import type1 from "../images/type1.png";
import type2 from "../images/type2.png";
import type3 from "../images/type3.png";
import type4 from "../images/type4.png";
import type5 from "../images/type5.png";
import homepage1 from "../images/landingpage.png";
import logo from "../images/logo.png";
import { getLocalStorage } from "../Axios/tokenStorage";
import { Redirect } from "react-router-dom";
import { role, token } from "../Axios/baseURL";
import { base_URL } from "../Axios/baseURL";

const Home = (props) => {
  const { register, handleSubmit, errors, reset } = useForm();
  const history = useHistory();
  const [featuredData, setFeaturedData] = useState([]);
  const [stozy, setStozy] = useState("stozy");
  const [loader, setLoader] = useState(false);
  const [storagePlan, setStoragePlan] = useState("");
  const [location1, setLocation1] = useState("");
  const [location2, setLocation2] = useState("");
  const [location3, setLocation3] = useState("");
  const [location4, setLocation4] = useState("");

  const [unit, setUnit] = useState("");
  const [price, setPrice] = useState(0);
  const [size, setSize] = useState(0);

  useEffect(() => {
    AOS.init({
      // initialise with other settings
      duration: 2000,
    });
  }, []);

  //location coordinate function
  const locationCoordinate1 = (coordinate, location) => {
    setLocation1(location);
  };
  const locationCoordinate2 = (coordinate, location) => {
    setLocation2(location);
  };
  const locationCoordinate3 = (coordinate, location) => {
    setLocation3(location);
  };
  const locationCoordinate4 = (coordinate, location) => {
    setLocation4(location);
  };
  const {
    register: register2,
    handleSubmit: handleSubmit2,

    errors: errors2,
    //reset: reset2,
  } = useForm();

  const {
    register: register3,
    handleSubmit: handleSubmit3,

    errors: errors3,
    //reset: reset2,
  } = useForm();

  const {
    register: register4,
    errors: errors4,
    handleSubmit: handleSubmit4,
  } = useForm({
    mode: "onBlur",
  });

  const onSubmit4 = (data) => {
    setLoader(true);

    const myData = {
      space_size: data.space_size + " " + unit,
      start_date: data.start_date,
      pickup_time: data.pickup_time,
      plan_type: data.plan_type,
      storage_plan: data.storage_plan,
      message: data.message,
      location_of_interest: location3,
      storing_item: data.storing_item,
      price: size * price,
    };
    console.log("🚀 ~ file: Home.jsx ~ line 114 ~ onSubmit4 ~ myData", myData);

    create("/api/space/spacequery", myData)
      .catch((err) => {
        notify.handleError("Try again");
        setLoader(false);
      })
      .then((res) => {
        setStozy("stozy");
        notify.showSuccess("Your Space Query Send Successfully");
        setLoader(false);
      });
  };

  const onSubmit3 = (data) => {
    setLoader(true);

    const myData = {
      box_size: data.box_size,
      start_date: data.start_date,
      pickup_time: data.pickup_time,
      plan_type: data.plan_type,
      storage_plan: data.storage_plan,
      message: data.message,
      location_of_interest: location2,
      isFragile: data.isFragile,
      storing_item: data.storing_item,
      price: size * price,
    };

    create("api/box/boxquery", myData)
      .then((res) => {
        notify.showSuccess("Your Box Query Send Successfully");
        setLoader(false);
        setStozy("stozy");
      })
      .catch((err) => {
        notify.handleError(" Try again");
        console.log(err);
        setLoader(false);
      });
  };

  const onSubmit2 = (data) => {
    create("/api/user/findstozy", {
      location: data.location,
      storageType: data.storageType,
      listingType: data.listingType,

      planType: data.storingDuration,

      storingItem: data.storingItem,
      storingDuration: data.storingDuration,
    })
      .then((res) => {
        notify.showSuccess(
          "Your query has been sent, wait for their response."
        );
        reset();
      })
      .catch((err) => {
        console.log(err);
        notify.handleError(err);
      });
  };

  const onSubmit = (data) => {
    create("/api/stozy/search", {
      location: data.location,
      typeOfStorage: data.storageType,
      typeOfListing: data.listingType,
      planType: {
        weeklyPlan: data.weeklyPlan === true ? "available" : "not available",
        monthlyPlan: data.monthlyPlan === true ? "available" : "not available",
        yearlyPlan: data.yearlyPlan === true ? "available" : "not available",
      },
    })
      .then((res) => {
        history.push({
          pathname: "/search-location",
          state: { result: res.data },
        });
      })
      .catch((err) => {
        console.log(err);
        notify.handleError(err);
      });
  };

  useEffect(() => {
    getFeatData();
  }, []);
  const getFeatData = () => {
    read("/api/getallfeaturedproperty")
      .then((res) => {
        setFeaturedData(res.data.property.reverse());
      })
      .catch((err) => {
        if (err.response) {
          // notify.handleError(err.response.data.msg + ", Try again");
          console.log(err);
        }
      });
  };

  const availableFeaturedData = featuredData?.filter(
    (arg) => arg.booking_status === "available"
  );

  // console.log(availableFeaturedData);

  return (
    <>
      <section id="home-page0">
        <h1 className="newh1">
          Come together as a Community, <br /> and store together.
          <br />
        </h1>
        <h5>
          {" "}
          <Link to="/about">
            {" "}
            Learn More <FaLongArrowAltRight />{" "}
          </Link>
        </h5>
        <div className="container" style={{ marginTop: "-15px" }}>
          <div className="row justify-content-between align-items-center flex-wrap">
            <div className="col-lg-4">
              {/* <div className="sectionNew1 col-lg-5 animate__animated animate__fadeInLeft"> */}
              {stozy === "stozy" ? (
                <div>
                  {/* <h4 style={{ color: "rgb(53, 49, 49)", marginBottom: "10px" }}>
                  Book & Experience Amazing Storages!!
                </h4>
                <h5 className="mb-2">Your problem, Our Solution</h5> */}
                  {/* <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginBottom: "8px",
                    }}
                  >
                    <img
                      src={logo}
                      alt=""
                      width="40%"
                      className="img-fluid text-center my-3"
                    />
                  </div> */}
                  <button
                    onClick={() => setStozy("boxQuery")}
                    className="signupbtnLogin1"
                    style={{
                      width: "80%",
                      height: "min-content",
                      marginBottom: "10px",
                      fontSize: "15px",
                    }}
                  >
                    <span>STOZY by Box</span>
                  </button>
                  <button
                    onClick={() => setStozy("findSpace")}
                    className="signupbtnLogin1"
                    style={{
                      width: "80%",
                      height: "min-content",
                      marginTop: "20px",
                      fontSize: "15px",
                    }}
                  >
                    <span>STOZY by Space</span>
                  </button>
                </div>
              ) : null}
              {stozy === "findSpace" ? (
                <div>
                  <h5
                    style={{ color: "rgb(53, 49, 49)", textAlign: "left" }}
                    className="mb-1"
                  >
                    <FaArrowAltCircleLeft
                      className="backbtn"
                      style={{
                        cursor: "pointer",
                        color: "#1d6aba",
                      }}
                      onClick={() => setStozy("stozy")}
                    />{" "}
                    {/* Search your desired storage and store your stuffs easily. */}
                  </h5>

                  {/* <p className="text-center">We help you find a good Storage</p> */}
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginBottom: "8px",
                    }}
                  >
                    {" "}
                    <img
                      src={logo}
                      alt=""
                      width="40%"
                      className="img-fluid text-center my-3"
                    />
                  </div>
                  <button
                    onClick={() => setStozy("searchBox")}
                    className="signupbtnLogin1"
                    style={{
                      width: "100%",
                      height: "min-content",
                      marginBottom: "10px",
                    }}
                  >
                    <span>Find a STOZY space </span>
                  </button>

                  <button
                    onClick={() => setStozy("spaceQuery")}
                    className="signupbtnLogin1"
                    style={{
                      width: "100%",
                      height: "min-content",
                      marginTop: "20px",
                      fontSize: "14px",
                    }}
                  >
                    <span>We can find you STOZY space </span>
                  </button>
                </div>
              ) : null}

              {stozy === "searchBox" ? (
                <div>
                  <h5
                    style={{
                      color: "rgb(53, 49, 49)",
                      textAlign: "left",
                      margin: "15px 0",
                    }}
                  >
                    <FaArrowAltCircleLeft
                      className="backbtn"
                      style={{ cursor: "pointer", color: "#1d6aba" }}
                      onClick={() => setStozy("stozy")}
                      // className="mb-3"
                    />{" "}
                    Search Storage
                  </h5>
                  {/* <h5
                  style={{
                    textAlign: "center",
                    marginBottom: "25px",
                  }}
                >
                  Your Problem, our Solution.
                </h5> */}
                  {/* <div className="d-flex justify-content-between flex-wrap">
                  <p>Want to Store by Box? </p>
                  <Link className="" to="dashboard-add-box">
                    <FaBoxOpen /> Store By Box
                  </Link>
                </div> */}

                  <form
                    className="mt-1"
                    key={1}
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="form-outline mb-3">
                      {/* <input
                      type="text"
                      className="form-control"
                      name="location"
                      placeholder="Location of Interest"
                      ref={register({ required: true })}
                    /> */}
                      <NewMap
                        locationCoordinate={locationCoordinate1}
                        name="location"
                        // errors={errors}
                        register={register}
                      />
                      {errors.location ? (
                        <p
                          style={{
                            color: "red",
                            marginBottom: "-10px",
                          }}
                        >
                          <FiAlertTriangle /> {errors.location.message}
                        </p>
                      ) : null}
                    </div>

                    <div className="row mb-3">
                      <div className="col-sm-12 col-md-6 mb-3">
                        <div className="form-outline">
                          <select
                            className="form-control"
                            name="listingType"
                            ref={register()}
                          >
                            <option value="">Type of Listing</option>
                            <option value="small room">Small Room</option>
                            <option value="small space">Small Space</option>
                            <option value="closet space">Closet Space</option>
                            <option value="large space">Large Space</option>
                            <option value="others">Others</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-6 mb-sm-3">
                        <div className="form-outline">
                          <select
                            name="storageType"
                            className="form-control"
                            ref={register()}
                          >
                            <option value="" style={{ fontSize: "10px" }}>
                              Type of Storage
                            </option>
                            <option value="small personal items">
                              Small Personal Items
                            </option>
                            <option value="small household items">
                              Small Household Items
                            </option>
                            <option value="large household items">
                              Large Household Items
                            </option>
                            <option value="vehicles">Vehicles</option>
                            <option value="others">Others</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div
                      className="row form-check mb-4"
                      style={{ marginLeft: "-15px" }}
                    >
                      <label className="form-label">Choose Plan Type:</label>{" "}
                      <br />
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="weeklyPlan"
                          id="weeklyPlan"
                          ref={register()}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineCheckbox1"
                        >
                          Weekly
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="monthlyPlan"
                          id="monthlyPlan"
                          ref={register()}
                        />
                        <label className="form-check-label">Monthly</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="yearlyPlan"
                          name="yearlyPlan"
                          ref={register()}
                        />
                        <label className="form-check-label">Yearly</label>
                      </div>
                    </div>

                    <button
                      className="signupbtnLogin1"
                      style={{
                        width: "100%",
                        height: "min-content",
                        fontSize: "15px",
                      }}
                    >
                      <span>Search Location </span>
                    </button>
                    <p className="text-center mt-1 mb-0">OR</p>
                    <Link
                      // to="/we-can-find-you"
                      className="signupbtnLogin1 mt-2"
                      style={{
                        width: "100%",
                        display: "inline-block",
                        fontSize: "14px",
                      }}
                      data-toggle="modal"
                      data-target="#wecanfindyou"
                    >
                      <span>We can find you STOZY Space</span>
                    </Link>
                  </form>
                </div>
              ) : null}
              {stozy === "boxQuery" ? (
                <>
                  {getLocalStorage(role) === "renter" ? (
                    // <section id="main-dashboard">
                    <>
                      <div className="dashboard-content-home">
                        <section
                          id="add-listing-here-home"
                          className="animate__animated animate__fadeInUp"
                        >
                          <div className="add-listing">
                            <h5 className="text-left">
                              <FaArrowAltCircleLeft
                                className="backbtn"
                                style={{
                                  cursor: "pointer",
                                  color: "#1d6aba",
                                }}
                                onClick={() => setStozy("stozy")}
                                // className="mb-3"
                              />
                              &nbsp;REQUEST BOX | BASIC DETAILS
                            </h5>
                            <hr className="mb-3" />
                            <form onSubmit={handleSubmit3(onSubmit3)}>
                              <div className="row mb-4">
                                <div className="col-md-3 col-lg-12 mb-3">
                                  <div className="form-outline">
                                    <label className="form-label">
                                      Box Size
                                    </label>
                                    <select
                                      type="text"
                                      name="box_size"
                                      id="box_size"
                                      onClick={(e) =>
                                        setSize(
                                          e.target.value === "Small"
                                            ? 200
                                            : e.target.value === "Medium"
                                            ? 400
                                            : e.target.value === "Large"
                                            ? 600
                                            : 0
                                        )
                                      }
                                      className={classNames("form-control", {
                                        "is-invalid": errors3.box_size,
                                      })}
                                      placeholder="Enter the Box Size."
                                      ref={register3({
                                        required: "Required Field ",
                                        minLength: {
                                          value: 5,
                                          message: "Select One",
                                        },
                                      })}
                                    >
                                      <option value="">Select One</option>
                                      <option value="Small">Small</option>
                                      <option value="Medium">Medium</option>
                                      <option value="Large">Large</option>
                                    </select>
                                    {errors3.box_size ? (
                                      <p
                                        style={{
                                          color: "red",
                                          marginBottom: "-10px",
                                        }}
                                      >
                                        <FiAlertTriangle />{" "}
                                        {errors3.box_size.message}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>

                                <div className="col-md-3 col-lg-12 mb-3">
                                  <div className="form-outline">
                                    <label className="form-label storage-type">
                                      Start Date
                                    </label>
                                    <input
                                      type="date"
                                      id="start_date"
                                      name="start_date"
                                      className={classNames("form-control", {
                                        "is-invalid": errors3.start_date,
                                      })}
                                      ref={register3({
                                        required: true,
                                      })}
                                    />
                                    {errors3.start_date && (
                                      <p
                                        style={{
                                          color: "red",
                                          marginBottom: "-10px",
                                        }}
                                      >
                                        <FiAlertTriangle /> Required Field
                                      </p>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-3 col-lg-12 mb-3">
                                  <div className="form-outline">
                                    <label
                                      className="form-label"
                                      htmlFor="listing-title"
                                    >
                                      PickUp Time
                                    </label>
                                    <input
                                      type="time"
                                      name="pickup_time"
                                      className="form-control"
                                      ref={register3({
                                        required: "Required",
                                      })}
                                    />
                                    {errors3.pickup_time ? (
                                      <p
                                        style={{
                                          color: "red",
                                          marginBottom: "-10px",
                                        }}
                                      >
                                        <FiAlertTriangle />{" "}
                                        {errors3.pickup_time.message}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="col-md-3 col-lg-12">
                                  <label
                                    className="form-label"
                                    htmlFor="listingType"
                                  >
                                    Storing Items
                                  </label>
                                  <input
                                    type="text"
                                    name="storing_item"
                                    id="storing_item"
                                    placeholder="Enter Storing Items"
                                    className={classNames("form-control", {
                                      "is-invalid": errors3.storing_item,
                                    })}
                                    ref={register3({
                                      required: "Required Field ",
                                    })}
                                  />

                                  {errors3.storing_item ? (
                                    <p
                                      style={{
                                        color: "red",
                                        marginBottom: "-10px",
                                      }}
                                    >
                                      <FiAlertTriangle />{" "}
                                      {errors3.storing_item.message}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-4 col-lg-12 mb-3">
                                  <label className="form-label">Fragile</label>
                                  <select
                                    type="text"
                                    name="isFragile"
                                    id="isFragile"
                                    className={classNames("form-control", {
                                      "is-invalid": errors3.isFragile,
                                    })}
                                    placeholder="Select One"
                                    ref={register3({
                                      required: "Required Field ",
                                      minLength: {
                                        message: "Select One",
                                      },
                                    })}
                                  >
                                    <option value="">Select One</option>
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                  </select>
                                  {errors3.isFragile ? (
                                    <p
                                      style={{
                                        color: "red",
                                        marginBottom: "-10px",
                                      }}
                                    >
                                      <FiAlertTriangle />{" "}
                                      {errors3.isFragile.message}
                                    </p>
                                  ) : null}
                                </div>
                                <div className="col-md-4 col-lg-12 mb-3">
                                  <label className="form-label">
                                    Plan Type
                                  </label>
                                  <select
                                    type="text"
                                    name="plan_type"
                                    id="isFragile"
                                    className={classNames("form-control", {
                                      "is-invalid": errors3.plan_type,
                                    })}
                                    placeholder="Select One"
                                    onClick={(e) => {
                                      setStoragePlan(e.target.value);
                                      setPrice(
                                        e.target.value === "Weekly"
                                          ? 1
                                          : e.target.value === "Monthly"
                                          ? 5
                                          : e.target.value === "Yearly"
                                          ? 52
                                          : 0
                                      );
                                    }}
                                    ref={register3({
                                      required: "Required Field ",
                                      minLength: {
                                        value: 5,
                                        message: "Select One",
                                      },
                                    })}
                                  >
                                    <option value="">Select One</option>
                                    <option value="Weekly">Weekly</option>
                                    <option value="Monthly">Monthly</option>
                                    <option value="Yearly">Yearly</option>
                                  </select>
                                  {errors3.plan_type ? (
                                    <p
                                      style={{
                                        color: "red",
                                        marginBottom: "-10px",
                                      }}
                                    >
                                      <FiAlertTriangle />{" "}
                                      {errors3.plan_type.message}
                                    </p>
                                  ) : null}
                                </div>
                                <div className="col-md-4 col-lg-12 mb-3">
                                  <label className="form-label">
                                    Storage Type
                                  </label>
                                  <select
                                    type="text"
                                    name="storage_plan"
                                    id="isFragile"
                                    className={classNames("form-control", {
                                      "is-invalid": errors3.storage_plan,
                                    })}
                                    placeholder="Select One"
                                    ref={register3({
                                      required: "Required Field ",
                                      minLength: {
                                        message: "Select One",
                                      },
                                    })}
                                  >
                                    <option value="">Select One</option>
                                    {storagePlan === "Weekly" ? (
                                      <>
                                        <option value="A Week">A Week</option>
                                        <option value=" Several Weekly">
                                          Several Weekly
                                        </option>
                                      </>
                                    ) : null}
                                    {storagePlan === "Monthly" ? (
                                      <>
                                        <option value="A Month">A Month</option>
                                        <option value=" Several Month">
                                          Several Month
                                        </option>
                                      </>
                                    ) : null}
                                    {storagePlan === "Yearly" ? (
                                      <>
                                        <option value="A Year">A Year</option>
                                        <option value=" Several Year">
                                          Several Year
                                        </option>
                                      </>
                                    ) : null}
                                  </select>
                                  {errors3.storage_plan ? (
                                    <p
                                      style={{
                                        color: "red",
                                        marginBottom: "-10px",
                                      }}
                                    >
                                      <FiAlertTriangle />{" "}
                                      {errors3.storage_plan.message}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <hr className="mt-2" />
                              <h5>Location Of Interest</h5>
                              <hr className="mb-3" />
                              <div className="row mb-4">
                                <div className="col-sm">
                                  <NewMap
                                    locationCoordinate={locationCoordinate2}
                                    name="location"
                                    // errors={errors}
                                    register={register3}
                                  />
                                  {errors3.location ? (
                                    <p
                                      style={{
                                        color: "red",
                                        marginBottom: "-10px",
                                      }}
                                    >
                                      <FiAlertTriangle />{" "}
                                      {errors3.location.message}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="form-outline mb-4">
                                <label className="form-label">Message</label>
                                <textarea
                                  type="text"
                                  name="message"
                                  id="message"
                                  className={classNames("form-control", {
                                    "is-invalid": errors3.message,
                                  })}
                                  placeholder="Message"
                                  ref={register3()}
                                />
                              </div>
                              {/* <h5>Choose Package</h5>
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="price"
                                id="flexRadioDefault1"
                                onClick={() => setPrice(1)}
                                ref={register3({ required: "Required Field" })}
                              />
                              <label
                                class="form-check-label"
                                for="flexRadioDefault1"
                              >
                                Weekly
                              </label>
                            </div>
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="price"
                                id="flexRadioDefault2"
                                onClick={() => setPrice(5)}
                                ref={register3({ required: "Required Field" })}
                              />
                              <label
                                class="form-check-label"
                                for="flexRadioDefault2"
                              >
                                Monthly
                              </label>
                            </div>
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                name="price"
                                type="radio"
                                id="flexRadioDefault2"
                                onClick={() => setPrice(52)}
                                ref={register3({ required: "Required Field" })}
                              />
                              <label
                                class="form-check-label"
                                for="flexRadioDefault2"
                              >
                                Yearly
                              </label>
                            </div>
                            {errors3.price ? (
                              <p
                                style={{ color: "red", marginBottom: "-10px" }}
                              >
                                <FiAlertTriangle /> {errors3.price.message}
                              </p>
                            ) : null} */}
                              {/* <h2>Cost : ${size*price} </h2>*/}

                              <button
                                type="submit"
                                className="signupbtnLogin btn-primary btn-block mb-4 mt-4 ml-auto mr-auto"
                                style={{ width: "max-content" }}
                              >
                                Submit
                              </button>
                            </form>
                          </div>
                        </section>
                      </div>
                      <Loader loader={loader} />{" "}
                    </>
                  ) : (
                    // </section>
                    <Redirect
                      to={{
                        pathname: "/login",
                        state: { from: props.location },
                      }}
                    />
                  )}
                </>
              ) : null}
              {stozy === "spaceQuery" ? (
                <>
                  {getLocalStorage(token) ? (
                    <div className="dashboard-content-home">
                      <h5 className="text-left">
                        <FaArrowAltCircleLeft
                          className="backbtn"
                          style={{
                            cursor: "pointer",
                            color: "#1d6aba",
                          }}
                          onClick={() => setStozy("stozy")}
                          // className="mb-3"
                        />
                        &nbsp; REQUEST SPACE | BASIC DETAILS
                      </h5>
                      <hr />
                      <form
                        className="mt-3 "
                        onSubmit={handleSubmit4(onSubmit4)}
                      >
                        <div className="row mb-4">
                          <div className="col-md-3 col-lg-12 mb-3">
                            <div className="form-outline">
                              <label className="form-label">Space Size</label>
                              <div>
                                <form check>
                                  <label check>
                                    <input
                                      type="radio"
                                      name="radio1"
                                      onClick={(e) => setUnit("Square Feet")}
                                    />
                                    Square Feet
                                  </label>
                                  <label check>
                                    <input
                                      type="radio"
                                      name="radio1"
                                      onClick={(e) => setUnit("Square Meter")}
                                    />
                                    Square Meter
                                  </label>
                                </form>
                              </div>
                              <input
                                type="text"
                                name="space_size"
                                id="space_size"
                                onClick={(e) =>
                                  setSize(
                                    e.target.value === "Small"
                                      ? 200
                                      : e.target.value === "Medium"
                                      ? 400
                                      : e.target.value === "Large"
                                      ? 600
                                      : 0
                                  )
                                }
                                className={classNames("form-control", {
                                  "is-invalid": errors4.Space_size,
                                })}
                                placeholder="Required space Size."
                                ref={register4({
                                  required: "Required Field ",
                                })}
                              ></input>
                              {errors4.space_size ? (
                                <p
                                  style={{
                                    color: "red",
                                    marginBottom: "-10px",
                                  }}
                                >
                                  <FiAlertTriangle />{" "}
                                  {errors4.space_size.message}
                                </p>
                              ) : null}
                            </div>
                          </div>

                          <div className="col-md-3 col-lg-12 mb-3">
                            <div className="form-outline">
                              <label className="form-label storage-type">
                                Start Date
                              </label>
                              <input
                                type="date"
                                id="start_date"
                                name="start_date"
                                className={classNames("form-control", {
                                  "is-invalid": errors4.start_date,
                                })}
                                ref={register4({
                                  required: true,
                                })}
                              />
                              {errors4.start_date && (
                                <p
                                  style={{
                                    color: "red",
                                    marginBottom: "-10px",
                                  }}
                                >
                                  <FiAlertTriangle /> Required Field
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3 col-lg-12 mb-3">
                            <div className="form-outline">
                              <label
                                className="form-label"
                                htmlFor="listing-title"
                              >
                                PickUp Time
                              </label>
                              <input
                                type="time"
                                name="pickup_time"
                                className="form-control"
                                ref={register4({
                                  required: "Required",
                                })}
                              />
                              {errors4.pickup_time ? (
                                <p
                                  style={{
                                    color: "red",
                                    marginBottom: "-10px",
                                  }}
                                >
                                  <FiAlertTriangle />{" "}
                                  {errors4.pickup_time.message}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-3 col-lg-12">
                            <label className="form-label" htmlFor="listingType">
                              Storing Items
                            </label>
                            <input
                              type="text"
                              name="storing_item"
                              id="storing_item"
                              placeholder="Enter Storing Items"
                              className={classNames("form-control", {
                                "is-invalid": errors4.storing_item,
                              })}
                              ref={register4({
                                required: "Required Field ",
                              })}
                            />

                            {errors4.storing_item ? (
                              <p
                                style={{
                                  color: "red",
                                  marginBottom: "-10px",
                                }}
                              >
                                <FiAlertTriangle />{" "}
                                {errors4.storing_item.message}
                              </p>
                            ) : null}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4 col-lg-12 mb-3">
                            <label className="form-label">Plan Type</label>
                            <select
                              type="text"
                              name="plan_type"
                              id="isFragile"
                              className={classNames("form-control", {
                                "is-invalid": errors4.plan_type,
                              })}
                              placeholder="Select One"
                              onClick={(e) => {
                                setStoragePlan(e.target.value);
                                setPrice(
                                  e.target.value === "Weekly"
                                    ? 1
                                    : e.target.value === "Monthly"
                                    ? 5
                                    : e.target.value === "Yearly"
                                    ? 52
                                    : 0
                                );
                              }}
                              ref={register4({
                                required: "Required Field ",
                                minLength: {
                                  value: 5,
                                  message: "Select One",
                                },
                              })}
                            >
                              <option value="">Select One</option>
                              <option value="Weekly">Weekly</option>
                              <option value="Monthly">Monthly</option>
                              <option value="Yearly">Yearly</option>
                            </select>
                            {errors4.plan_type ? (
                              <p
                                style={{
                                  color: "red",
                                  marginBottom: "-10px",
                                }}
                              >
                                <FiAlertTriangle /> {errors4.plan_type.message}
                              </p>
                            ) : null}
                          </div>
                          <div className="col-md-4 col-lg-12 mb-3">
                            <label className="form-label">Storage Type</label>
                            <select
                              type="text"
                              name="storage_plan"
                              id="isFragile"
                              className={classNames("form-control", {
                                "is-invalid": errors4.storage_plan,
                              })}
                              placeholder="Select One"
                              ref={register4({
                                required: "Required Field ",
                                minLength: {
                                  message: "Select One",
                                },
                              })}
                            >
                              <option value="">Select One</option>
                              {storagePlan === "Weekly" ? (
                                <>
                                  <option value="A Week">A Week</option>
                                  <option value=" Several Week">
                                    Several Week
                                  </option>
                                </>
                              ) : null}
                              {storagePlan === "Monthly" ? (
                                <>
                                  <option value="A Month">A Month</option>
                                  <option value=" Several Month">
                                    Several Month
                                  </option>
                                </>
                              ) : null}
                              {storagePlan === "Yearly" ? (
                                <>
                                  <option value="A Year">A Year</option>
                                  <option value=" Several Year">
                                    Several Year
                                  </option>
                                </>
                              ) : null}
                            </select>
                            {errors4.storage_plan ? (
                              <p
                                style={{
                                  color: "red",
                                  marginBottom: "-10px",
                                }}
                              >
                                <FiAlertTriangle />{" "}
                                {errors4.storage_plan.message}
                              </p>
                            ) : null}
                          </div>
                        </div>
                        <hr className="mt-2" />
                        <h5>Location Of Interest</h5>
                        <hr className="mb-3" />
                        <div className="row mb-4">
                          <div className="col-sm">
                            {/* <Autofill /> */}
                            {/* <AddMap /> */}
                            <NewMap
                              locationCoordinate={locationCoordinate3}
                              name="location"
                              // errors={errors}
                              register={register4}
                            />
                            {errors4.location ? (
                              <p
                                style={{
                                  color: "red",
                                  marginBottom: "-10px",
                                }}
                              >
                                <FiAlertTriangle /> {errors4.location.message}
                              </p>
                            ) : null}
                          </div>
                        </div>
                        <div className="form-outline mb-4">
                          <label className="form-label">Message</label>
                          <textarea
                            type="text"
                            name="message"
                            id="message"
                            className={classNames("form-control", {
                              "is-invalid": errors4.message,
                            })}
                            placeholder="Message"
                            ref={register4()}
                          />
                        </div>
                        {/* <h5>Choose Package</h5>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="price"
                          id="flexRadioDefault1"
                          onClick={() => setPrice(1)}
                          ref={register4({
                            required: "Required Field",
                            minLength: {
                              message: "choose one",
                            },
                          })}
                        />
                        <label class="form-check-label" for="flexRadioDefault1">
                          Weekly
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="price"
                          id="flexRadioDefault2"
                          onClick={() => setPrice(5)}
                          ref={register4({
                            required: "Required Field",
                            minLength: {
                              message: "choose one",
                            },
                          })}
                        />
                        <label class="form-check-label" for="flexRadioDefault2">
                          Monthly
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          name="price"
                          type="radio"
                          id="flexRadioDefault2"
                          onClick={() => setPrice(52)}
                          ref={register4({
                            required: "Required Field",
                            minLength: {
                              message: "choose one",
                            },
                          })}
                        />
                        <label class="form-check-label" for="flexRadioDefault2">
                          Yearly
                        </label>
                      </div>
                      {errors4.price ? (
                        <p style={{ color: "red", marginBottom: "-10px" }}>
                          <FiAlertTriangle /> {errors4.price.message}
                        </p>
                      ) : null} */}
                        {/* <h2>Cost : ${size*price} </h2>*/}
                        <button
                          type="submit"
                          className="signupbtnLogin btn-primary btn-block mb-4 mt-4 ml-auto mr-auto"
                          style={{ width: "max-content" }}
                        >
                          Submit
                        </button>
                        <Loader loader={loader} />{" "}
                      </form>
                    </div>
                  ) : (
                    <Redirect to="/login" />
                  )}
                </>
              ) : null}
              {/* </div> */}

              {/* {We can find you modal body} */}
              <div
                className="modal fade"
                id="wecanfindyou"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-md modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header text-center">
                      <h5 className="modal-title" id="exampleModalLabel">
                        We will find you a
                        <span style={{ color: "#1d6aba" }}> STOZY </span>{" "}
                        Location.
                      </h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body align-items-center">
                      <div className="home-container mb-5">
                        {/* <div className="section2 col-lg-6 col-md-12 mb-5">
                      <div className="text-center align-items-center">
                        <h3 style={{ color: "rgb(53, 49, 49)" }}>
                          Don't worry we will find you a Perfect Stozy Location.
                        </h3>
                        <img alt="" src={group1} className="image1 img-fluid" />
                      </div>
                    </div> */}
                        <div className="sectionNew1 col-lg-12 col-md-12">
                          <h3
                            className="text-center"
                            style={{ color: "rgb(53, 49, 49)" }}
                          >
                            Please let us know, what do you want?
                          </h3>

                          <form
                            className="mt-3 "
                            onSubmit={handleSubmit2(onSubmit2)}
                          >
                            <div className="form-outline mb-4">
                              {/* <input
                            type="text"
                            className="form-control"
                            name="location"
                            placeholder="Location of Interest"
                            ref={register2({
                              required: "Required Field ",
                            })}
                          />
                          {errors.location ? (
                            <p style={{ color: "red", marginBottom: "-10px" }}>
                              <FiAlertTriangle /> {errors.location.message}
                            </p>
                          ) : null} */}
                              <NewMap
                                locationCoordinate={locationCoordinate4}
                                name="location"
                                // errors={errors}
                                register={register2}
                              />
                              {errors2.location ? (
                                <p
                                  style={{
                                    color: "red",
                                    marginBottom: "-10px",
                                  }}
                                >
                                  <FiAlertTriangle /> {errors2.location.message}
                                </p>
                              ) : null}
                            </div>

                            <div className="form-outline  mb-4">
                              <select
                                className="form-control"
                                name="listingType"
                                ref={register2({
                                  required: "Required Field ",
                                })}
                              >
                                <option value="">Type of Listing</option>
                                <option value="small room">Small Room</option>
                                <option value="small space">Small Space</option>
                                <option value="closet space">
                                  Closet Space
                                </option>
                                <option value="large space">Large Space</option>
                                <option value="others">Others</option>
                              </select>
                              {errors.listingType ? (
                                <p
                                  style={{
                                    color: "red",
                                    marginBottom: "-10px",
                                  }}
                                >
                                  <FiAlertTriangle />{" "}
                                  {errors.listingType.message}
                                </p>
                              ) : null}
                            </div>
                            <div className="form-outline mb-4">
                              <select
                                name="storageType"
                                className="form-control"
                                ref={register2({
                                  required: "Required Field",
                                })}
                              >
                                <option value="">Type of Storage</option>
                                <option value="small personal items">
                                  Small Personal Items
                                </option>
                                <option value="small household items">
                                  Small Household Items
                                </option>
                                <option value="large household items">
                                  Large Household Items
                                </option>
                                <option value="vehicles">Vehicles</option>
                                <option value="others">Others</option>
                              </select>
                              {errors.storageType ? (
                                <p
                                  style={{
                                    color: "red",
                                    marginBottom: "-10px",
                                  }}
                                >
                                  <FiAlertTriangle />{" "}
                                  {errors.storageType.message}
                                </p>
                              ) : null}
                            </div>

                            <div className="form-outline mb-4">
                              <input
                                type="text"
                                className="form-control"
                                name="storingItem"
                                placeholder="What do you want to Store?"
                                ref={register2({
                                  required: "Required Field",
                                })}
                              />
                              {errors.storingItem ? (
                                <p
                                  style={{
                                    color: "red",
                                    marginBottom: "-10px",
                                  }}
                                >
                                  <FiAlertTriangle />{" "}
                                  {errors.storingItem.message}
                                </p>
                              ) : null}
                            </div>
                            <div className="form-outline mb-4">
                              <select
                                name="storingDuration"
                                className="form-control"
                                ref={register2({
                                  required: "Required field",
                                })}
                              >
                                <option value="">
                                  How Long do you Plan for Storing?
                                </option>
                                <option value="a week">A Week</option>
                                <option value="several week">
                                  Several Week
                                </option>
                                <option value="a month">A Month</option>
                                <option value="several months">
                                  Several Months
                                </option>
                                <option value="a year">A year</option>
                                <option value="several year">
                                  Several Year
                                </option>
                              </select>
                              {errors.storingDuration ? (
                                <p
                                  style={{
                                    color: "red",
                                    marginBottom: "-10px",
                                  }}
                                >
                                  <FiAlertTriangle />
                                  {errors.storingDuration.message}
                                </p>
                              ) : null}
                            </div>

                            <button
                              type="submit"
                              className="signupbtnLogin"
                              style={{ width: "100%" }}
                            >
                              Submit
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <img
                alt=""
                src={homepage1}
                className="img-fluid"
                style={{
                  maxWidth: "110%",
                  alignItems: "start",
                }}
              />
            </div>
          </div>
        </div>
      </section>

      <section id="home-page3">
        <div className="main-row">
          <h1>IT'S SO EASY TO STOZY</h1>
        </div>
        <div className="hsection3 d-flex flex-wrap text-center">
          <div
            className="col-lg-4 howit1 text-center"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <img alt="" src={Stozy1} style={{ height: "250px" }} />
            <h3 className="mt-3" style={{ color: "#1d6aba" }}>
              1) Find your perfect fit
            </h3>
            <p>
              Use our filters - like product type, size, location, and duration{" "}
              - to personalize your search and locate the ideal listing.
            </p>
          </div>
          <div
            className="col-lg-4 howit1 "
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <img alt="" src={Stozy2} style={{ height: "250px" }} />
            <h3 className="mt-3" style={{ color: "#1d6aba" }}>
              2) It's all in details
            </h3>
            <p>
              Browse through the listing photos, it's reviews and accompanying
              details. Satisfied? Create a booking and you are done.
            </p>
          </div>
          <div
            className="col-lg-4 howit1"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            <img alt="" src={Stozy3} style={{ height: "250px" }} />
            <h3 className="mt-3" style={{ color: "#1d6aba" }}>
              3) Safe Storage
            </h3>
            <p>
              We verify all the listings to ensure you are getting exactly
              what's described. Our rigorous security checks ensure that your
              items are safely stored a few miles from home!
            </p>
          </div>
        </div>

        <div className="text-center mt-5">
          <Link to="/about" className="signupbtnLoginAlt">
            <span>KNOW MORE ABOUT STOZY</span>
          </Link>
        </div>
      </section>
      <section id="different">
        <div className="different">
          <h1 className="text-center">What makes STOZY different?</h1>
          <div className="row justify-content-center">
            <div className="col-lg-10 mt-4 text-center">
              <img
                alt=""
                src={diff1}
                className="img-fluid"
                data-aos="fade-down-right"
                data-aos-duration="1000"
              />
              <h3
                style={{ color: "#1d6aba" }}
                data-aos="zoom-in"
                data-aos-duration="1000"
              >
                {" "}
                1. We are a community facility.
              </h3>
              <p>
                We make storage a shared economy where everyone benefits! We
                bring new storage options to areas where it is impossibe/super
                expensive to build storage warehouses.
              </p>
            </div>
          </div>
          <div className="row text-center mt-5 flex-wrap justify-content-center">
            <div
              className="col-lg-4 col-md-7"
              data-aos="fade-up-right"
              data-aos-duration="1000"
            >
              <img src={diff2} alt="" className="img-fluid" />
              <h4
                style={{ color: "#1d6aba" }}
                data-aos="zoom-in"
                data-aos-duration="1000"
              >
                {" "}
                2. Flexibility{" "}
              </h4>

              <p>
                We offer hassle-free storage and retrieval at your fingertips!
                Our extensive filters help you find the perfect storage space
                with easy access and recovery. With no long term binding
                contracts or any hidden fees, STOZY is as easy as 1-2-3!
              </p>
            </div>
            <div
              className="col-lg-4 col-md-7"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img src={diff3} alt="" className="img-fluid mt-5" />
              <h4
                style={{ color: "#1d6aba" }}
                data-aos="zoom-in"
                data-aos-duration="1000"
              >
                {" "}
                3. Pocket Friendly Prices{" "}
              </h4>{" "}
              <p>
                Our prices are so low because we match vacant spaces with people
                looking for storage, without incurring any capital costs. We
                believe in complete transparency, so you know exactly how much
                little you're paying when you STOZY.
              </p>
            </div>
            <div
              className="col-lg-4 col-md-7"
              data-aos="fade-up-left"
              data-aos-duration="1000"
            >
              <img src={diff4} alt="" className="img-fluid mt-5" />
              <h4
                style={{ color: "#1d6aba" }}
                data-aos="zoom-in"
                data-aos-duration="1000"
              >
                {" "}
                4. We have you covered
              </h4>

              <p>
                Our robust liability contracts and insurance policies make
                storage worry-free! For any assistance, we are just a phone-call
                away.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="flippableCard">
        <h1 className="text-center mt-4">Book Your STOZY Location Today !</h1>

        <div
          className="flippableCard  d-flex  flex-column flex-lg-row mt-4"
          data-aos="fade-right"
          data-aos-duration="1000"
        >
          <div className="col mt-4 mb-4">
            <div className="homepageCard card card-flip h-100">
              <div className="card-front text-white bg-light">
                <div className="card-body">
                  <img
                    src={type2}
                    alt="this is it"
                    width="100%"
                    style={{ height: "250px", objectFit: "contain" }}
                  />
                  <h4 className="card-title mt-3 text-center">Small Room</h4>
                </div>
              </div>
              <div className="card-back bg-white text-center">
                <div className="card-body">
                  <p className="card-title text-dark">
                    So What are Small Room?
                  </p>
                  <p className="card-text">
                    Items such as outdoor sporting goods, instruments, and small
                    furniture will be ideal for this space!
                  </p>
                  <Link
                    to="/search-location"
                    className="btn btn-outline-secondary"
                  >
                    Book Small Room
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col mt-4 mb-4">
            <div className="homepageCard card card-flip h-100">
              <div className="card-front text-white bg-light">
                <div className="card-body">
                  <img
                    src={type3}
                    alt="this is it"
                    width="100%"
                    style={{ height: "250px", objectFit: "contain" }}
                  />
                  <h4 className="card-title mt-3 text-center">Small Space</h4>
                </div>
              </div>
              <div className="card-back bg-white text-center">
                <div className="card-body">
                  <p className="card-title text-dark">
                    So What are Small Space?
                  </p>
                  <p className="card-text">
                    You can store small, personal & memorabilia items in a Small
                    Space.
                  </p>
                  <Link
                    to="/search-location"
                    className="btn btn-outline-secondary"
                  >
                    Book Small Space
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col mt-4 mb-4">
            <div className="homepageCard card card-flip h-100">
              <div className="card-front text-white bg-light">
                <div className="card-body">
                  <img
                    src={type5}
                    alt="this is it"
                    width="100%"
                    style={{ height: "250px", objectFit: "contain" }}
                  />
                  <h4 className="card-title mt-3 text-center">Closet Space</h4>
                </div>
              </div>
              <div className="card-back bg-white text-center">
                <div className="card-body">
                  <p className="card-title text-dark ">
                    So What are Closet Space?
                  </p>
                  <p className="card-text">
                    You can store specific items, which take up only some closet
                    space. These can be your army gear!
                  </p>
                  <Link
                    to="/search-location"
                    className="btn btn-outline-secondary"
                  >
                    Book Closet Space
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col mt-4 mb-4">
            <div className=" homepageCard card card-flip h-100">
              <div className="card-front text-white bg-light">
                <div className="card-body">
                  <img
                    src={type4}
                    alt="this is it"
                    width="100%"
                    style={{ height: "250px", objectFit: "contain" }}
                  />
                  <h4 className="card-title mt-3 text-center">Large Space</h4>
                </div>
              </div>
              <div className="card-back bg-white text-center">
                <div className="card-body">
                  <p className="card-title text-dark ">
                    So What are Large Space?
                  </p>
                  <p className="card-text">
                    You can store large household items like sofas, desks, large
                    instruments in a a Large Space!
                  </p>
                  <Link
                    to="/search-location"
                    className="btn btn-outline-secondary"
                  >
                    Book Large Space
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col mt-4 mb-4">
            <div className="homepageCard card card-flip h-100">
              <div className="card-front text-white bg-light">
                <div className="card-body">
                  <img
                    src={type1}
                    alt="this is it"
                    width="100%"
                    style={{ height: "250px", objectFit: "contain" }}
                  />
                  <h4 className="card-title mt-3 text-center">Others</h4>
                </div>
              </div>
              <div className="card-back bg-white text-center">
                <div className="card-body">
                  <p className="card-title text-dark">
                    So What are Other Items?
                  </p>
                  <p className="card-text">
                    Can’t find a suitable description of your items and space
                    needed? We have every type of space on our platform.
                  </p>
                  <Link
                    to="/search-location"
                    className="btn btn-outline-secondary"
                  >
                    Book Other
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section id="home-page1">
          <div className="main-row mb-5 text-center">
            <h1 className="heading-text">
              We believe everything belongs somewhere
            </h1>
          </div>
          <div className="hsection d-flex flex-wrap justify-content-between">
            <div
              className="hsection1 img-fluid col-md-5 text-center mr-5 mb-3 "
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <img alt="" src={group2} className="image2" />
            </div>
            <div
              className="hsection2 col-md-6 text-center"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <h2>
                Find Storage or parking Spaces for 50% less than self storage
                units.
              </h2>
              <div className="wrapper">
                <Link to="/search-location" className="signupbtnLogin1">
                  <span> SEARCH YOUR SPACE</span>
                </Link>
              </div>
            </div>
          </div>
        </section> */}
      {/*<section id="home-page2">
        <div className="main-row">
          <h1>FRIENDLY STORAGE AND PARKING SPACES NEAR YOUR LOCATION</h1>
        </div>
        <div className="hsection3 d-flex flex-wrap flex-content-between text-center">
          <div className="col-lg-3 col-md-6 mb-3">
            <div
              className="card mt-3 "
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <img
                alt=""
                className="card-img-top"
                src={card}
                style={{ objectFit: "cover" }}
              />
              <div className="card-body">
                <p className="card-text">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-3">
            <div
              className="card mt-3"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img
                alt=""
                className="card-img-top"
                src={card}
                style={{ objectFit: "cover" }}
              />
              <div className="card-body">
                <p className="card-text">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div
              className="card mt-3"
              data-aos="fade-down"
              data-aos-duration="1000"
            >
              <img
                alt=""
                className="card-img-top"
                src={card}
                style={{ objectFit: "cover" }}
              />
              <div className="card-body">
                <p className="card-text">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div
              className="card mt-3"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <img
                alt=""
                className="card-img-top"
                src={card}
                style={{ objectFit: "cover" }}
              />
              <div className="card-body">
                <p className="card-text">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>*/}
      <section className="pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-12 ">
              <h3 className="mb-3 ">Featured Properties </h3>
            </div>
            {/* <div className="col-6 text-right">
              <a
                className="btn btn-primary mb-3 mr-1"
                href="#carouselExampleIndicators2"
                role="button"
                data-slide="prev"
              >
                <FaArrowCircleLeft />
              </a>
              <a
                className="btn btn-primary mb-3 "
                href="#carouselExampleIndicators2"
                role="button"
                data-slide="next"
              >
                <FaArrowCircleRight />
              </a>
            </div> */}
            <div className="col-12">
              <div
                id="carouselExampleIndicators2"
                className="carousel slide"
                data-ride="carousel"
              >
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <div
                      className="row"
                      data-aos="fade-right"
                      data-aos-duration="1000"
                    >
                      {availableFeaturedData?.map((item) => (
                        <div className="col-md-6 col-lg-4 mb-3 text-capitalize">
                          <div
                            className="homepagecard card"
                            style={{ borderRadius: "20px 20px 8px 8px" }}
                          >
                            <img
                              className="img-fluid"
                              alt="100%x280"
                              style={{
                                maxHeight: "192px",
                                objectFit: "contain",
                                borderRadius: "20px 20px 8px 8px",
                              }}
                              src={
                                `${base_URL}/file/images/` +
                                item.propertyImage[0]
                              }
                            />
                            <div className="card-body">
                              <p className="card-title">
                                <Link
                                  to={{ pathname: `/list-details/${item._id}` }}
                                >
                                  {item.title}
                                </Link>
                                (
                                {item?.pricing?.weekly ? (
                                  <>
                                    {item?.pricing?.weekly}$ <sub>/week</sub>
                                  </>
                                ) : item?.pricing?.monthly ? (
                                  <>
                                    {item?.pricing?.monthly}$ <sub>/month</sub>
                                  </>
                                ) : (
                                  <>
                                    {item?.pricing?.yearly}$ <sub>/year</sub>
                                  </>
                                )}
                                ) <br />
                                <FaLocationArrow style={{ color: "#1d6aba" }} />
                                &nbsp;
                                {item?.address?.location}
                              </p>
                              <p className="card-text">
                                <ul className="list-unstyled text-left">
                                  <li className="font-weight-bold text-capitalize">
                                    Storage Type:
                                    <b style={{ color: "#616161" }}>
                                      {item.typeOfStorage}
                                    </b>
                                  </li>
                                  <li className="font-weight-bold text-capitalize">
                                    Listing Type:
                                    <b style={{ color: "#616161" }}>
                                      {item.typeOfListing}
                                    </b>
                                  </li>
                                  <li className="font-weight-bold text-capitalize">
                                    Size:&nbsp;&nbsp;
                                    <b style={{ color: "#616161" }}>
                                      {item.size}
                                      &nbsp;{item.unit}
                                    </b>
                                  </li>
                                </ul>
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Ready />
      <Footer />
    </>
  );
};

export default Home;
