import React, { useState } from "react";
import { Input } from "antd";
// import PP from "../images/pp.jpg";
import { Link } from "react-router-dom";
// import { FaArrowAltCircleRight, FaEdit, FaStar } from "react-icons/fa";
// import { MdDeleteForever } from "react-icons/md";
import HostSidebar from "./HostSidebar";
import PaginationFunction from "../Pagination/Pagination";
import SyncLoader from "react-spinners/SyncLoader";
import { connect } from "react-redux";

const MyListings = (props) => {
  const [loading] = useState(false);

  const filterByTag = (list, searchTag) => {
    if (searchTag && searchTag !== "all") {
      return list.filter((listItem) => listItem.booking_status === searchTag);
    }
    return list;
  };

  const countByTag = (list, searchTag) => {
    if (searchTag && searchTag !== "all") {
      return list.filter((listItem) => listItem.booking_status === searchTag)
        .length;
    }
    return list.length;
  };

  const listingData = props.ownerProperty;

  let availableData = listingData.filter(
    (arg) => arg.booking_status !== "available"
  );

  /*const accept = (id) => {
    create("api/property/acceptbooking", {
      propertyId: id,
    })
      .then((res) => {
        console.log(res.data);
        // console.log("the response is >>", res.data.accessToken);
        notify.showSuccess("You have accepeted this request, wait for payment");
        // console.log(res.data.email);
        setState(!state);
      })
      .catch((err) => {
        if (err.response) {
          // notify.handleError(err.response.data.msg + ", Try again");
          console.log(err.response.data.msg);
          // console.log(err.response.status);
          // console.log(err.response.headers);
        }
      });
  };
  const reject = (id) => {
    create("api/property/rejectbooking", {
      propertyId: id,
    })
      .then((res) => {
        // console.log(res.data);
        // console.log("the response is >>", res.data.accessToken);
        notify.showSuccess("Okay You have declined this request");
        // console.log(res.data.email);
        // setListingData(listingData.reverse());
        setState(!state);
      })
      .catch((err) => {
        if (err.response) {
          // notify.handleError(err.response.data.msg + ", Try again");
          console.log(err.response.data.msg);
          // console.log(err.response.status);
          // console.log(err.response.headers);
        }
      });
    }
    const booked = filterData.filter((arg) => arg.booking_status === "accepted");
    const PendingListing = filterData.filter(
      (arg) => arg.booking_status === "pending"
    );
    */

  const { Search } = Input;
  const [searchterm, setSearchTerm] = useState("");
  const filterData = availableData?.filter((item) =>
    searchterm === ""
      ? item
      : item.title.toLowerCase().includes(searchterm.toLowerCase()) ||
        item.typeOfListing.toLowerCase().includes(searchterm.toLowerCase()) ||
        item.typeOfStorage.toLowerCase().includes(searchterm.toLowerCase())
  );

  const [filter, setFilter] = useState("all");

  const [currentPage, setCurrentPage] = useState(parseInt("1"));
  const [postPerPage] = useState(parseInt("6"));
  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const currentListing = filterByTag(filterData, filter).slice(
    indexOfFirstPost,
    indexOfLastPost
  );
 
  const paginateFunction = (pageNo) => {
    setCurrentPage(pageNo);
  };
  return (
    <>
      <section id="main-dashboard">
        <div className="sidebar-row">
          <div className="sidebar-container">
            <HostSidebar />
          </div>

          {/* Reservations confirm Modal body */}
          <div
            className="modal fade"
            id="acceptReservation"
            tabindex="-1"
            role="dialog"
            aria-labelledby="acceptReservation"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header text-center ">
                  <h5 className="modal-title ml-auto">
                    Are you sure want to confirm this Reservation?
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-footer  justify-content-center">
                  <button type="button" className="btn btn-primary">
                    Yes
                  </button>

                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* Reservations reject Modal body */}
          <div
            className="modal fade"
            id="declineReservation"
            tabindex="-1"
            role="dialog"
            aria-labelledby="declineReservation"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header text-center ">
                  <h5 className="modal-title ml-auto">
                    Are you sure want to Decine this Reservation Request?
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-footer  justify-content-center">
                  <button type="button" className="btn btn-primary">
                    Yes
                  </button>

                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard-content">
            <div className="dashboard-title">
              <h5>Dashboard | Reservations</h5>
            </div>
            {loading ? (
              <>
                {" "}
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "80vh", top: "50%" }}
                >
                  <SyncLoader size={18} color="#1d6aba" margin="2" />
                </div>
              </>
            ) : (
              <div className="dashboard-second animate__animated animate__fadeInUp">
                <h5 className="p-3">Manage Your Reservations</h5>

                <div className="dashboard-second-title d-flex flex-wrap justify-content-between">
                  <div className="more-buttons col-lg-6 col-md-12">
                    <button
                      className="manage-button mt-2"
                      onClick={() => {
                        setFilter("all");
                      }}
                    >
                      All ({filterData.length})
                    </button>
                    <button
                      className="manage-button mt-2"
                      onClick={() => {
                        setFilter("accepted");
                      }}
                    >
                      Booked ({countByTag(listingData, "accepted")})
                    </button>
                    <button
                      className="manage-button  mt-2"
                      onClick={() => {
                        setFilter("pending");
                      }}
                    >
                      Pending ({countByTag(listingData, "pending")})
                    </button>
                  </div>
                  <div className="search-bar col-lg-6 col-md-12 mt-4 mt-lg-0">
                    <Search
                      placeholder="Search Listing"
                      allowClear
                      enterButton
                      size="large"
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        setSearchTerm(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className=" table-responsive">
                  {currentListing.length > 0 ? (
                    <>
                      <table className="table  table-hover text-center table-striped">
                        <thead>
                          <tr>
                            <th scope="col">S.N.</th>
                            <th scope="col">Title</th>
                            <th scope="col">Storage Tpe</th>
                            <th scope="col">Listing Type</th>
                            <th scope="col">Plan Type</th>
                            <th scope="col">Price</th>
                            <th scope="col">Status</th>
                            <th scope="col">Actions</th>
                          </tr>
                        </thead>
                        {currentListing.map((item, indx) => (
                          <tbody>
                            <tr>
                              <th scope="row">{indx + 1}.</th>
                              <td>
                                <Link
                                  to={{
                                    pathname: `/list-details/${item?._id}`,
                                  }}
                                >
                                  <b style={{ color: "#1D6ABA" }}>
                                    {item?.title}
                                  </b>
                                </Link>
                                <br />
                                <small> Kohalpur, Banke</small>
                              </td>
                              <td style={{ textTransform: "capitalize" }}>
                                <small> {item?.typeOfStorage}</small>
                              </td>
                              <td style={{ textTransform: "capitalize" }}>
                                <small>{item?.typeOfListing}</small>{" "}
                              </td>
                              <td>
                                <ul className="list-unstyled">
                                  <li
                                    style={{
                                      color: "#1d6aba",
                                    }}
                                  >
                                    <small>
                                      {item?.planType?.weeklyPlan ===
                                      "available"
                                        ? "WeeklyPlan"
                                        : null}
                                    </small>
                                  </li>
                                  <li
                                    style={{
                                      color: "#009933",
                                    }}
                                  >
                                    <small>
                                      {" "}
                                      {item?.planType?.monthlyPlan ===
                                      "available"
                                        ? "MonthlyPlan"
                                        : null}
                                    </small>
                                  </li>
                                  <li
                                    style={{
                                      color: "#ee00ff",
                                    }}
                                  >
                                    <small>
                                      {item?.planType?.yearlyPlan ===
                                      "available"
                                        ? "YearlyPlan"
                                        : null}
                                    </small>
                                  </li>
                                </ul>
                              </td>
                              <td>
                                <ul className="list-unstyled ">
                                  <li
                                    style={{
                                      color: "#1d6aba",
                                    }}
                                  >
                                    <small>
                                      {item?.planType?.weeklyPlan ===
                                      "available" ? (
                                        <>{item?.pricing?.weekly}$</>
                                      ) : null}
                                    </small>
                                  </li>
                                  <li
                                    style={{
                                      color: "#009933",
                                    }}
                                  >
                                    <small>
                                      {item?.planType?.monthlyPlan ===
                                      "available" ? (
                                        <>{item?.pricing?.monthly}$</>
                                      ) : null}
                                    </small>
                                  </li>
                                  <li
                                    style={{
                                      color: "#ee00ff",
                                    }}
                                  >
                                    <small>
                                      {item?.planType?.yearlyPlan ===
                                      "available" ? (
                                        <>{item?.pricing?.yearly}$</>
                                      ) : null}
                                    </small>
                                  </li>
                                </ul>
                              </td>
                              <td>
                                {" "}
                                {item.booking_status === "available" ? (
                                  <h5 className="new">Available</h5>
                                ) : item.booking_status === "pending" ? (
                                  <h5 className="pending">Pending</h5>
                                ) : (
                                  <h5 className="accepted">Booked</h5>
                                )}
                              </td>
                              <td>
                                {item.booking_status === "accepted" ? (
                                  <Link
                                    to={{
                                      pathname: `/reservation-request/${item.booking[0]}`,
                                    }}
                                  >
                                    <button className="manage-button">
                                      Details
                                    </button>
                                  </Link>
                                ) : (
                                  <>
                                    <Link
                                      to={{
                                        pathname: `/reservation-request/${item.booking[0]}`,
                                      }}
                                    >
                                      <button
                                        className="manage-button"
                                        // data-toggle="modal"
                                        // data-target="#acceptReservation"
                                      >
                                        See Details
                                      </button>
                                    </Link>
                                  </>
                                )}
                              </td>{" "}
                            </tr>
                          </tbody>
                        ))}
                      </table>

                      <PaginationFunction
                        postPerPage={postPerPage}
                        totalPost={availableData?.length}
                        paginate={paginateFunction}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                      />
                    </>
                  ) : (
                    <h3 className="text-center p-4"> No reservation property available</h3>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => ({
  ownerProperty: state.ownerProperty.ownerProperty,
  ownerFeatureProperty: state.ownerFeaturedProperty.ownerFeaturedProperty,
  refresh: state.refresh.refresh.refresh,
  refreshFunction: state.refresh.refresh.refreshFunction,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MyListings);
