import React, { useState } from "react";
import { Input, Tooltip } from "antd";
import { deleteData, create } from "../Axios/axiosService";
import notify from "../Notify/notify";
// import { refreshPage } from "../Axios/tokenStorage";
import $ from "jquery";
import PaginationFunction from "../Pagination/Pagination";
// import EditListing from "./EditListing";
import ListingItem from "./ListingItem";
import HostSidebar from "./HostSidebar";
import SyncLoader from "react-spinners/SyncLoader";
import { connect } from "react-redux";
import Loader from "./../service/loader/loader";
import { FaInfo, FaInfoCircle } from "react-icons/fa";

const MyListings = (props) => {
  const [loading] = useState(false);
  const [loader, setLoader] = useState(false);

  //const [listingData, setListingData] = useState([]);
  const listingData = props.ownerProperty.slice().reverse();

  const [currentPage, setCurrentPage] = useState(parseInt("1"));
  const [postPerPage] = useState(parseInt("6"));

  console.log(listingData);

  const filterByTag = (list, searchTag) => {
    if (searchTag && searchTag !== "all") {
      return list.filter((listItem) => listItem.booking_status === searchTag);
    }
    return list;
  };

  const countByTag = (list, searchTag) => {
    if (searchTag && searchTag !== "all") {
      return list.filter((listItem) => listItem.booking_status === searchTag)
        .length;
    }
    return list.length;
  };

  const { Search } = Input;
  const [filter, setFilter] = useState("all");
  const [searchterm, setSearchTerm] = useState("");

  const filterData = listingData?.filter((item) =>
    searchterm === ""
      ? item
      : item.title.toLowerCase().includes(searchterm.toLowerCase()) ||
        item.typeOfListing.toLowerCase().includes(searchterm.toLowerCase()) ||
        item.typeOfStorage.toLowerCase().includes(searchterm.toLowerCase())
  );

  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const currentListing = filterByTag(filterData, filter).slice(
    indexOfFirstPost,
    indexOfLastPost
  );
  const paginateFunction = (pageNo) => {
    setCurrentPage(pageNo);
  };

  const removeData = (id) => {
    setLoader(true);
    deleteData(`/api/property/${id}`)
      .then((res) => {
        // refreshPage();
        $(`#deleteListing-${id}`).modal("hide");
        props.refreshFunction(6);
        setLoader(false);

        //  refreshPage();
        notify.showSuccess("Your Listing is deleted successfully");
      })
      .catch((err) => {
        notify.handleError(err.response.data.msg);
        setLoader(false);
      });
  };

  const addToFeatured = (id) => {
    setLoader(true);
    create("api/property/addtofeatured", {
      propertyId: id,
    })
      .then((res) => {
        props.refreshFunction(5);
        notify.showSuccess("Your Listing is added to Featured");
        setLoader(false);
      })
      .catch((err) => {
        notify.handleError(err.response.data.msg);
        setLoader(false);
      });
  };

  return (
    <>
      <section id="main-dashboard">
        <div className="sidebar-row">
          <div className="sidebar-container">
            <HostSidebar />
          </div>
          <div className="dashboard-content">
            <div className="dashboard-title">
              <h5>Dashboard | Listings</h5>
            </div>

            {loading ? (
              <>
                {" "}
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "80vh", top: "50%" }}
                >
                  <SyncLoader size={18} color="#1d6aba" margin="2" />
                </div>
              </>
            ) : (
              <div className="dashboard-second animate__animated animate__fadeInUp">
                <h5 className="p-3">Manage Your Listings</h5>
                <div className="dashboard-second-title d-flex flex-wrap justify-content-between">
                  <div className="more-buttons col-lg-6 col-md-12">
                    <div className="d-flex flex-wrap justify-content-start">
                      <button
                        className="manage-button mt-2"
                        onClick={() => {
                          setFilter("all");
                        }}
                      >
                        All ({listingData.length})
                      </button>
                      <button
                        className="manage-button mt-2"
                        onClick={() => {
                          setFilter("available");
                        }}
                      >
                        Available({countByTag(listingData, "available")})
                      </button>
                      <button
                        className="manage-button mt-2"
                        onClick={() => {
                          setFilter("accepted");
                        }}
                      >
                        Booked(
                        {countByTag(listingData, "accepted")})
                      </button>

                      <button
                        className="manage-button mt-2"
                        onClick={() => {
                          setFilter("pending");
                        }}
                      >
                        Pending(
                        {countByTag(listingData, "pending")})
                      </button>
                    </div>
                  </div>
                  <div className="search-bar col-lg-6 col-md-12 mt-4 mt-lg-0">
                    <Search
                      placeholder="Search Listing"
                      allowClear
                      enterButton
                      size="large"
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        setSearchTerm(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="mylistings table-responsive ">
                  {currentListing.length > 0 ? (
                    <>
                      <table className="table  table-hover text-center table-striped">
                        <thead>
                          <tr>
                            <th scope="col">S.N.</th>
                            <th scope="col">Title</th>
                            <th scope="col">Storage Tpe</th>
                            <th scope="col">Listing Type</th>
                            <th scope="col">Plan Type</th>
                            <th scope="col">
                              {" "}
                              &nbsp; &nbsp;&nbsp; Price &nbsp;&nbsp; &nbsp;
                            </th>
                            <th scope="col">
                              Status{" "}
                              <Tooltip title="Status will only be visible after property is verified by the Admin.">
                                <FaInfoCircle style={{ color: "#1d6aba" }} />
                              </Tooltip>
                            </th>
                            <th scope="col">Property Status</th>
                            <th scope="col">Actions</th>
                          </tr>
                        </thead>

                        {currentListing.map((item, index) => (
                          <ListingItem
                            deleteItem={removeData}
                            featured={addToFeatured}
                            ownerFeatureProperty={
                              props?.ownerFeatureProperty?.data
                            }
                            item={item}
                            index={index}
                            indexOfFirstPost={indexOfFirstPost}
                          />
                        ))}
                      </table>

                      <PaginationFunction
                        postPerPage={postPerPage}
                        totalPost={filterByTag(filterData, filter).length}
                        // totalPost={listingData.length}
                        paginate={paginateFunction}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                      />
                    </>
                  ) : (
                    <>
                      <h3 className="text-center p-4 pb-0">
                        No results to show.
                      </h3>
                      {/* <p className="text-center">
                        Go to <Link to="/dashboard-add"> Add New listing </Link>{" "}
                        to add Property
                      </p> */}
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        {/* delete action modal */}

        {/* faavourite action modal */}
        <div
          className="modal fade"
          id="favouriteListing"
          tabindex="-1"
          role="dialog"
          aria-labelledby="favouriteListing"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header text-center ">
                <h5 className="modal-title ml-auto">
                  Do you want to make this your Favourite Listing?
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-footer  justify-content-center">
                <button type="button" className="btn btn-primary">
                  Yes
                </button>

                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
        <Loader loader={loader} />
      </section>
    </>
  );
};
const mapStateToProps = (state) => ({
  ownerProperty: state.ownerProperty.ownerProperty,
  ownerFeatureProperty: state.ownerFeaturedProperty.ownerFeaturedProperty,
  refresh: state.refresh.refresh.refresh,
  refreshFunction: state.refresh.refresh.refreshFunction,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MyListings);
