import React, { useState } from "react";
import LoginImg from "../images/login.png";
import Ready from "./Ready";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { create } from "../Axios/axiosService";
import { getLocalStorage, setLocalStorage } from "../Axios/tokenStorage";
import { adminToken, token } from "../Axios/baseURL";
import Footer from "./Footer";
import { connect } from "react-redux";
import "./login.css";
import { refreshPage } from "../Axios/tokenStorage";
import Loader from "./../service/loader/loader";

import notify from "../Notify/notify";

const Login = (props) => {
  const [loader, setLoader] = useState(false);
  const { register, handleSubmit } = useForm();
  const history = useHistory();
  const [user, setUser] = useState("renter");

  if (getLocalStorage(token)) {
    history.push("/");
  } else if (getLocalStorage(adminToken)) {
    history.push("/admin-panel");
  }

  const onSubmit = (data) => {
    setLoader(true);
    if (user === "host") {
      create("/api/auth/owner/login", {
        email: data.email,
        password: data.password,
      })
        .then((res) => {
          console.log(res);
          if (res?.data?.owner?.status === "suspended") {
            history.push("/suspend");
          } else {
            localStorage.setItem("role", "owner");
            history.push("/host");
            setLocalStorage(token, res.data.token);
          }
          notify.showSuccess("Welcome" + " " + data.email);
          refreshPage(); // handleLogin();
          setLoader(false);
        })
        .catch((err) => {
          if (err.response) {
            notify.handleError(err.response.data.msg + ", Try again");
            setLoader(false);
          }
        });
    } else {
      create("/api/auth/login", {
        email: data.email,
        password: data.password,
      })
        .then((res) => {
          if (res?.data?.user?.status === "suspended") {
            history.push("/suspend");
          } else {
            history.push("/");
            setLocalStorage(token, res.data.token);
            localStorage.setItem("role", "renter");
          }
          notify.showSuccess("Welcome" + " " + data.email);
          refreshPage();
          setLoader(false);
        })
        .catch((err) => {
          if (err.response) {
            console.log(err);
            notify.handleError(err.response.data.msg + ", Try again");
            setLoader(false);
          }
        });
    }
  };

  return (
    <>
      <section id="login-page">
        <div className="container register">
          <div className="row">
            <div className="col-lg-7  register-right ">
              <ul
                className="nav nav-tabs nav-justified text-center mt-2 mr-3"
                id="myTab"
                role="tablist"
              >
                <li className="nav-item ">
                  <a
                    className="nav-link active"
                    id="renter-tab"
                    data-toggle="tab"
                    href="#renter"
                    role="tab"
                    aria-controls="renter"
                    aria-selected="true"
                    onClick={() => {
                      setUser("renter");
                    }}
                  >
                    Renter
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="host-tab"
                    data-toggle="tab"
                    href="#host"
                    role="tab"
                    aria-controls="host"
                    aria-selected="false"
                    onClick={() => {
                      setUser("host");
                    }}
                  >
                    Host
                  </a>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="renter"
                  role="tabpanel"
                  aria-labelledby="renter-tab"
                >
                  {user === "host" ? (
                    <h3 className="register-heading text-center">
                      Login as a Host
                    </h3>
                  ) : (
                    <h3 className="register-heading text-center">
                      Login as a Renter
                    </h3>
                  )}

                  <div className="row register-form">
                    <div className="col-md-12 col-sm-12">
                      {/* <div className="d-flex ">
                        {user !== "host" ? (
                          <>
                            {" "}
                            <GoogleLogin
                              clientId="541895617796-tk82ak6hkrqr72gbltjm7jl64g8jphbu.apps.googleusercontent.com"
                              buttonText="Continue with Google"
                              onSuccess={responseGoogle}
                              onFailure={responseGoogle}
                              cookiePolicy="single_host_origin"
                              style={{ width: "100%" }}
                              className="signupbtn-google"
                            /> */}
                      {/* <button className="signupbtn-facebook ">
                              <FaFacebookF className="social-icons-facebook" />
                              Sign in with Facebook
                            </button> */}
                      {/* </>
                        ) : null}
                      </div> */}
                      <form
                        className="form-group"
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        {/* {user !== "host" ? (
                          <h5>
                            <span>or</span>
                          </h5>
                        ) : null} */}

                        <div className="mb-3 form-group">
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            id="email"
                            placeholder="Email"
                            ref={register({
                              required: "!!!! Required",
                            })}
                          />
                        </div>
                        <div className="mb-3 form-group">
                          <input
                            type="password"
                            className="form-control"
                            name="password"
                            id="password"
                            placeholder="Password"
                            ref={register({
                              required: "!!!! Required",
                            })}
                          />
                        </div>
                        {/* <div className="mb-3 form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="rememberMe"
                            name="rememberMe"
                            ref={register({
                              required: false,
                            })}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="remember-me"
                          >
                            Remember Me
                          </label>
                        </div> */}
                        <div className="d-flex  text-center flex-wrap flex-column flex-lg-row justify-content-between">
                          <button
                            type="submit"
                            className="signupbtnLogin mt-2"
                            style={{ width: "100%" }}
                          >
                            SIGN IN
                          </button>
                          {/* <Link to="/signup" className="signupbtnCreate mt-3">
                            SIGN UP
                          </Link> */}
                        </div>
                        <div className="mt-3 text-center">
                          <Link
                            className="mr-auto"
                            to={
                              user === "host"
                                ? "/host/forgot-password"
                                : "/renter/forgot-password"
                            }
                          >
                            <u> Forgot Your Password?</u>
                          </Link>
                        </div>
                        <div className="text-center mt-3">
                          {" "}
                          New here? <Link to="/signup">Signup</Link> to create
                          an Account.
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5 register-left d-none d-lg-block">
              <img
                alt=""
                src={LoginImg}
                className="img-fluid animate__animated animate__bounce"
              />
            </div>
          </div>
        </div>
      </section>
      <Loader loader={loader} />
      <Ready />
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({
  refresh: state.refresh.refresh.refresh,
  refreshFunction: state.refresh.refresh.refreshFunction,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
