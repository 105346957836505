import React from "react";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
      <footer className="bg-dark text-center text-lg-start footer">
        <div className="p-5 text-center justify-content-center" id="footer">
          <div className="row pl-4">
            <div className="col-lg-5 col-md-12 mb-4 mb-md-0 ">
              <h5 className="text-uppercase text-left mb-4 text-light">
                ABOUT US
              </h5>

              <p className="text-justify">
                We like stuff. We know you like stuff too. We see the joy it
                sparks in you. We value the time and money invested in curating
                your belongings. Get rid of it? No way. <br />
                <h3 style={{ color: "#fff" }}>Store easy with STOZY!</h3>{" "}
              </p>
            </div>

            <div className="footer-list col-lg-3 col-md-6 mb-4 mb-md-0">
              <h5 className="text-uppercase mb-4 text-light">USEFUL LINKS</h5>

              <ul className="list-unstyled text-center">
                <li className="mb-2">
                  <Link to="/about">About Us</Link>
                </li>
                <li>
                  <Link to="/contact" className="pd-3">
                    Contact Us
                  </Link>
                </li>
                <li>
                  <Link to="/host" className="">
                    About Host
                  </Link>
                </li>
                <li>
                  <Link to="#!" className="">
                    Our Policies
                  </Link>
                </li>
              </ul>
            </div>

            <div className="footer-list col-lg-3 col-md-6 mb-4 mb-md-0">
              <h5 className="text-uppercase mb-4 text-light">Social Links</h5>

              <ul className="list-unstyled">
                <li>
                  <a href="#!" className="">
                    <FaTwitter /> &nbsp;Twitter
                  </a>
                </li>
                <li>
                  <a href="#!" className="">
                    <FaFacebookF />
                    &nbsp;Facebook
                  </a>
                </li>
                <li>
                  <a href="#!" className="">
                    <FaInstagram /> &nbsp;Instagram
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div
          className="text-center p-3"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}
        >
          © 2020 Copyright:
          <a className="text-light" href="https://Stozy.com/">
            {" "}
            Stozy.com
          </a>
        </div>
      </footer>
    </>
  );
};

export default Footer;
