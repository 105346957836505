
/* eslint-disable radix */
import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + parseInt("1"),
    color: "#fff",
  },
}));

 function Loader(props) {
  
  const { loader } = props;
  const classes = useStyles();

  return (
    <div>
      <Backdrop className={classes.backdrop} open={loader}>
        <CircularProgress size='100px' color="inherit" />
      </Backdrop>
    </div>
  );
}
export default Loader
