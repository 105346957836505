import React, { useState } from "react";
import { Link } from "react-router-dom";
import RenterSidebar from "./RenterSidebar";
import { create } from "../Axios/axiosService";
import notify from "../Notify/notify";
import { MdDeleteForever } from "react-icons/md";
import { Input, Tooltip } from "antd";
import $ from "jquery";
import SyncLoader from "react-spinners/SyncLoader";
import { connect } from "react-redux";
import PaginationFunction from "../Pagination/Pagination";
import Loader from "../service/loader/loader";

const RenterReservation = (props) => {
  //const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loading] = useState(false);
  const [currentPage, setCurrentPage] = useState(parseInt("1"));
  const [postPerPage] = useState(parseInt("6"));

  const data = props?.renterBookedProperty?.slice()?.reverse();

  const CancelBooking = (id) => {
    setLoader(true);
    create(`api/booking/${id}`)
      .then((res) => {
        $(`#cancelBooking-${id}`).modal("hide");
        setLoader(false);
        props.refreshFunction(2);
        notify.showSuccess("Your have cancelled booking successfully");
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.msg);
          setLoader(false);
        }
      });
  };

  const { Search } = Input;
  const [searchterm, setSearchTerm] = useState("");
  const filterData = data?.filter((item) =>
    searchterm === ""
      ? item
      : item?.property?.title
          ?.toLowerCase()
          ?.includes(searchterm.toLowerCase()) ||
        item?.property?.typeOfListing
          ?.toLowerCase()
          ?.includes(searchterm.toLowerCase()) ||
        item?.property?.typeOfStorage
          ?.toLowerCase()
          ?.includes(searchterm.toLowerCase())
  );

  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const currentListing = filterData.slice(indexOfFirstPost, indexOfLastPost);

  const paginateFunction = (pageNo) => {
    setCurrentPage(pageNo);
  };
  return (
    <>
      <section id="main-dashboard">
        <div className="sidebar-row">
          <div className="sidebar-container">
            <RenterSidebar />
          </div>

          <div className="dashboard-content">
            <div className="dashboard-title">
              <h5>Dashboard | Reservations</h5>
            </div>
            {loading ? (
              <>
                {" "}
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "80vh", top: "50%" }}
                >
                  <SyncLoader size={18} color="#1d6aba" margin="2" />
                </div>
              </>
            ) : (
              <div className="dashboard-second  animate__animated animate__fadeInUp">
                <div className="topbar d-flex justify-content-between flex-wrap mt-4 mb-4">
                  <h5 className="pl-3 mb-4 col-lg-6 col-md-12">
                    Manage Your Bookings
                  </h5>
                  <div className="search-bar col-lg-6 col-md-12">
                    <Search
                      placeholder="Search Listing"
                      allowClear
                      enterButton
                      size="large"
                      onChange={(e) => {
                        setSearchTerm(e.target.value);
                      }}
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>

                <div className="mylistings table-responsive-sm">
                  {filterData.length > 0 ? (
                    <>
                      <table className="table table-striped table-hover text-center text-capitalize">
                        <thead>
                          <tr>
                            <th scope="col">S.N.</th>
                            <th scope="col">Title</th>
                            <th scope="col">Storage Tpe</th>
                            <th scope="col">Listing Type</th>
                            <th scope="col">Plan Type</th>
                            <th scope="col">Price</th>
                            <th scope="col">Payment Status</th>
                            <th scope="col">Status</th>
                            <th scope="col">Actions</th>
                          </tr>
                        </thead>
                        {filterData.map((item, index) => (
                          <>
                            <tbody>
                              <tr>
                                <th scope="row">
                                  {indexOfFirstPost + index + 1}
                                </th>
                                <td>
                                  <Link
                                    to={{
                                      pathname: `/list-details/${item?.property?._id}`,
                                    }}
                                  >
                                    <b style={{ color: "#1D6ABA" }}>
                                      {item?.property?.title}
                                    </b>
                                  </Link>
                                  <br />
                                  <small>
                                    {" "}
                                    {item?.property?.address?.location}
                                  </small>
                                </td>
                                <td style={{ textTransform: "capitalize" }}>
                                  <small>
                                    {" "}
                                    {item?.property?.typeOfStorage}
                                  </small>
                                </td>
                                <td style={{ textTransform: "capitalize" }}>
                                  <small>{item?.property?.typeOfListing}</small>{" "}
                                </td>
                                <td>
                                  <ul className="list-unstyled">
                                    <li
                                      style={{
                                        color: "#1d6aba",
                                      }}
                                    >
                                      <small>
                                        {item?.planType === "weekly"
                                          ? "WeeklyPlan"
                                          : null}
                                      </small>
                                    </li>
                                    <li
                                      style={{
                                        color: "#009933",
                                      }}
                                    >
                                      <small>
                                        {" "}
                                        {item?.planType === "monthly"
                                          ? "MonthlyPlan"
                                          : null}
                                      </small>
                                    </li>
                                    <li
                                      style={{
                                        color: "#ee00ff",
                                      }}
                                    >
                                      <small>
                                        {item?.planType === "yearly"
                                          ? "YearlyPlan"
                                          : null}
                                      </small>
                                    </li>
                                  </ul>
                                </td>
                                {item?.price ? (
                                  <td>
                                    <ul className="list-unstyled ">
                                      <li
                                        style={{
                                          color: "#1d6aba",
                                        }}
                                      >
                                        <small>
                                          {item?.planType === "weakly" ? (
                                            <>{item?.price}$</>
                                          ) : null}
                                        </small>
                                      </li>
                                      <li
                                        style={{
                                          color: "#009933",
                                        }}
                                      >
                                        <small>
                                          {item?.planType === "monthly" ? (
                                            <>{item?.price}$</>
                                          ) : null}
                                        </small>
                                      </li>
                                      <li
                                        style={{
                                          color: "#ee00ff",
                                        }}
                                      >
                                        <small>
                                          {item?.planType === "yearly" ? (
                                            <>{item?.price}$</>
                                          ) : null}
                                        </small>
                                      </li>
                                    </ul>
                                  </td>
                                ) : null}
                                <td>
                                  {item?.booking_status === "accepted"
                                    ? item?.isPaid === false
                                      ? "Not Paid"
                                      : "Paid"
                                    : item?.booking_status === "canceled"
                                    ? "canceled"
                                    : item?.booking_status === "rejected"
                                    ? "rejected"
                                    : "wait..."}
                                </td>

                                <td>
                                  {" "}
                                  {item.booking_status === "available" ? (
                                    <h5 className="new">Available</h5>
                                  ) : item.booking_status === "pending" ? (
                                    <h5 className="pending">Pending</h5>
                                  ) : (
                                    <h5 className="accepted">Booked</h5>
                                  )}
                                </td>

                                <td>
                                  <Tooltip title="Cancel Booking">
                                    <button
                                      className="manage-button"
                                      data-toggle="modal"
                                      data-target={`#cancelBooking-${item._id}`}
                                      // onClick={() => {
                                      //   CancelBooking(item._id);
                                      // }}
                                    >
                                      {" "}
                                      <MdDeleteForever className="icon" />{" "}
                                    </button>
                                  </Tooltip>

                                  {/* <Tooltip title="View Details">
                                <Link
                                  to={{
                                    pathname: `/list-details/${item?.property?._id}`,
                                  }}
                                >
                                  <button className="manage-button">
                                    <FaArrowAltCircleRight className="icon" />{" "}
                                  </button>
                                </Link>
                                </Tooltip>*/}
                                </td>
                              </tr>
                            </tbody>

                            <div
                              className="modal fade"
                              id={`cancelBooking-${item._id}`}
                              tabindex="-1"
                              role="dialog"
                              aria-labelledby="cancelBooking"
                              aria-hidden="true"
                            >
                              <div
                                className="modal-dialog modal-dialog-centered"
                                role="document"
                              >
                                <div className="modal-content">
                                  <div className="modal-header text-center ">
                                    <h5 className="modal-title ml-auto">
                                      Do you want to Cancel this Booking Plan?
                                    </h5>
                                    <button
                                      type="button"
                                      className="close"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                  <div className="modal-footer  justify-content-center">
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      onClick={() => {
                                        CancelBooking(item._id);
                                      }}
                                    >
                                      Yes
                                    </button>

                                    <button
                                      type="button"
                                      className="btn btn-secondary"
                                      data-dismiss="modal"
                                    >
                                      No
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                      </table>

                      <PaginationFunction
                        postPerPage={postPerPage}
                        totalPost={data.length}
                        paginate={paginateFunction}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                      />
                    </>
                  ) : (
                    <h4 className="text-center p-4">No Results </h4>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <Loader loader={loader} />
      </section>
    </>
  );
};
const mapStateToProps = (state) => ({
  renterBookedProperty: state.renterBookedProperty.renterBookedProperty,
  refresh: state.refresh.refresh.refresh,
  refreshFunction: state.refresh.refresh.refreshFunction,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(RenterReservation);
