import React from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";

const NotFound = () => (
  <div>
    <div className="error-page1 text-center">
      <h1 className="text-center mb-1" style={{ fontSize: "30px" }}>
        Oops!! ACCOUNT SUSPENDED
      </h1>
      <h5 className="mb-1">Your account has been suspended.</h5>
      <div id="orbit-system">
        <div class="system">
          <div class="satellite-orbit">
            <div class="satellite">SUSPENDED</div>
          </div>
          <div class="planet">
            <img
              src="http://orig02.deviantart.net/69ab/f/2013/106/0/4/sad_man_by_agiq-d61wk0d.png"
              height="200px"
            />{" "}
          </div>
        </div>
      </div>
      <p>For More Information, Please</p>
      <Link
        to="/contact"
        className="signupbtnLogin1"
        style={{ height: "min-content" }}
      >
        <span>Contact Us</span>{" "}
      </Link>
    </div>
    <Footer />
  </div>
);

export default NotFound;
