import React, { useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import { FcDownRight } from "react-icons/fc";
import { FaDownload, FaLocationArrow } from "react-icons/fa";
import { getLocalStorage } from "../Axios/tokenStorage";
import { token } from "../Axios/baseURL";
import Footer from "../Public/Footer";
import ImageGallery from "../images/images";
import SyncLoader from "react-spinners/SyncLoader";
import Image from "../images/about.jpg";
import Loader from "./../service/loader/loader";
import notify from "../Notify/notify";
import { saveAs } from "file-saver";
//import { MapContainer, TileLayer, Popup, Marker } from "react-leaflet";
//import Autocomplete2 from "../Map/AutoComplete2";
import { base_URL } from "../Axios/baseURL";

import { connect } from "react-redux";
import { create } from "../Axios/axiosService";

const ListingDetails = (props) => {
  const { id } = useParams();
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  getLocalStorage(token);

  const [loading] = useState(false);
  const listingDetail = props?.getAllProperty;
  const listingData = props?.getAllProperty;

  const selectedData = listingDetail.filter((arg) => arg._id === id)[0];
  console.log(selectedData);
  const filteredData1 = listingData.filter(
    (args) => args.booking_status === "available"
  );

  const acceptProperty = () => {
    setLoader(true);
    create("/api/admin/verify-property", {
      propertyId: id,
    })
      .then((res) => {
        notify.showSuccess("Accepted Successfully");
        history.push("/admin-panel");
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        notify.handleError("Try again");
        setLoader(false);
      });
  };
  const rejectProperty = () => {
    setLoader(true);
    create("/api/admin/decline-verification", {
      propertyId: id,
    })
      .then((res) => {
        notify.showSuccess("Rejected Successfully");
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        notify.handleError(" Try again");
        setLoader(false);
      });
  };

  function forceDownload(url, fileName) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = function () {
      var urlCreator = window.URL || window.webkitURL;
      var imageUrl = urlCreator.createObjectURL(this.response);
      var tag = document.createElement("a");
      tag.href = imageUrl;
      tag.download = fileName;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
    };
    xhr.send();
  }

  return (
    <>
      {loading ? (
        <>
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "80vh", top: "50%" }}
          >
            <SyncLoader size={18} color="#1d6aba" margin="2" />
          </div>
        </>
      ) : (
        <>
          {/*map location*/}

          <section id="listing-details">
            <>
              <hr />
              <h1 className="text-center">Property Detail</h1>
              <hr />
              <div className="listing-details col-lg-12">
                <div className="top-img col-lg-7 float-left">
                  <ImageGallery />
                </div>
                <div className="booking-card col-lg-4">
                  <div className="card mt-3">
                    <h3 className="card-header">Owner Information</h3>
                    <div className="card-body">
                      <ul class="list-group list-group-flush">
                        <li
                          class="list-group-item"
                          style={{ backgroundColor: "transparent" }}
                        >
                          Name : <span>{selectedData?.owner?.fullName}</span>
                        </li>

                        <li
                          class="list-group-item"
                          style={{ backgroundColor: "transparent" }}
                        >
                          Email : {selectedData?.owner?.email}
                        </li>
                        <li
                          class="list-group-item"
                          style={{ backgroundColor: "transparent" }}
                        >
                          Contact : {selectedData?.owner.contactNo}
                        </li>
                        <li
                          class="list-group-item"
                          style={{ backgroundColor: "transparent" }}
                        >
                          Property Document :{" "}
                          <img
                            src={
                              `${base_URL}/file/images/` +
                              selectedData?.propertyDocument[0]
                            }
                            alt=""
                            width="100px"
                          />
                          <a
                            href="#"
                            onClick={() =>
                              forceDownload(
                                `${base_URL}/file/images/` +
                                  selectedData?.propertyDocument[0],
                                "Property Document"
                              )
                            }
                          >
                            <br />
                            Download Image
                            <FaDownload />
                          </a>
                        </li>

                        <li
                          class="list-group-item"
                          style={{ backgroundColor: "transparent" }}
                        >
                          Profile Image :{" "}
                          <img
                            src={
                              `${base_URL}/file/images/` +
                              selectedData?.owner?.profileImage[0]
                            }
                            alt=""
                            width="100px"
                          />
                          <a
                            href="#"
                            onClick={() =>
                              forceDownload(
                                `${base_URL}/file/images/` +
                                  selectedData?.owner?.profileImage[0],
                                "profile Image"
                              )
                            }
                          >
                            <br />
                            Download Image
                            <FaDownload />
                          </a>
                        </li>

                        {selectedData?.booking_status === "accepted" &&
                        getLocalStorage("role") === "renter" ? (
                          <li
                            class="list-group-item"
                            style={{ backgroundColor: "transparent" }}
                          >
                            <Link to={`/payment/${selectedData?.booking[0]}`}>
                              {" "}
                              click here to pay
                            </Link>
                          </li>
                        ) : null}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </>

            <div className="main-details col-sm-12">
              <div className="details col-sm-12">
                <h4 className="text-capitalize">
                  Title: {selectedData?.title}
                  <h5>
                    <FaLocationArrow style={{ color: "#1d6aba" }} />
                    &nbsp; Location:&nbsp;
                    {selectedData?.address?.location}
                  </h5>
                </h4>

                <h4 className=" mt-4">Short Description</h4>
                <hr />
                <p
                  className="row"
                  style={{
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                    padding: "8px 25px",
                    color: "black",
                  }}
                >
                  {selectedData?.description}
                </p>
                <hr />
                <h4>Details</h4>
                <hr />
                <div
                  className="details-row row d-flex flex-wrap justify-content-between"
                  style={{
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                    padding: "8px",
                  }}
                >
                  <div className="col-lg-6">
                    <ul className="list-unstyled ">
                      <li style={{ textTransform: "capitalize" }}>
                        <FcDownRight /> Listing Type:{" "}
                        {selectedData?.typeOfListing}
                      </li>
                      <li style={{ textTransform: "capitalize" }}>
                        <FcDownRight /> Size: {selectedData?.size} &nbsp;
                        {selectedData?.unit}
                      </li>
                    </ul>
                  </div>

                  <div className="col-lg-6">
                    <ul className="list-unstyled ">
                      <li style={{ textTransform: "capitalize" }}>
                        <FcDownRight /> Storage Type:{" "}
                        {selectedData?.typeOfStorage}
                      </li>
                    </ul>
                  </div>
                </div>
                <hr />
                <h4>Pricing Details</h4>
                <hr />
                <div
                  className="price-details row d-flex flex-wrap justify-content-between"
                  style={{
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                    padding: "8px",
                  }}
                >
                  <div className="col-lg-6">
                    <ul className="list-unstyled">
                      <li>
                        {selectedData?.planType?.weeklyPlan === "available" ? (
                          <li>
                            <FcDownRight />
                            Plan Type: Weekly
                          </li>
                        ) : null}
                        {selectedData?.planType?.monthlyPlan === "available" ? (
                          <li>
                            <FcDownRight />
                            Plan Type: Monthly
                          </li>
                        ) : null}
                        {selectedData?.planType?.yearlyPlan === "available" ? (
                          <li>
                            <FcDownRight />
                            Plan Type: Yearly
                          </li>
                        ) : null}
                      </li>
                    </ul>
                  </div>

                  <div className="col-lg-6">
                    <ul className="list-unstyled ">
                      {selectedData?.pricing?.weekly ? (
                        <li>
                          <FcDownRight /> Weekly Price:{" "}
                          {selectedData?.pricing?.weekly}$
                        </li>
                      ) : null}
                      {selectedData?.pricing?.monthly ? (
                        <li>
                          <FcDownRight /> Monthly Price:{" "}
                          {selectedData?.pricing?.monthly}$
                        </li>
                      ) : null}
                      {selectedData?.pricing?.yearly ? (
                        <li>
                          <FcDownRight /> Yearly Price:{" "}
                          {selectedData?.pricing?.yearly}$
                        </li>
                      ) : null}
                    </ul>
                  </div>
                </div>
                {selectedData?.otherFacilities?.reception === "available" ||
                selectedData?.otherFacilities?.security === "available" ||
                selectedData?.otherFacilities?.airConditioning ===
                  "available" ? (
                  <>
                    <hr />
                    <h4>Amenities</h4>

                    <hr />
                    <div
                      className="facilities row d-flex flex-wrap justify-content-between"
                      style={{
                        borderRadius: "8px",
                        backgroundColor: "#fff",
                        padding: "12px 20px",
                      }}
                    >
                      <div className="col-lg-4 mb-3">
                        {selectedData?.otherFacilities?.reception ===
                        "available" ? (
                          <>
                            <FcDownRight /> Reception
                          </>
                        ) : null}
                      </div>
                      <div className="col-lg-4 mb-3">
                        {selectedData?.otherFacilities?.security ===
                        "available" ? (
                          <>
                            <FcDownRight /> Security
                          </>
                        ) : null}
                      </div>
                      <div className="col-lg-4 ">
                        {selectedData?.otherFacilities?.airConditioning ===
                        "available" ? (
                          <>
                            <FcDownRight /> Air Conditioning
                          </>
                        ) : null}
                      </div>
                    </div>
                  </>
                ) : null}

                <hr />
                <h4>Property Proof Image</h4>
                <hr />
                <div className="row justify-content-center">
                  <div className="col-lg-6 text-center">
                    <img
                      src={
                        `${base_URL}/file/images/` +
                        selectedData?.propertyDocument[0]
                      }
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="row justify-content-center">
              {selectedData?.isVerified === null ? (
                <div className="btns">
                  <button
                    onClick={() => acceptProperty()}
                    className="signupbtnLogin1 mr-5"
                  >
                    <span>Accept Property</span>
                  </button>
                  <button
                    onClick={() => rejectProperty()}
                    className="signupbtnLoginAlt"
                  >
                    <span>Reject Property</span>
                  </button>
                </div>
              ) : null}
            </div>
            <div className="d-flex justify-content-center">
              {selectedData?.isVerified === true ? (
                <h4 className="accepted text-center ">Accepted</h4>
              ) : null}
              {selectedData?.isVerified === false ? (
                <h4 className="rejected">Rejected</h4>
              ) : null}
            </div>
          </section>
          <Loader loader={loader} />
          <Footer />
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  getAllProperty: state.getAllProperty.getAllProperty,
  ownerInfo: state.getOwnerInfo.getOwnerInfo,
  refresh: state.refresh.refresh.refresh,
  refreshFunction: state.refresh.refresh.refreshFunction,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ListingDetails);

/* <div className="top-img">
            <img src={About} width="700px" height="300px" />
          </div>
          <div className="image-grid">
            <div className="grid-item1">
              <img src={About} />
            </div>
            <div className="grid-item2">
              <img src={About} />
            </div>
            <div className="grid-item3">
              <img src={About} />
            </div>
            <div className="grid-item4">
              <img src={About} />
            </div>
          </div> */
{
  /*    <section id="reviews" className="mb-5">
            <div className="w-100 mb-3">
              <h4 style={{ color: "#1d6aba" }}>REVIEWS</h4>
              <div className="host-info mt-4">
                <div className="d-flex justify-content-left">
                  <img
                    src={PP}
                    style={{
                      width: "70px",
                      height: "70px",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />
                  <ul className="list-unstyled  ml-4">
                    <li
                      className="font-weight-bold"
                      style={{ color: "#1D6ABA" }}
                    >
                      <h5> Divash Ranabhat</h5>
                    </li>

                    <li>
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStarHalfAlt />
                    </li>
                  </ul>
                </div>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi
                culpa et dolor nisi maxime distinctio eos quas tenetur quam
                quasi inventore repellendus molestiae blanditiis incidunt
                voluptatum cupiditate aliquid, sint corporis.
              </div>
            </div>

          </section>*/
}
