import React from "react";
import { Redirect, Route } from "react-router-dom";
import { getLocalStorage } from "../Axios/tokenStorage";
import { adminToken, token } from "../Axios/baseURL";

const AdminRoute = ({ component: Component, ...rest }) => {
  const authorize = getLocalStorage(adminToken);

  return (
    <Route
      {...rest}
      render={(props) =>
        authorize ? (
          <Component {...props} />
        ) : (
          (
            <Redirect
              to={{
                pathname: "/admin-login",
                state: { from: props.location },
              }}
            />
          )
        )
      }
    />
  );
};
export default AdminRoute;
