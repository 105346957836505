import React, { useEffect } from "react";
import aboutimg from "../images/AboutUs.jpg";
import customer from "../images/customer.svg";
import favela from "../images/favela.svg";
import deal from "../images/deal.svg";
import Ready from "./Ready";
import Footer from "./Footer";
import AOS from "aos";
import { FaBuilding, FaUserAlt, FaUsers } from "react-icons/fa";
import { FcKey } from "react-icons/fc";
import { FaHandPointRight } from "react-icons/fa";
import notify from "../Notify/notify";

const About = () => {
  useEffect(() => {
    AOS.init({});
  }, []);
  return (
    <>
      <section id="about-page">
        <h1 className="text-center pb-4 pt-4">ABOUT US</h1>
        {/* <button onClick={() => notify.customModal("THis is the message")}>
          onclick
        </button> */}

        <hr />
        <div className="container mb-5">
          <div className="row my-5  justify-content-center text-center">
            <div className="col-lg-8 align-items-center">
              <div className="text text-center">
                <p data-aos="fade-right" data-aos-duration="1000">
                  We like stuff.
                </p>
                <p data-aos="fade-right" data-aos-duration="2000">
                  {" "}
                  We <i style={{ color: "grey" }}> know </i> you like stuff too.
                </p>
                <p data-aos="fade-right" data-aos-duration="3000">
                  {" "}
                  We see the joy it sparks in you.
                </p>
                <p data-aos="fade-right" data-aos-duration="3000">
                  {" "}
                  We value the time and money invested in curating your
                  belongings.
                </p>
                <p data-aos="fade-right" data-aos-duration="3000">
                  {" "}
                  Get rid of it? <i> No way. </i>
                </p>
                <div
                  className=" animate__animated animate__zoomIn"
                  style={{ animationDuration: "3s" }}
                >
                  {" "}
                  <h3
                    style={{
                      color: "#1d6aba",
                      marginBottom: "30px",
                      marginTop: "50px",
                    }}
                  >
                    Store easy with STOZY!
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="about-container justify-content-center"
          style={{ padding: "0 10%" }}
        >
          {" "}
          <div
            className="col-lg-12"
            data-aos="fade-right"
            data-aos-duration="2000"
          >
            <img
              alt=""
              src={aboutimg}
              className="img-fluid"
              style={{
                borderRadius: "8px",
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </div>
          <div
            className="col-lg-12 my-5 text-left"
            data-aos="fade-left"
            data-aos-duration="2000"
          >
            <p>
              Bid farewell to expensive, commercial storage options that offer
              limited rental choices and are difficult to access. Instead, store
              your items at affordable rates right within your community: Stozy
              connects people who have unused spaces, with people in the
              vicinity looking for some room to store their things. It is
              simple, convenient and super flexible!
              <br />
              <br />
              There’s a huge range of spaces available ─ from closets that can
              store a few boxes to entire parking spots. Once a customer moves
              their things into a ‘Stozy’ space, the host receives a payment –
              consequently earning income from an otherwise idle space.
              <br />
              <br />
              Our platform aids in coordinating schedules, managing rental fees
              and ensuring safety for both renters and hosts. The added
              advantage of having a Stozy space nearby is enjoying easy and
              immediate access to all your stuff!
              <br />
            </p>
            <br />
            <h5 className="text-left">Sign up and Stozy today!</h5>
          </div>
          {/* <div className="col-lg-6 col-md-12  text-justify mb-4 animate__animated animate__fadeInRight">
            Bid farewell to expensive, commercial storage options that offer
            limited rental choices and are difficult to access. Instead, store
            your items at affordable rates right within your community: Stozy
            connects people who have unused spaces, with people in the vicinity
            looking for some room to store their things. It is simple,
            convenient and super flexible! There’s a huge range of spaces
            available ─ from closets that can store a few boxes to entire
            parking spots. Once a customer moves their things into a ‘Stozy’
            space, the host receives a payment – consequently earning income
            from an otherwise idle space. Our platform aids in coordinating
            schedules, managing rental fees and ensuring safety for both renters
            and hosts. The added advantage of having a Stozy space nearby is
            enjoying easy and immediate access to all your stuff! Sign up and
            Stozy today!
          </div> */}
          {/* <div className="col-lg-6 col-md-12 animate__animated animate__fadeInLeft">
            <img
              alt=""
              src={aboutimg}
              // className="img-fluid"
              style={{
                borderRadius: "8px",
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </div> */}
        </div>
      </section>

      <section id="about2">
        <div className="about2">
          <div className="col text-center mb-3">
            <div data-aos="fade-right" data-aos-duration="1000">
              <FaUsers style={{ color: "#1d6aba" }} size="148px" />
            </div>
            {/* <img
              alt=""
              src={customer}
              style={{ height: "150px" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            /> */}
            <br />{" "}
            <h4
              className="mt-4 "
              data-aos="fade-up-right"
              data-aos-duration="1000"
            >
              10000 Satisfied Customers
            </h4>
          </div>
          <div className="col text-center mb-3">
            <div data-aos="fade-down" data-aos-duration="1000">
              <FcKey size="148px" />
            </div>
            {/* <img
              alt=""
              src={deal}
              style={{ height: "150px" }}
              data-aos="fade-up"
              data-aos-duration="1000"
            /> */}
            <br />{" "}
            <h4 className="mt-4 " data-aos="fade-up" data-aos-duration="1000">
              {" "}
              100 Direct Interaction
            </h4>
          </div>
          <div className="col text-center mb-3">
            <div data-aos="fade-left" data-aos-duration="1000">
              <FaBuilding style={{ color: "#1d6aba" }} size="148px" />
            </div>
            {/* <img
              alt=""
              src={favela}
              style={{ height: "150px" }}
              
            /> */}
            <br />{" "}
            <h4
              className="mt-4 "
              data-aos="fade-up-left"
              data-aos-duration="1000"
            >
              18000+ Properties
            </h4>
          </div>
        </div>
      </section>
      <Ready />
      <Footer />
    </>
  );
};

export default About;
