import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { FiAlertTriangle } from "react-icons/fi";
import classNames from "classnames";
import NewMap from "../Map/NewMap";
import notify from "../Notify/notify";
import { useHistory } from "react-router-dom";
import HostSidebar from "./HostSidebar";
import { connect } from "react-redux";

import Loader from "./../service/loader/loader";
import { create } from "../Axios/axiosService";




const Add = (props) => {
  const [loader, setLoader] = useState(false);
  const [weeklyClick, setWeeklyClick] = useState(false);

  const [monthlyClick, setMonthlyClick] = useState(false);
  const [yearlyClick, setYearlyClick] = useState(false);
  const history = useHistory();
  const [planError, setPlanError] = useState("");
  const [weeklyUnitPrice, setWeeklyUnitPrice] = useState(0);
  const [monthlyUnitPrice, setMonthlyUnitPrice] = useState(0);
  const [yearlyUnitPrice, setYearlyUnitPrice] = useState(0);

  const [size, setSize] = useState(0);
  const [unit, setUnit] = useState("");

  const [location, setLocation] = useState({});
  const [coordinate, setCoordinate] = useState({});

  const locationCoordinate = (coordinate, location) => {
    setCoordinate(coordinate);
    setLocation(location);
  };

  const { register, handleSubmit, errors } = useForm({
    mode: "onTouched",
  });

  const onSubmit = (data) => {
    setLoader(true);
    if (!weeklyClick && !monthlyClick && !yearlyClick) {
      setPlanError("Please select at least one plan");
      return;
    }
    const myData = {
      title: data.title,
      typeOfListing: data.listingType,
      typeOfStorage: data.storageType,
      description: data.description,
      size: data.size,
      unit: data.unit,
      propertyImage: data.propertyImage,
      propertyDocument: data.propertyDocument,

      otherFacilities: {
        reception: data.reception === true ? "available" : "not available",
        security: data.security === true ? "available" : "not available",
        airConditioning:
          data.airConditioning === true ? "available" : "not available",
      },
      address: {
        location: location,
        street: data.street,
        longitude: coordinate.lng,
        latitude: coordinate.lat,
      },
      planType: {
        weeklyPlan: data.weeklyPlan === true ? "available" : "not available",
        monthlyPlan: data.monthlyPlan === true ? "available" : "not available",
        yearlyPlan: data.yearlyPlan === true ? "available" : "not available",
      },
      pricing: {
        weekly: weeklyClick === true ? weeklyUnitPrice * size : 0,
        monthly: monthlyClick === true ? monthlyUnitPrice * size : 0,
        yearly: yearlyClick === true ? yearlyUnitPrice * size : 0,
      },
    };
    // console.log(myData);

    let finalData = new FormData();
    for (const key of Object.keys(myData)) {
      if (key === "propertyImage") {
        for (let i = 0; i < myData.propertyImage.length; i++) {
          finalData.append("propertyImage", myData.propertyImage[i]);
        }
        // for (let i = 0; i < myData.propertyDocument.length; i++) {
        //   finalData.append("propertyDocument", myData.propertyDocument[i]);
        // }
      } else if (
        ["otherFacilities", "address", "planType", "pricing"].includes(key)
      ) {
        for (const innerkey of Object.keys(myData[key])) {
          finalData.append(`${key}[${innerkey}]`, myData[key][innerkey]);
        }
      } else {
        finalData.append(key, myData[key]);
      }
    }
    create("api/property", finalData)
      .then((res) => {
        props.refreshFunction(6);
        notify.showSuccess("Your Listing is added Successfully");
        history.push("/dashboard-listings");
        setLoader(false);
      })
      .catch((err) => {
        if (err.response) {
          notify.handleError(err.response.data.msg._message + ", Try again");
          console.log(err);
        }
        setLoader(false);
      });
  };

  const getDimension = async (file) => {
    let reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException("Problem parsing input file."));
      };

      reader.onload = () => {
        var image = new Image();
        image.src = reader.result;
        image.onload = function () {
          resolve({ width: this.width, height: this.height });
        };
      };
      reader.readAsDataURL(file);
    });
  };

  return (
    <>
      <section id="main-dashboard">
        <div className="sidebar-row">
          <div className="sidebar-container">
            <HostSidebar />
          </div>
          <div className="dashboard-content">
            <div className="dashboard-title">
              <h5>Dashboard | Add Listing</h5>
            </div>
            <section
              id="add-listing-here"
              className="animate__animated animate__fadeInUp"
            >
              <div className="add-listing pt-3 ">
                <h5>BASIC DETAILS</h5>
                <hr className="mb-3" />
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row mb-4">
                    <div className="col-lg-4 col-md-6 col-sm-12 mb-4 mb-md-0">
                      <div className="form-outline">
                        <label className="form-label">Title</label>
                        <input
                          type="text"
                          name="title"
                          id="title"
                          className={classNames("form-control", {
                            "is-invalid": errors.title,
                          })}
                          placeholder="Enter the listing title."
                          ref={register({
                            required: "Required Field ",
                            minLength: {
                              value: 5,
                              message: "Enter a Valid Name",
                            },
                          })}
                        />
                        {errors.title ? (
                          <p style={{ color: "red", marginBottom: "-10px" }}>
                            <FiAlertTriangle /> {errors.title.message}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 mb-4 mb-md-0">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="listingType">
                          Type of Lisiting?
                        </label>
                        <select
                          id="listingType"
                          className={classNames("form-control", {
                            "is-invalid": errors.listingType,
                          })}
                          ref={register({
                            required: "Required Field ",
                          })}
                          name="listingType"
                        >
                          <option value="">Type of Listing</option>
                          <option value="small room">Small Room</option>
                          <option value="small space">Small Space</option>
                          <option value="closet space">Closet Space</option>
                          <option value="large space">Large Space</option>
                          <option value="others">Others</option>
                        </select>
                        {errors.listingType ? (
                          <p style={{ color: "red", marginBottom: "-10px" }}>
                            <FiAlertTriangle /> {errors.listingType.message}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 mt-md-4 mt-lg-0">
                      <div className="form-outline">
                        <label className="form-label storage-type">
                          Type of Storage?
                        </label>
                        <select
                          id="storageType"
                          name="storageType"
                          className={classNames("form-control", {
                            "is-invalid": errors.storageType,
                          })}
                          ref={register({
                            required: true,
                          })}
                        >
                          <option value="">Type of Storage</option>
                          <option value="small household items">
                            Small Household Items
                          </option>
                          <option value="small personal items">
                            Small Personal Items
                          </option>
                          <option value="large household items">
                            Large Household Items
                          </option>
                          <option value="vehicles">Vehicles</option>
                          <option value="others">Others</option>
                        </select>
                        {errors.storageType && (
                          <p style={{ color: "red", marginBottom: "-10px" }}>
                            <FiAlertTriangle /> Required Field
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-outline mb-4">
                    <label className="form-label">
                      A Short Description about your Listing.
                    </label>
                    <textarea
                      type="text"
                      name="description"
                      id="description"
                      className={classNames("form-control", {
                        "is-invalid": errors.description,
                      })}
                      placeholder="For Example : Entire room in Orchard"
                      ref={register({
                        required: "Required Field",
                      })}
                    />
                    {errors.description ? (
                      <p style={{ color: "red", marginBottom: "-10px" }}>
                        <FiAlertTriangle /> {errors.description.message}
                      </p>
                    ) : null}
                  </div>
                  <div className="row mb-4">
                    <div className="col-lg-6 col-md-6 col-sm-12 mb-4 mb-md-0">
                      <label className="form-label listing-type">Size</label>
                      <input
                        type="number"
                        name="size"
                        className={classNames("form-control", {
                          "is-invalid": errors.size,
                        })}
                        placeholder="Size of Space:"
                        onChange={(e) => setSize(e.target.value)}
                        ref={register({
                          required: "Required Field",
                        })}
                      />
                      {errors.size ? (
                        <p style={{ color: "red", marginBottom: "-10px" }}>
                          <FiAlertTriangle /> {errors.size.message}
                        </p>
                      ) : null}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12  ">
                      <label className="form-label listing-type">Unit</label>
                      <select
                        id="listing-type"
                        name="unit"
                        className={classNames("form-control", {
                          "is-invalid": errors.unit,
                        })}
                        onChange={(e) => setUnit(e.target.value)}
                        ref={register({
                          required: "Required Field",
                        })}
                      >
                        <option value="">Select Unit</option>
                        <option value="square inch">Square Inch</option>
                        <option value="square foot">Square Foot</option>
                        <option value="square meter">Square Meter</option>
                      </select>
                      {errors.unit ? (
                        <p style={{ color: "red", marginBottom: "-10px" }}>
                          <FiAlertTriangle /> {errors.unit.message}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  {/* <div className="my-4">
                    <span
                      className=""
                      style={{ fontSize: "14px", color: "#f2be2e" }}
                    >
                      {" "}
                      <FaInfoCircle className="bgstd" /> &nbsp; For week: “We
                      recommend S$1” &nbsp; || &nbsp; For month: “We recommend
                      S$3” &nbsp; || &nbsp; For Year: “We Recommend S$30{" "}
                    </span>
                  </div> */}

                  <div className="row mb-4">
                    <div className="col-lg-4 col-md-6 col-sm-12  mb-3 mb-md-0">
                      <label className="form-label listing-type">
                        Weekly Price Per Unit
                      </label>
                      <input
                        id="unitPrice"
                        name="unitPrice"
                        type="number"
                        placeholder="We
                        recommend S$1"
                        className={classNames("form-control", {
                          "is-invalid": errors.unitPrice,
                        })}
                        ref={register({
                          required: "Required Field",
                        })}
                        onChange={(e) => setWeeklyUnitPrice(e.target.value)}
                      ></input>
                      {errors.unitPrice ? (
                        <p style={{ color: "red", marginBottom: "-10px" }}>
                          <FiAlertTriangle /> {errors.unitPrice.message}
                        </p>
                      ) : null}
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12  mb-3 mb-md-3 mb-lg-0 ">
                      <label className="form-label listing-type">
                        Monthly Price Per Unit
                      </label>
                      <input
                        id="unitPrice"
                        name="unitPrice"
                        type="number"
                        placeholder="We recommend
                        S$3 "
                        className={classNames("form-control", {
                          "is-invalid": errors.unitPrice,
                        })}
                        ref={register({
                          required: "Required Field",
                        })}
                        onChange={(e) => setMonthlyUnitPrice(e.target.value)}
                      ></input>
                      {errors.unitPrice ? (
                        <p style={{ color: "red", marginBottom: "-10px" }}>
                          <FiAlertTriangle /> {errors.unitPrice.message}
                        </p>
                      ) : null}
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12   mb-3 mb-md-0">
                      <label className="form-label listing-type">
                        Yearly Price Per Unit
                      </label>
                      <input
                        id="unitPrice"
                        name="unitPrice"
                        type="number"
                        placeholder="We Recommend S$30"
                        className={classNames("form-control", {
                          "is-invalid": errors.unitPrice,
                        })}
                        ref={register({
                          required: "Required Field",
                        })}
                        onChange={(e) => setYearlyUnitPrice(e.target.value)}
                      ></input>
                      {errors.unitPrice ? (
                        <p style={{ color: "red", marginBottom: "-10px" }}>
                          <FiAlertTriangle /> {errors.unitPrice.message}
                        </p>
                      ) : null}
                    </div>
                  </div>

                  {/* <div className="form-outline mb-4">
                    <label className="label">
                      Choose more than one location images to Upload:
                    </label>{" "}
                    <br />
                    <input
                      type="file"
                      name="propertyImage"
                      className={classNames("form-control", {
                        "is-invalid": errors.propertyImage,
                      })}
                      onChange={(e) => e.target.files}
                      multiple
                      ref={register({ required: "Required field" })}
                    />
                    {errors.propertyImage ? (
                      <p style={{ color: "red", marginBottom: "-10px" }}>
                        <FiAlertTriangle /> {errors.propertyImage.message}
                      </p>
                    ) : null}
                  </div> */}

                  <div className="row mb-4">
                    <div className="col-lg-6 col-md-6 col-sm-12 mb-4 mb-md-0">
                      <label className="label">
                        Choose more than one location images to Upload:
                      </label>{" "}
                      <br />
                      <input
                        type="file"
                        name="propertyImage"
                        className={classNames("form-control", {
                          "is-invalid": errors.propertyImage,
                        })}
                        onChange={(e) => e.target.files}
                        multiple
                        ref={register({
                          required: "Required field",
                          validate: async (value) => {
                            console.log(value[0]);
                            const fileTypes = ["jpg", "png", "jpeg"];
                            const fileType = value[0].name.split(".")[1];

                            if (!fileTypes.includes(fileType)) {
                              return `Please upload a valid file format. (${fileTypes})`;
                            }

                            const sizes = await getDimension(value[0]);
                            // if (sizes.width > 1000 && sizes.height > 1000) {
                            //   return "Image width and height must be less than or equal to 1000px";
                            // }

                            const fileSize = Math.round(value[0].size / 1024);
                            if (fileSize > 500) {
                              return "File size must be lower than 500kb";
                            }
                          },
                        })}
                      />
                      {errors.propertyImage ? (
                        <p style={{ color: "red", marginBottom: "-10px" }}>
                          <FiAlertTriangle /> {errors.propertyImage.message}
                        </p>
                      ) : null}
                    </div>

                    {/* <div className="col-lg-6 col-md-6 col-sm-12  ">
                      <label className="label">
                        Upload the document of claim of the Property.
                      </label>{" "}
                      <br />
                      <input
                        type="file"
                        name="propertyDocument"
                        className={classNames("form-control", {
                          "is-invalid": errors.propertyDocument,
                        })}
                        onChange={(e) => e.target.files}
                        multiple
                        ref={register({
                          required: "Required field",
                          validate: async (value) => {
                            console.log(value[0]);
                            const fileTypes = ["jpg", "png", "jpeg"];
                            const fileType = value[0].name.split(".")[1];

                            if (!fileTypes.includes(fileType)) {
                              return `Please upload a valid file format. (${fileTypes})`;
                            }

                            const sizes = await getDimension(value[0]);
                            // if (sizes.width > 1000 && sizes.height > 1000) {
                            //   return "Image width and height must be less than or equal to 1000px";
                            // }

                            const fileSize = Math.round(value[0].size / 1024);
                            if (fileSize > 500) {
                              return "File size must be lower than 500kb";
                            }
                          },
                        })}
                      />
                      {errors.propertyDocument ? (
                        <p style={{ color: "red", marginBottom: "-10px" }}>
                          <FiAlertTriangle /> {errors.propertyDocument.message}
                        </p>
                      ) : null}
                    </div> */}
                  </div>
                  <div className="col">
                    <label className="form-label">Amenities:</label> <br />
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="reception"
                        id="reception"
                        ref={register()}
                      />
                      <label className="form-check-label">Reception</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="security"
                        id="security"
                        ref={register()}
                      />
                      <label className="form-check-label">Security</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="airConditioning"
                        name="airConditioning"
                        ref={register()}
                      />
                      <label className="form-check-label">
                        Air Conditioning
                      </label>
                    </div>
                  </div>

                  <hr className="mt-5" />
                  <h5>Location Details</h5>
                  <hr className="mb-3" />
                  <div className="row mb-4">
                    <div className="col-lg-6 mb-3 mb-lg-0">
                      <label className="control-label">Location:</label>
                      <NewMap
                        locationCoordinate={locationCoordinate}
                        name="location"
                        // errors={errors}
                        register={register}
                      />
                      {errors.location ? (
                        <p style={{ color: "red", marginBottom: "-10px" }}>
                          <FiAlertTriangle /> {errors.location.message}
                        </p>
                      ) : null}
                    </div>
                    <div className="col-lg-6">
                      <label className="form-label listing-type">Street</label>
                      <input
                        type="text"
                        name="street"
                        className={classNames("form-control", {
                          "is-invalid": errors.street,
                        })}
                        placeholder="Street/Room No/Address Detail"
                        ref={register({
                          required: "Required Field",
                        })}
                      />
                      {errors.street ? (
                        <p style={{ color: "red", marginBottom: "-10px" }}>
                          <FiAlertTriangle /> {errors.street.message}
                        </p>
                      ) : null}
                    </div>
                  </div>

                  <div className="form-outline row"></div>
                  <hr className="mt-5" />
                  <h5>PLANS AND PRICING DETAILS</h5>
                  <hr className="mb-3" />
                  <div className="row form-check mb-5">
                    <label className="form-label">
                      Choose Payment Plan Type:
                    </label>{" "}
                    {}
                    <br />
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="weeklyPlan"
                        id="weeklyPlan"
                        onClick={() => setWeeklyClick(!weeklyClick)}
                        ref={register}
                      />

                      <label
                        className="form-check-label"
                        htmlFor="inlineCheckbox1"
                      >
                        Weekly
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="monthlyPlan"
                        id="monthlyPlan"
                        onClick={() => setMonthlyClick(!monthlyClick)}
                        ref={register}
                      />
                      <label className="form-check-label">Monthly</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="yearlyPlan"
                        name="yearlyPlan"
                        onClick={() => setYearlyClick(!yearlyClick)}
                        ref={register({
                          // required:
                          //   weeklyClick === true ||
                          //   monthlyClick === true ||
                          //   yearlyClick === true
                          //     ? "req"
                          //     : false,
                        })}
                      />

                      <label className="form-check-label">Yearly</label>
                    </div>
                    {planError &&
                    !weeklyClick &&
                    !monthlyClick &&
                    !yearlyClick ? (
                      <p
                        style={{
                          color: "red",
                          marginBottom: "-10px",
                          fontSize: "12px",
                        }}
                      >
                        <FiAlertTriangle /> {planError}
                      </p>
                    ) : null}
                  </div>
                  <div className="row mb-5">
                    {weeklyClick === true ? (
                      <div className="col-lg-4 col-md-6 col-sm-12  mb-3 mb-md-0">
                        <div className="form-outline">
                          <label className="form-label">
                            Weekly {`($${weeklyUnitPrice} per ${unit})`}
                          </label>
                          <input
                            type="text"
                            id="weekly"
                            name="weekly"
                            disabled
                            value={weeklyUnitPrice * size}
                            className={classNames("form-control", {
                              "is-invalid": errors.weekly,
                            })}
                            placeholder="Enter Weekly Price."
                            ref={register({
                              required: "Required Field ",
                            })}
                          />
                          {errors.weekly ? (
                            <p style={{ color: "red", marginBottom: "-10px" }}>
                              <FiAlertTriangle /> {errors.weekly.message}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                    {monthlyClick === true ? (
                      <div className="col-lg-4 col-md-6 col-sm-12  mb-3 mb-md-3 mb-lg-0">
                        <div className="form-outline">
                          <label className="form-label">
                            Monthly {`($${monthlyUnitPrice} per ${unit})`}
                          </label>
                          <input
                            type="text"
                            id="monthly"
                            name="monthly"
                            value={monthlyUnitPrice * size}
                            disabled
                            className={classNames("form-control", {
                              "is-invalid": errors?.monthly,
                            })}
                            placeholder="Enter Monthly Price ."
                            ref={register({
                              required: "Required Field ",
                            })}
                          />
                          {errors.weekly ? (
                            <p style={{ color: "red", marginBottom: "-10px" }}>
                              <FiAlertTriangle /> {errors.monthly.message}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                    {yearlyClick === true ? (
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="form-outline">
                          <label className="form-label">
                            Yearly {`($${yearlyUnitPrice} per ${unit})`}
                          </label>
                          <input
                            type="text"
                            id="yearly"
                            name="yearly"
                            value={yearlyUnitPrice * size}
                            disabled
                            className={classNames("form-control", {
                              "is-invalid": errors.yearly,
                            })}
                            placeholder="Enter Yearly Price."
                            ref={register({
                              required: "Required Field ",
                            })}
                          />
                          {errors.yearly ? (
                            <p style={{ color: "red", marginBottom: "-10px" }}>
                              <FiAlertTriangle /> {errors.yearly.message}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <button
                    type="submit"
                    className="signupbtnLogin btn-primary btn-block mb-4"
                  >
                    Submit
                  </button>
                </form>
              </div>
            </section>
          </div>
        </div>
        <Loader loader={loader} />
      </section>
    </>
  );
};
const mapStateToProps = (state) => ({
  refresh: state.refresh.refresh.refresh,
  refreshFunction: state.refresh.refresh.refreshFunction,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Add);
