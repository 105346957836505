import { GET_ALL_PROPERTY } from "../action/action";

const initialState = { getAllProperty: [] };
export const getAllPropertyListReducer = (state = initialState, action) => {
  switch (action.type) {
  case GET_ALL_PROPERTY:
    return { ...state, getAllProperty: action.payload };

  default:
    return { ...state };
  }
};
