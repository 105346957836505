import { RENTER_FAVOURITIES_PROPERTY } from "../action/action";

const initialState = { renterFavouritesProperty: [] };
export const renterFavouritesPropertyReducer = (state = initialState, action) => {
  switch (action.type) {
  case RENTER_FAVOURITIES_PROPERTY:
    return { ...state, renterFavouritesProperty: action.payload };

  default:
    return { ...state };
  }
};
