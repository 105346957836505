import React from "react";
import {
  FaBars,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import logo from "../images/logo2.png";

const NewNav = () => {
  return (
    <>
      <nav className="main-nav navbar navbar-expand-md sticky-top ">
        <div
          className="navbar-toggler-right"
          style={{
            width: "100%",
            flexDirection: "row",
            display: "flex",
            alignItems: "stretch",
          }}
          id="dropper"
        >
          <div style={{ flex: "1" }}></div>
          <div
            className="ml-4"
            style={{
              flex: "15",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <Link
              to="/"
              className="navbar-brand"
              // style={{ fontSize: "18px", fontWeight: "bolder" }}
            >
              <img src={logo} className="nav-logo" style={{ height: "20px" }} />
            </Link>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
            style={{ flex: "1", alignSelf: "left" }}
          >
            <span className="navbar-toggler-icon">
              <FaBars className="navbar-toggler-icon" />{" "}
            </span>
          </button>
        </div>
        {/* for smaller screen */}
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul
            className="navbar-nav ml-auto mt-2 mt-lg-0"
            style={{ textAlign: "center" }}
          >
            <div className="dropdown-divider"></div>
            <li className="nav-item">
              <Link
                className="nav-link signInbtn"
                to="/login"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                Sign In
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link signInbtn"
                to="/signup"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                Sign Up
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link "
                to="/host"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                Become a Host
              </Link>
            </li>
            <div className="dropdown-divider"></div>
            <li className="nav-item">
              <Link className="" to="#" style={{ color: "black" }}>
                <FaFacebook style={{ fontSize: "20px" }} />
              </Link>
              &nbsp;&nbsp;
              <Link className="" to="#" style={{ color: "black" }}>
                <FaInstagram style={{ fontSize: "20px" }} />
              </Link>
              &nbsp;&nbsp;
              <Link className="" to="#" style={{ color: "black" }}>
                <FaTwitter style={{ fontSize: "20px" }} />
              </Link>
              &nbsp;&nbsp;
              <Link className="" to="#" style={{ color: "black" }}>
                <FaLinkedin style={{ fontSize: "20px" }} />
              </Link>
              &nbsp;&nbsp;
              <Link className="" to="#" style={{ color: "black" }}>
                <FaYoutube style={{ fontSize: "20px" }} />
              </Link>
            </li>
            <div className="dropdown-divider"></div>
            <li className="nav-item">
              <Link
                className="nav-link"
                to="/"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                to="/about"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                About us
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                to="/search-location"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                Stozy Your Stuff
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                to="/dashboard-add"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                List your space
              </Link>
            </li>

            <li className="nav-item">
              <Link
                className="nav-link"
                to="/dashboard"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                Manage Booking
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                to="/contact"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                Contact Us
              </Link>
            </li>

            {/* <li className="nav-item">
              <Link
                className="dropdown-item"
                to="/about"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                About Us
              </Link>
              <Link
                className="dropdown-item"
                to="/search-location"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                Stozy your Stuff
              </Link>
              <Link
                className="dropdown-item"
                to="/dashboard-add"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                List your Space
              </Link>
              <Link
                className="dropdown-item"
                to="/dashboard"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                Manage Booking
              </Link>
              <Link
                className="dropdown-item"
                to="/contact"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                Contact Us
              </Link> */}
            {/* <Link
                className="nav-link dropdown-toggle"
                to="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                More
              </Link> */}
            {/* <div
                className="dropdown-menu dropdown-menu"
                aria-labelledby="navbarDropdown"
                style={{ margin: "auto" }}
              ></div> */}
            {/* </li> */}
          </ul>
        </div>

        {/* for bigger screen */}
        <div className="collapse navbar-collapse flex-column" id="navbar">
          <div className="first-nav d-flex justify-content-between w-100 px-3 py-1">
            <ul className="navbar-nav socialIconNav w-100 justify-content-start px-3 align-items-center">
              <li className="nav-item ">
                <Link className="nav-link nav-icons" to="#">
                  <FaFacebook style={{ fontSize: "16px" }} />
                </Link>
              </li>
              <li className="nav-item ">
                <Link className="nav-link  nav-icons" to="#">
                  <FaInstagram style={{ fontSize: "16px" }} />{" "}
                </Link>
              </li>
              <li className="nav-item ">
                <Link className="nav-link  nav-icons" to="#">
                  <FaTwitter style={{ fontSize: "16px" }} />
                </Link>
              </li>
              <li className="nav-item ">
                <Link className="nav-link  nav-icons" to="#">
                  <FaLinkedin style={{ fontSize: "16px" }} />
                </Link>
              </li>
              <li className="nav-item ">
                <Link className="nav-link  nav-icons" to="#">
                  <FaYoutube style={{ fontSize: "16px" }} />
                </Link>
              </li>
            </ul>

            <ul className="navbar-nav logo-nav w-100 justify-content-center px-3">
              <li className="nav-item active">
                <Link
                  to="/"
                  className="navbar-brand"
                  style={{ fontSize: "24px", fontWeight: "bolder" }}
                >
                  <img src={logo} height="60px" />
                </Link>
              </li>
            </ul>
            <ul className="navbar-nav w-100 login-signup justify-content-end align-items-center">
              <>
                <li className="nav-item">
                  <Link
                    to="/login"
                    // className="nav-link btn signInbtn"
                    style={{ fontSize: "14px", padding: "4px 8px" }}
                  >
                    Sign In
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/signup"
                    // className="nav-link btn signInbtn"
                    style={{ fontSize: "14px", padding: "4px 8px" }}
                  >
                    Sign Up{" "}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link btn"
                    to="/host"
                    style={{ fontSize: "14px", padding: "4px 8px" }}
                  >
                    Become a Host{" "}
                  </Link>
                </li>
              </>
            </ul>
          </div>

          <ul className="navbar-nav second-nav justify-content-center w-100 px-3 p-2">
            <li>
              {" "}
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about" className="navTab">
                {" "}
                About Us
              </Link>
            </li>
            <li>
              <Link to="/search-location"> Stozy Your Stuff</Link>
            </li>
            <li>
              <Link to="/dashboard-add">List your Space</Link>
            </li>
            <li>
              <Link to="/dashboard">Manage Booking</Link>
            </li>
            <li>
              <Link to="/contact">Contact Us</Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default NewNav;
