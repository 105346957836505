import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { FcInfo } from "react-icons/fc";
import { FiAlertTriangle } from "react-icons/fi";
import classNames from "classnames";
import CountUp from "react-countup";
import { create } from "../Axios/axiosService";
import notify from "../Notify/notify";
import { connect } from "react-redux";
import Loader from "./../service/loader/loader";

const BookingCard = (props) => {
  const [loader, setLoader] = useState(false);

  const { register, handleSubmit, errors } = useForm({
    mode: "onTouched",
  });

  const onSubmit = (data) => {
    setLoader(true);
    create("api/booking", {
      property: props.id,
      start_date: data.start_date,
      planType: data.planType,
      pickup_time: data.pickup_time,
      storagePlan: data.storagePlan,
      messageForHost: data.messageForHost,
      price: getTotalPrice(),
    })
      .then((res) => {
        notify.showSuccess(
          "Your Request  is Submitted to Owner Successfully, wait for the Confirmation"
        );
        setLoader(false);
        props.refreshFunction(1);
      })
      .catch((err) => {
        notify.handleError(err.response.data.msg.error);
        setLoader(false);
      });
  };

  const [selectPlan, setSelectPlan] = useState(null);
  //const [listingDetail, setListingDetail] = useState([]);
  //const [profileDetail, setProfileDetail] = useState([]);

  // const token = localStorage.getItem("token");
  const { id } = useParams();

  const listingDetail = props.allProperty;
  const profileDetail = props.ownerInfo;

  const selectedData = listingDetail.filter((arg) => arg._id === id)[0];
  const getTotalPrice = () => {
    if (selectPlan === "weekly") {
      return props.weekly;
    } else if (selectPlan === "monthly") {
      return props.monthly;
    } else {
      return props.yearly;
    }
  };

  return (
    <>
      <div className="booking-card">
        <div className="card">
          {profileDetail.email === selectedData?.owner?.email ? (
            <h3 className="card-header">Free</h3>
          ) : (
            <h3 className="card-header">
              {props.weekly ? (
                <>
                  {props.weekly}$ <sub>per week</sub>
                </>
              ) : props.monthly ? (
                <>
                  {props.monthly}$ <sub>per month</sub>
                </>
              ) : (
                <>
                  {props.yearly}$ <sub>per year</sub>
                </>
              )}
            </h3>
          )}
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* <div className="row mb-4">
                <div className="col"> */}
              <div className="form-outline mb-3">
                <label className="form-label" htmlFor="listing-title">
                  Start Date
                </label>
                <input
                  type="date"
                  id="start_date"
                  name="start_date"
                  className="form-control"
                  ref={register({
                    valueAsDate: true,
                    required: "Required",
                  })}
                />
                {errors.start_date ? (
                  <p style={{ color: "red", marginBottom: "-10px" }}>
                    <FiAlertTriangle /> {errors.start_date.message}
                  </p>
                ) : null}
              </div>
              {/* </div>
                <div className="col"> */}
              <div className="form-outline mb-3">
                <label className="form-label" htmlFor="listing-title">
                  PickUp Time
                </label>
                <input
                  type="time"
                  name="pickup_time"
                  className="form-control"
                  ref={register({
                    required: "Required",
                  })}
                />
                {errors.pickup_time ? (
                  <p style={{ color: "red", marginBottom: "-10px" }}>
                    <FiAlertTriangle /> {errors.pickup_time.message}
                  </p>
                ) : null}
              </div>
              {/* </div>
              </div> */}
              <label htmlFor="planType">Select Available Plan Type:</label>
              <div className="row mb-4 ml-2">
                {props.weekly ? (
                  <div className="col form-check ">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="planType"
                      id="weekly"
                      value="weekly"
                      ref={register()}
                      onChange={(e) => setSelectPlan(e.target.value)}
                    />
                    <label className="form-check-label" htmlFor="weekly">
                      Weekly
                    </label>
                  </div>
                ) : null}
                {props.monthly ? (
                  <div className="col form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="planType"
                      id="monthly"
                      value="monthly"
                      ref={register()}
                      onChange={(e) => setSelectPlan(e.target.value)}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio2">
                      Monthly
                    </label>
                  </div>
                ) : null}

                {props.yearly ? (
                  <div className="col form-check ">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="planType"
                      id="yearly"
                      value="yearly"
                      ref={register()}
                      onChange={(e) => setSelectPlan(e.target.value)}
                    />
                    <label className="form-check-label" htmlFor="yearly">
                      Yearly
                    </label>
                  </div>
                ) : null}
              </div>

              <div className="form-outline mb-4">
                <select
                  id="storagePlan"
                  className={classNames("form-control", {
                    "is-invalid": errors.storagePlan,
                  })}
                  ref={register({
                    required: "Required Field ",
                  })}
                  name="storagePlan"
                >
                  <option value="">How Long do you Plan for Storing?</option>
                  {props.weekly && selectPlan === "weekly" ? (
                    <>
                      <option value="a week">A Week</option>
                      <option value="several weeks">Several Weeks</option>
                    </>
                  ) : null}

                  {props.monthly && selectPlan === "monthly" ? (
                    <>
                      <option value="a month">A Month</option>
                      <option value="several months">Several Month</option>
                    </>
                  ) : null}
                  {props.yearly && selectPlan === "yearly" ? (
                    <>
                      <option value="a year">A Year</option>
                      <option value="several years">Several Year</option>
                    </>
                  ) : null}
                </select>
                {errors.storagePlan ? (
                  <p style={{ color: "red", marginBottom: "-10px" }}>
                    <FiAlertTriangle /> {errors.storagePlan.message}
                  </p>
                ) : null}
              </div>
              <div className="form-outline mb-4">
                <textarea
                  type="text"
                  id="messageForHost"
                  name="messageForHost"
                  placeholder="Message to the Host"
                  className={classNames("form-control", {
                    "is-invalid": errors.messageForHost,
                  })}
                  ref={register({
                    required: "Required Field ",
                  })}
                />
                {errors.messageForHost ? (
                  <p style={{ color: "red", marginBottom: "-10px" }}>
                    <FiAlertTriangle /> {errors.messageForHost.message}
                  </p>
                ) : null}
              </div>
              <div className="totalPrice">
                {selectPlan ? (
                  <h5 className="text-center">
                    Your Total Price is :{" "}
                    <h1 style={{ color: "#1d6aba" }}>
                      {" "}
                      <CountUp end={getTotalPrice()} duration={1} />$
                    </h1>
                  </h5>
                ) : null}
              </div>

              <div className="text-center ">
                {profileDetail.email === selectedData?.owner?.email ? (
                  <button
                    type="button"
                    className="signupbtnLogin w-100 p-2 mb-3"
                    style={{ display: "inline-block" }}
                  >
                    You cannot book your own Listing
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="signupbtnLogin w-100 p-2 mb-3"
                    style={{ display: "inline-block" }}
                  >
                    Request to Book
                  </button>
                )}
                <FcInfo /> <small> You won't be charged yet.</small>
                {/* <a
                  href="#"
                  className="signupbtnCreate w-100 p-2 mb-3 mt-2"
                  style={{ display: "inline-block" }}
                >
                  {" "}
                  <FaRegHeart /> Add to Favourite
                </a>
                <a
                  href="#"
                  className="signupbtnCreate w-100 p-2 mb-3"
                  style={{ display: "inline-block" }}
                >
                  {" "}
                  <FcPhone /> Contact Host
                </a>
                <a
                  href="#"
                  className="signupbtnCreate w-100 p-2 mb-3"
                  style={{ display: "inline-block" }}
                >
                  {" "}
                  <FcPrint /> Print this Page
                </a> */}
              </div>
            </form>
          </div>
        </div>
      </div>
      <Loader loader={loader} />
    </>
  );
};
const mapStateToProps = (state) => ({
  allProperty: state.getAllProperty.getAllProperty,
  ownerInfo: state.getOwnerInfo.getOwnerInfo,
  refresh: state.refresh.refresh.refresh,
  refreshFunction: state.refresh.refresh.refreshFunction,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(BookingCard);
