export const GET_OWNER_INFO = "GET_OWNER_INFO";
export const GET_ALL_PROPERTY = "GET_ALL_PROPERTY";
export const ALL_FEATURED_PROPERTY = "ALL_FEATURED_PROPERTY";
export const OWNER_PROPERTY = "OWNER_PROPERTY";
export const OWNER_FEATURED_PROPERTY = "OWNER_FEATURED_PROPERTY";
export const RENTER_BOOKED_PROPERTY = "RENTER_BOOKED_PROPERTY";
export const RENTER_FAVOURITIES_PROPERTY = "RENTER_FAVOURITIES_PROPERTY";
export const REFRESH_PAGE = "REFRESH_PAGE";
export const RENTER_INFO = "RENTER_INFO";
export const OWNER_BOOKED_PROPERTY = "OWNER_BOOKED_PROPERTY";
export const ALL_BOOKED_PROPERTY = "All_BOOKED_PROPERTY";
export const RENTAL_BOX_QUERY_REQUEST = "RENTAL_BOX_QUERY_REQUEST";
export const RENTAL_SPACE_QUERY_REQUEST = "RENTAL_SPACE_QUERY_REQUEST";
export const ADMIN_ALL_BOX_QUERY_REQUEST = "ADMIN_ALL_BOX_QUERY_REQUEST";
export const ADMIN_ALL_SPACE_QUERY_REQUEST = "ADMIN_ALL_SPACE_QUERY_REQUEST";













