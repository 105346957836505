import { ALL_FEATURED_PROPERTY } from "../action/action";

const initialState = { allFeaturedProperty: [] };
export const allFeaturedPropertyReducer = (state = initialState, action) => {
  switch (action.type) {
  case ALL_FEATURED_PROPERTY:
    return { ...state, allFeaturedProperty: action.payload };

  default:
    return { ...state };
  }
};
