import React, { useEffect } from "react";
import { FaHandPointRight } from "react-icons/fa";
import how1 from "../images/host1.png";
import how2 from "../images/host2.png";
import how3 from "../images/host3.png";
import landingPage from "../images/homepage11.png";
import { Link } from "react-router-dom";
import Footer from "../Public/Footer";
import { getLocalStorage } from "../Axios/tokenStorage";
import { role, token } from "../Axios/baseURL";
import AOS from "aos";

const HostLandingPage = () => {
  //initialization for animation
  useEffect(() => {
    AOS.init({
      duration: 100,
    });
  }, []);

  return (
    <>
      <section id="host-page">
        <div className="host-container">
          <div className="section1 col-lg-6 col-md-12 animate__animated animate__fadeInLeft">
            <h1 style={{ fontSize: "32px", marginBottom: "40px" }}>
              KNOW YOUR EMPTY PROPERTY'S WORTH
            </h1>
            <h5 className="mb-5 text-justify">
              There is nothing easier than making money from any spare space you
              have available – whether it’s a closet, a few shelves, a parking
              spot or an entire room! For minimal effort, you can kick back and
              let STOZY generate a supplemental income for you!
            </h5>
            <h5>
              <ul className="list-unstyled">
                {" "}
                <li>
                  {" "}
                  <FaHandPointRight color="#1d6aba" className="host-list" />
                  &nbsp; Hassle-free shortcut to earning a passive income (bid
                  adieu to agent fees){" "}
                </li>
                <li>
                  <FaHandPointRight color="#1d6aba" />
                  &nbsp; You choose the prices you charge!
                </li>
                <li>
                  <FaHandPointRight color="#1d6aba" />
                  &nbsp; Guaranteed safety with our liability contracts!!
                </li>
                <li>
                  <FaHandPointRight color="#1d6aba" />
                  &nbsp; We take care of all the marketing and promotions{" "}
                </li>
              </ul>
            </h5>
          </div>
          <div className="section2  col-lg-6 col-md-12 animate__animated animate__fadeInRight">
            <div>
              <img alt="" src={landingPage} className="imageHost ml-5 mt-5" />
            </div>
            <div className="host-listing-section text-center ml-5 mt-5">
              <h3>Are You Ready?</h3>
              {getLocalStorage(token) && getLocalStorage(role) === "owner" ? (
                <Link
                  to="dashboard-add"
                  className="signupbtnLogin1"
                  style={{ height: "min-content" }}
                >
                  <span>Add Property</span>
                </Link>
              ) : (
                <Link
                  to="host/signup"
                  className="signupbtnLogin1"
                  style={{ height: "min-content" }}
                >
                  <span>Sign up as a Host</span>
                </Link>
              )}
            </div>
          </div>
        </div>
      </section>
      <section id="host-page3">
        <div className="main-row" data-aos="fade-up">
          <h2>
            BEGIN HOSTING IN <b style={{ color: "#1d6aba" }}>3</b> SIMPLE STEPS
          </h2>
        </div>
        <div className="hsection3 text-center">
          <div className="howit1 col-lg-4 " data-aos="fade-right">
            <img alt="" src={how1} style={{ height: "250px" }} />
            <h4 className="mt-5 text-center">
              List your <b style={{ color: "#1d6aba" }}> space</b> for{" "}
              <b style={{ color: "#1d6aba" }}> free.</b>
            </h4>
            <p className="text-center">
              {/* Find your perfect fit. Use our filters - like product type, sizem
              location, and duration - to personalize your search and locate the
              ideal listing. */}
              Any unused space you own becomes an income generator! Mention the
              specifications of the available space and create a listing.
            </p>
          </div>
          <div className="howit1 col-lg-4" data-aos="fade-up">
            <img alt="" src={how2} style={{ height: "250px" }} />
            <h4 className="mt-5 text-center">
              Your <b style={{ color: "#1d6aba" }}> preferences</b> matter!
            </h4>
            <p>
              You decide how you want to host your space, you can choose your
              own timeline, charges and requirements for customers. You can also
              view customer reviews.
            </p>
          </div>
          <div className="howit1 col-lg-4" data-aos="fade-left">
            <img alt="" src={how3} style={{ height: "250px" }} />
            <h4 className="mt-5 text-center">
              {" "}
              Sit back and <b style={{ color: "#1d6aba" }}>earn.</b>
            </h4>
            <p>
              Once your listing is vetted, relax and watch us earn some money
              for you. It really is that easy
            </p>
          </div>
        </div>
        {getLocalStorage(role) === "renter" ? null : (
          <div className="text-center mt-4">
            <h3 style={{ color: "#1d6aba" }} data-aos="fade-right">
              Best price for your Property
            </h3>
            <Link
              to="/dashboard-add"
              className="signupbtnLogin1 mt-3"
              data-aos="fade-left"
            >
              <span>List Your Space</span>
            </Link>
          </div>
        )}
      </section>
      <Footer />
    </>
  );
};

export default HostLandingPage;
