import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import { FcDownRight } from "react-icons/fc";
import { FaLocationArrow } from "react-icons/fa";
import BookingCard from "../Booking/BookingCard";
import { getLocalStorage } from "../Axios/tokenStorage";
import { token } from "../Axios/baseURL";
import Footer from "../Public/Footer";
import ImageGallery from "../images/images";
import SyncLoader from "react-spinners/SyncLoader";
//import { MapContainer, TileLayer, Popup, Marker } from "react-leaflet";
//import Autocomplete2 from "../Map/AutoComplete2";
import { base_URL } from "../Axios/baseURL";

import { connect } from "react-redux";

const ListingDetails = (props) => {
  const { id } = useParams();

  getLocalStorage(token);

  const [loading] = useState(false);
  const listingDetail = props?.getAllProperty;
  const listingData = props?.getAllProperty;

  const selectedData = listingDetail?.filter((arg) => arg._id === id)[0];
  console.log(selectedData);
  const filteredData1 = listingData.filter(
    (args) => args.isVerified === true && args.booking_status === "available"
  );
  console.log(filteredData1);

  // console.log(position);
  return (
    <>
      {loading ? (
        <>
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "80vh", top: "50%" }}
          >
            <SyncLoader size={18} color="#1d6aba" margin="2" />
          </div>
        </>
      ) : (
        <>
          {/*map location*/}

          <div className="half-map col-sm-4 animate__animated animate__fadeInLeft"></div>
          <section id="listing-details">
            <>
              <div className="listing-details col-lg-12">
                <div className="top-img col-lg-7 float-left">
                  <ImageGallery />
                </div>
                <div className="booking-card col-lg-4">
                  {!selectedData ? <h2>Property Not Verified</h2> : null}
                  {selectedData?.isBooked === false ? (
                    <BookingCard
                      id={selectedData?._id}
                      weekly={selectedData?.pricing?.weekly}
                      monthly={selectedData?.pricing?.monthly}
                      yearly={selectedData?.pricing?.yearly}
                    />
                  ) : null}
                  {selectedData?.isBooked === true ? (
                    <div className="card">
                      <h3 className="card-header">Renter Information</h3>
                      <div className="card-body">
                        <ul class="list-group list-group-flush text-capitalize">
                          {/* <li
                            class="list-group-item"
                            style={{ backgroundColor: "transparent" }}
                          >
                            Booked by :
                            <span>{selectedData?.booked_by?.name}</span>
                          </li> */}

                          <li
                            class="list-group-item"
                            style={{ backgroundColor: "transparent" }}
                          >
                            Booked Date :
                            {new Date(
                              selectedData?.createdAt
                            ).toLocaleDateString()}
                          </li>
                          <li
                            class="list-group-item"
                            style={{ backgroundColor: "transparent" }}
                          >
                            Booking Status : {selectedData?.booking_status}
                          </li>
                          {/* <li
                            class="list-group-item"
                            style={{ backgroundColor: "transparent" }}
                          >
                            Email :<span>{selectedData?.booked_by?.email}</span>
                          </li>
                          <li
                            class="list-group-item"
                            style={{ backgroundColor: "transparent" }}
                          >
                            Phone :<span>{selectedData?.booked_by?.phone}</span>
                          </li> */}
                          {selectedData?.booking_status === "accepted" &&
                          getLocalStorage("role") === "renter" ? (
                            <li
                              class="list-group-item"
                              style={{ backgroundColor: "transparent" }}
                            >
                              <Link to={`/payment/${selectedData?.booking[0]}`}>
                                {" "}
                                click here to pay
                              </Link>
                            </li>
                          ) : null}
                        </ul>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </>

            <div className="main-details col-sm-12">
              <div className="details col-sm-12">
                <h4 className="text-capitalize">
                  Title: {selectedData?.title}
                  <h5>
                    <FaLocationArrow style={{ color: "#1d6aba" }} />
                    &nbsp; Location:&nbsp;
                    {selectedData?.address?.location}
                  </h5>
                </h4>

                <h4 className=" mt-4">Short Description</h4>
                <hr />
                <p
                  className="row"
                  style={{
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                    padding: "8px 25px",
                    color: "black",
                  }}
                >
                  {selectedData?.description}
                </p>
                <hr />
                <h4>Details</h4>
                <hr />
                <div
                  className="details-row row d-flex flex-wrap justify-content-between"
                  style={{
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                    padding: "8px",
                  }}
                >
                  <div className="col-lg-6">
                    <ul className="list-unstyled ">
                      <li style={{ textTransform: "capitalize" }}>
                        <FcDownRight /> Listing Type:{" "}
                        {selectedData?.typeOfListing}
                      </li>
                      <li style={{ textTransform: "capitalize" }}>
                        <FcDownRight /> Size: {selectedData?.size} &nbsp;
                        {selectedData?.unit}
                      </li>
                    </ul>
                  </div>

                  <div className="col-lg-6">
                    <ul className="list-unstyled ">
                      <li style={{ textTransform: "capitalize" }}>
                        <FcDownRight /> Storage Type:{" "}
                        {selectedData?.typeOfStorage}
                      </li>
                    </ul>
                  </div>
                </div>
                <hr />
                <h4>Pricing Details</h4>
                <hr />
                <div
                  className="price-details row d-flex flex-wrap justify-content-between"
                  style={{
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                    padding: "8px",
                  }}
                >
                  <div className="col-lg-6">
                    <ul className="list-unstyled">
                      <li>
                        {selectedData?.planType?.weeklyPlan === "available" ? (
                          <li>
                            <FcDownRight />
                            Plan Type: Weekly
                          </li>
                        ) : null}
                        {selectedData?.planType?.monthlyPlan === "available" ? (
                          <li>
                            <FcDownRight />
                            Plan Type: Monthly
                          </li>
                        ) : null}
                        {selectedData?.planType?.yearlyPlan === "available" ? (
                          <li>
                            <FcDownRight />
                            Plan Type: Yearly
                          </li>
                        ) : null}
                      </li>
                    </ul>
                  </div>

                  <div className="col-lg-6">
                    <ul className="list-unstyled ">
                      {selectedData?.pricing?.weekly ? (
                        <li>
                          <FcDownRight /> Weekly Price:{" "}
                          {selectedData?.pricing?.weekly}$
                        </li>
                      ) : null}
                      {selectedData?.pricing?.monthly ? (
                        <li>
                          <FcDownRight /> Monthly Price:{" "}
                          {selectedData?.pricing?.monthly}$
                        </li>
                      ) : null}
                      {selectedData?.pricing?.yearly ? (
                        <li>
                          <FcDownRight /> Yearly Price:{" "}
                          {selectedData?.pricing?.yearly}$
                        </li>
                      ) : null}
                    </ul>
                  </div>
                </div>
                {selectedData?.otherFacilities?.reception === "available" ||
                selectedData?.otherFacilities?.security === "available" ||
                selectedData?.otherFacilities?.airConditioning ===
                  "available" ? (
                  <>
                    <hr />
                    <h4>Amenities</h4>
                    <hr />
                    <div
                      className="facilities row d-flex flex-wrap justify-content-between"
                      style={{
                        borderRadius: "8px",
                        backgroundColor: "#fff",
                        padding: "12px 20px",
                      }}
                    >
                      <div className="col-lg-4 mb-3">
                        {selectedData?.otherFacilities?.reception ===
                        "available" ? (
                          <>
                            <FcDownRight /> Reception
                          </>
                        ) : null}
                      </div>
                      <div className="col-lg-4 mb-3">
                        {selectedData?.otherFacilities?.security ===
                        "available" ? (
                          <>
                            <FcDownRight /> Security
                          </>
                        ) : null}
                      </div>
                      <div className="col-lg-4 ">
                        {selectedData?.otherFacilities?.airConditioning ===
                        "available" ? (
                          <>
                            <FcDownRight /> Air Conditioning
                          </>
                        ) : null}
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </section>

          <section className="id">
            {/*  <div className="map" style={{ height: "400px" }}>
              <Autocomplete2 listingGrid={[selectedData]} />
             <MapContainer center={position} zoom={13} scrollWheelZoom={false}>
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={position}>
                  <Popup>
                    A pretty CSS3 popup. <br /> Easily customizable.
                  </Popup>
                </Marker>
              </MapContainer> 
            </div>*/}
          </section>
          <section id="host-details">
            <h5 style={{ color: "#1d6aba" }}>
              <b> Other Similar Properties </b>
            </h5>
            {/* <div style={{ display: "flex", flexWrap: "nowrap" }}> */}
            <div className="host-details">
              <div className="host-listing">
                <div className="d-flex justify-content-around flex-wrap">
                  {filteredData1.slice(0, 3).map((arg) => {
                    return (
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="card mt-3  ">
                          <img
                            alt=""
                            className="card-img-top"
                            src={
                              `${base_URL}/file/images/` + arg?.propertyImage[0]
                            }
                            style={{ objectFit: "cover" }}
                          />
                          <div className="card-body">
                            <div className="d-flex justify-content-around">
                              <ul className="list-unstyled text-center text-capitalize">
                                <a>
                                  <li
                                    className="font-weight-bold text-capitalize"
                                    style={{ color: "#1D6ABA" }}
                                  >
                                    {arg?.title}
                                  </li>
                                </a>
                                <li>{arg?.typeOfStorage}</li>
                              </ul>
                            </div>

                            <hr style={{ marginTop: "-8px" }} />
                            <div className="d-flex justify-content-between ">
                              <div className="price m-0">
                                <ul className="list-unstyled">
                                  <li>
                                    Price:{" "}
                                    <span style={{ color: "grey" }}>
                                      {arg?.pricing?.weekly ? (
                                        <>
                                          {arg?.pricing?.weekly}$
                                          <sub>per week</sub>
                                        </>
                                      ) : arg?.pricing?.monthly ? (
                                        <>
                                          {arg?.pricing?.monthly}$
                                          <sub>per month</sub>
                                        </>
                                      ) : (
                                        <>
                                          {arg?.pricing?.yearly}$
                                          <sub>per year</sub>
                                        </>
                                      )}
                                    </span>
                                  </li>
                                  <li className="font-weight-bold">
                                    Dimension:&nbsp;
                                    <span style={{ color: "grey" }}>
                                      {arg?.size}
                                      {arg?.unit}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div
                            className="detailsbtn  text-center mb-3 "
                            style={{ marginTop: "-25px" }}
                          >
                            <Link
                              to={{ pathname: `/list-details/${arg?._id}` }}
                            >
                              <button
                                className="ViewDetails"
                                style={{
                                  boxShadow: "none",
                                  width: "max-content",
                                }}
                              >
                                View Details
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </section>

          <Footer />
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  getAllProperty: state.getAllProperty.getAllProperty,
  ownerInfo: state.getOwnerInfo.getOwnerInfo,
  refresh: state.refresh.refresh.refresh,
  refreshFunction: state.refresh.refresh.refreshFunction,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ListingDetails);

/* <div className="top-img">
            <img src={About} width="700px" height="300px" />
          </div>
          <div className="image-grid">
            <div className="grid-item1">
              <img src={About} />
            </div>
            <div className="grid-item2">
              <img src={About} />
            </div>
            <div className="grid-item3">
              <img src={About} />
            </div>
            <div className="grid-item4">
              <img src={About} />
            </div>
          </div> */
{
  /*    <section id="reviews" className="mb-5">
            <div className="w-100 mb-3">
              <h4 style={{ color: "#1d6aba" }}>REVIEWS</h4>
              <div className="host-info mt-4">
                <div className="d-flex justify-content-left">
                  <img
                    src={PP}
                    style={{
                      width: "70px",
                      height: "70px",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />
                  <ul className="list-unstyled  ml-4">
                    <li
                      className="font-weight-bold"
                      style={{ color: "#1D6ABA" }}
                    >
                      <h5> Divash Ranabhat</h5>
                    </li>

                    <li>
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStarHalfAlt />
                    </li>
                  </ul>
                </div>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi
                culpa et dolor nisi maxime distinctio eos quas tenetur quam
                quasi inventore repellendus molestiae blanditiis incidunt
                voluptatum cupiditate aliquid, sint corporis.
              </div>
            </div>

          </section>*/
}
