import React from "react";
// import { Link, useParams } from "react-router-dom";
import { FiAlertTriangle } from "react-icons/fi";
import classNames from "classnames";
// import { Input, Tooltip } from "antd";
import notify from "../Notify/notify";
import { useForm } from "react-hook-form";
import { create } from "../Axios/axiosService";

const ForgotPassword = () => {
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data) => {
    create("api/auth/forgot-password", {
      email: data.email,
    })
      .then((res) => {
        notify.showSuccess("Email has been sent, check your E-mail");
      })
      .catch((err) => {
        if (err.response) {
          notify.handleError(err.response.data.msg + ", Try again");
          console.log(err);
        }
      });
  };

  return (
    <>
      <section id="reset">
        <div className="reset col-lg-6 col-md-12 col-sm-12 ml-auto mr-auto">
          <h3>Dear Renter, Forgot Password??</h3>
          <hr />
          Don't worry, We have an option. We will reset your
          <b style={{ color: "#1d6aba" }}> Password.</b>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group mt-3">
              <label htmlFor="recipient-name" className="col-form-label">
                Email Address
              </label>
              <input
                type="email"
                className={classNames("form-control", {
                  "is-invalid": errors.email,
                })}
                name="email"
                placeholder="Type your Email address"
                ref={register({
                  required: "Required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                })}
              />
              {errors.email ? (
                <p
                  style={{
                    color: "red",
                    marginBottom: "-10px",
                    fontSize: "12px",
                  }}
                >
                  <FiAlertTriangle /> {errors.email.message}
                </p>
              ) : null}
            </div>

            <button type="submit" className="signupbtnLogin w-100 mr-3 mt-3">
              Reset
            </button>
          </form>
        </div>
      </section>
    </>
  );
};

export default ForgotPassword;
