import React from "react";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import HostSidebar from "./HostSidebar";
import { connect } from "react-redux";

const MainDashboard = (props) => {
  //const [listingData, setListingData] = useState([]);
  //const [featured, setFeatured] = useState([]);

  const listingData = props?.ownerProperty;
  const featured = props?.ownerFeaturedProperty?.data;

  const reservationData = listingData?.filter((arg) => arg?.isBooked === true);

  return (
    <>
      <section id="main-dashboard">
        <div className="sidebar-row">
          <div className="sidebar-container">
            <HostSidebar />
          </div>

          <div className="dashboard-content">
            <div className="dashboard-title">
              <h5>Dashboard</h5>
            </div>

            <div className="dashboard-top row">
              <div className="col-sm-12 col-md-6 col-lg-4 animate__animated animate__fadeInLeft">
                <div className="tiles">
                  <ul className="list-unstyled ">
                    <li className="title">
                      Listings{" "}
                      <Link to="dashboard-listings">
                        {" "}
                        <button className="manage-link">Manage</button>
                      </Link>
                    </li>
                    <li className="count">
                      {" "}
                      <CountUp end={listingData?.length} duration={3} />
                    </li>
                    <li>0 New Listing</li>
                  </ul>
                </div>
              </div>
              <div className=" col-sm-12 col-md-6 col-lg-4 animate__animated animate__fadeInUp">
                <div className="tiles">
                  <ul className="list-unstyled ">
                    <li className="title">
                      Reservations{" "}
                      <Link to="/dashboard-reservation">
                        {" "}
                        <button className="manage-link">Manage</button>
                      </Link>{" "}
                    </li>
                    <li className="count">
                      {" "}
                      <CountUp end={reservationData?.length} duration={3} />
                    </li>
                    <li>0 New Reservations</li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-12  col-md-6 col-lg-4 animate__animated animate__fadeInRight">
                <div className="tiles">
                  <ul className="list-unstyled ">
                    <li className="title">
                      Featured
                      <Link to="/dashboard-featured">
                        {" "}
                        <button className="manage-link">Manage</button>
                      </Link>{" "}
                    </li>
                    <li className="count">
                      {" "}
                      <CountUp
                        end={reservationData?.length}
                        duration={3}
                      />{" "}
                    </li>
                    <li>0 New Featured</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="dashboard-second1 animate__animated animate__fadeInUp">
              <div className="dashboard-second-title d-flex flex-wrap justify-content-between">
                <h4>Featured Properties</h4>

                <Link to="/dashboard-featured">
                  View all <FaArrowAltCircleRight />{" "}
                </Link>
              </div>

              <div className="table-responsive">
                {featured?.length > 0 ? (
                  <table className="table table-striped table-hover text-center ">
                    <thead>
                      <tr>
                        <th scope="col">S.N.</th>
                        <th scope="col">Title</th>
                        <th scope="col">Storage Tpe</th>
                        <th scope="col">Listing Type</th>
                        <th scope="col">Plan Type</th>
                        <th scope="col">Price</th>
                      </tr>
                    </thead>
                    {featured?.slice(0, 4).map((item, index) => (
                      <tbody>
                        <tr>
                          <th scope="row">{index + 1}</th>
                          <td>
                            <Link
                              to={{
                                pathname: `/list-details/${item?._id}`,
                              }}
                            >
                              <b style={{ color: "#1D6ABA" }}>{item?.title}</b>
                            </Link>
                            <br />
                            <small> {item?.address?.location}</small>
                          </td>
                          <td style={{ textTransform: "capitalize" }}>
                            <small> {item?.typeOfStorage}</small>
                          </td>
                          <td style={{ textTransform: "capitalize" }}>
                            <small>{item?.typeOfListing}</small>{" "}
                          </td>
                          <td>
                            <ul className="list-unstyled">
                              <li
                                style={{
                                  color: "#1d6aba",
                                }}
                              >
                                <small>
                                  {item?.planType?.weeklyPlan === "available"
                                    ? "WeeklyPlan"
                                    : null}
                                </small>
                              </li>
                              <li
                                style={{
                                  color: "#009933",
                                }}
                              >
                                <small>
                                  {" "}
                                  {item?.planType?.monthlyPlan === "available"
                                    ? "MonthlyPlan"
                                    : null}
                                </small>
                              </li>
                              <li
                                style={{
                                  color: "#ee00ff",
                                }}
                              >
                                <small>
                                  {item?.planType?.yearlyPlan === "available"
                                    ? "YearlyPlan"
                                    : null}
                                </small>
                              </li>
                            </ul>
                          </td>
                          <td>
                            <ul className="list-unstyled ">
                              <li
                                style={{
                                  color: "#1d6aba",
                                }}
                              >
                                <small>
                                  {item?.planType?.weeklyPlan ===
                                  "available" ? (
                                    <>{item?.pricing?.weekly}$</>
                                  ) : null}
                                </small>
                              </li>
                              <li
                                style={{
                                  color: "#009933",
                                }}
                              >
                                <small>
                                  {item?.planType?.monthlyPlan ===
                                  "available" ? (
                                    <>{item?.pricing?.monthly}$</>
                                  ) : null}
                                </small>
                              </li>
                              <li
                                style={{
                                  color: "#ee00ff",
                                }}
                              >
                                <small>
                                  {item?.planType?.yearlyPlan ===
                                  "available" ? (
                                    <>{item?.pricing?.yearly}$</>
                                  ) : null}
                                </small>
                              </li>
                            </ul>
                          </td>
                          <td>
                            {/*<Tooltip title="View Details">
                                <Link
                                  to={{
                                    pathname: `/list-details/${item?._id}`,
                                  }}
                                >
                                  <button className="manage-button">
                                    {" "}
                                    <FaArrowAltCircleRight className="icon" />{" "}
                                  </button>
                                </Link>
                                </Tooltip>*/}
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </table>
                ) : (
                  <h4 className="text-center p-4">No Featured Properties.</h4>
                )}
              </div>
            </div>
            <div className="dashboard-third"></div>
            <div className="dashboard-fourth"></div>
          </div>
        </div>
        {/* Logout Modal body */}
      </section>
    </>
  );
};
const mapStateToProps = (state) => ({
  refresh: state.refresh.refresh.refresh,
  refreshFunction: state.refresh.refresh.refreshFunction,
  ownerProperty: state.ownerProperty.ownerProperty,
  ownerFeaturedProperty: state.ownerFeaturedProperty.ownerFeaturedProperty,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MainDashboard);
