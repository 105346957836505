import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useHistory } from "react-router-dom";
import NewMap from "../Map/NewMap";
import { update } from "../Axios/axiosService";
import notify from "../Notify/notify";
import RenterSidebar from "../RenterDashboard/RenterSidebar";
import Loader from "./../service/loader/loader";
import { connect } from "react-redux";
import HostSidebar from "./HostSidebar";
import { FiAlertTriangle } from "react-icons/fi";
import classNames from "classnames";
import { FaInfoCircle } from "react-icons/fa";

const EditListing = (props) => {
  const { id } = useParams();
  const [loader, setLoader] = useState(false);
  const [location, setLocation] = useState({});
  const [coordinate, setCoordinate] = useState({});
  const [weeklyUnitPrice, setWeeklyUnitPrice] = useState(0);
  const [monthlyUnitPrice, setMonthlyUnitPrice] = useState(0);
  const [yearlyUnitPrice, setYearlyUnitPrice] = useState(0);
  const [size, setSize] = useState(0);
  console.log(
    "🚀 ~ file: EditListing.jsx ~ line 22 ~ EditListing ~ size",
    size
  );
  const [unit, setUnit] = useState("");
  console.log(
    "🚀 ~ file: EditListing.jsx ~ line 24 ~ EditListing ~ unit",
    unit
  );
  const [weeklyClick, setWeeklyClick] = useState(false);

  const [monthlyClick, setMonthlyClick] = useState(false);
  const [yearlyClick, setYearlyClick] = useState(false);

  const locationCoordinate = (coordinate, location) => {
    setCoordinate(coordinate);
    setLocation(location);
  };
  const newAddress = {
    location: location,
    longitude: coordinate.lat,
    latitude: coordinate.lat,
  };

  const { register, handleSubmit, setValue, errors } = useForm({
    mode: "onTouched",
  });
  const OldData = props.ownerProperty.filter((arg) => arg._id === id)[0];

  useEffect(() => {
    setValue("title", OldData?.title);
    setValue("typeOfListing", OldData?.typeOfListing);
    setValue("typeOfStorage", OldData?.typeOfStorage);
    setValue("description", OldData?.description);
    setValue("size", OldData?.size);
    setValue("unit", OldData?.unit);
    setValue(
      "airConditioning",
      OldData?.otherFacilities?.airConditioning === "available" ? true : false
    );
    setValue(
      "reception",
      OldData?.otherFacilities?.reception === "available" ? true : false
    );
    setValue(
      "security",
      OldData?.otherFacilities?.security === "available" ? true : false
    );

    // setValue("location", OldData?.address?.location);
    setValue(
      "weeklyPlan",
      OldData?.planType?.weeklyPlan === "available"
        ? (true, setWeeklyClick(true))
        : (false, setWeeklyClick(false))
    );

    setValue(
      "monthlyPlan",
      OldData?.planType?.monthlyPlan === "available"
        ? (true, setMonthlyClick(true))
        : (false, setMonthlyClick(false))
    );
    setValue(
      "yearlyPlan",
      OldData?.planType?.yearlyPlan === "available"
        ? (true, setYearlyClick(true))
        : (false, setYearlyClick(false))
    );

    setValue("weekly", OldData?.pricing?.weekly);
    setValue("monthly", OldData?.pricing?.monthly);
    setValue("yearly", OldData?.pricing?.yearly);
    setSize(OldData?.size);
    // eslint-disable-next-line
  }, [props]);

  const history = useHistory();

  const onSubmit = (data) => {
    setLoader(true);
    const myData = {
      title: data.title,
      typeOfListing: data.typeOfListing,
      typeOfStorage: data.typeOfStorage,
      description: data.description,
      size: data.size,
      unit: data.unit,
      otherFacilities: {
        reception: data.reception === true ? "available" : "not available",
        security: data.security === true ? "available" : "not available",
        airConditioning:
          data.airConditioning === true ? "available" : "not available",
      },
      // address: newAddress?.location !== "" ? newAddress : OldData?.address,
      planType: {
        weeklyPlan: data.weeklyPlan === true ? "available" : "not available",
        monthlyPlan: data.monthlyPlan === true ? "available" : "not available",
        yearlyPlan: data.yearlyPlan === true ? "available" : "not available",
      },
      pricing: {
        weekly:
          weeklyClick === true
            ? weeklyUnitPrice * size === 0
              ? OldData?.pricing?.weekly
              : weeklyClick * size
            : OldData?.pricing?.weekly,
        monthly:
          monthlyClick === true
            ? monthlyUnitPrice * size === 0
              ? OldData?.pricing?.monthly
              : monthlyUnitPrice * size
            : OldData?.pricing?.monthly,
        yearly:
          yearlyClick === true
            ? yearlyUnitPrice * size === 0
              ? OldData?.pricing?.monthly
              : yearlyUnitPrice * size
            : OldData?.pricing?.yearly,
      },
      propertyImage:
        data.propertyImage.length !== 0
          ? data.propertyImage
          : OldData.propertyImage,
    };

    const jsonData = {
      title: data.title,
      typeOfListing: data.typeOfListing,
      typeOfStorage: data.typeOfStorage,
      description: data.description,
      size: data.size,
      unit: data.unit,
      otherFacilities: {
        reception: data.reception === true ? "available" : "not available",
        security: data.security === true ? "available" : "not available",
        airConditioning:
          data.airConditioning === true ? "available" : "not available",
      },
      // address: newAddress?.location !== "" ? newAddress : OldData?.address,
      planType: {
        weeklyPlan: data.weeklyPlan === true ? "available" : "not available",
        monthlyPlan: data.monthlyPlan === true ? "available" : "not available",
        yearlyPlan: data.yearlyPlan === true ? "available" : "not available",
      },
      pricing: {
        weekly:
          weeklyClick === true
            ? weeklyUnitPrice * size === 0
              ? OldData?.pricing?.weekly
              : weeklyUnitPrice * size
            : OldData?.pricing?.weekly,
        monthly:
          monthlyClick === true
            ? monthlyUnitPrice * size === 0
              ? OldData?.pricing?.monthly
              : monthlyUnitPrice * size
            : OldData?.pricing?.monthly,
        yearly:
          yearlyClick === true
            ? yearlyUnitPrice * size === 0
              ? OldData?.pricing?.monthly
              : yearlyUnitPrice * size
            : OldData?.pricing?.yearly,
      },
    };
    let finalData = new FormData();
    for (const key of Object.keys(myData)) {
      if (key === "propertyImage") {
        for (let i = 0; i < myData.propertyImage.length; i++) {
          finalData.append("propertyImage", myData.propertyImage[i]);
        }
      } else if (
        ["otherFacilities", "address", "planType", "pricing"].includes(key)
      ) {
        for (const innerkey of Object.keys(myData[key])) {
          finalData.append(`${key}[${innerkey}]`, myData[key][innerkey]);
        }
      } else {
        finalData.append(key, myData[key]);
      }
    }
    update(
      `api/property/${id}`,
      data?.propertyImage?.length === 0 ? jsonData : finalData
    )
      .then((res) => {
        // setEditDetail(res.data);
        if (res) {
          console.log(
            "🚀 ~ file: EditListing.jsx ~ line 160 ~ .then ~ res",
            res
          );
          notify.showSuccess("Your Listing is Updated successfully");
          //props.refreshFunction(6);
          // history.push("/dashboard-listings");
          setLoader(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          notify.handleError(" Try again");
          console.log(err);

          setLoader(false);
        }
      });
  };

  return (
    <>
      <section id="main-dashboard">
        <div className="sidebar-row">
          <div className="sidebar-container">
            <HostSidebar />
          </div>

          <div className="dashboard-content">
            <div className="dashboard-title">
              <h5> Dashboard | Edit Listing </h5>
            </div>
            <section id="add-listing-here">
              <div className="add-listing pt-3">
                <h5>Basic Details </h5>
                <hr className="mb-3" />
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row mb-4">
                    <div className="col">
                      <div className="form-outline">
                        <label className="form-label">Title</label>
                        <input
                          type="text"
                          name="title"
                          id="title"
                          className="form-control"
                          ref={register()}
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="typeOfListing">
                          Type of Lisiting?
                        </label>
                        <select
                          id="typeOfListing"
                          className="form-control"
                          name="typeOfListing"
                          ref={register}
                        >
                          <option value="">Type of Listing</option>
                          <option value="small room">Small Room</option>
                          <option value="small space">Small Space</option>
                          <option value="closet space">Closet Space</option>
                          <option value="large space">Large Space</option>
                          <option value="others">Others</option>
                        </select>
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-outline">
                        <label className="form-label storage-type">
                          Type of Storage?
                        </label>
                        <select
                          id="typeOfStorage"
                          name="typeOfStorage"
                          className="form-control"
                          ref={register}
                        >
                          <option value="">Type of Storage</option>
                          <option value="small personal items">
                            Small Personal Items
                          </option>
                          <option value="small household items">
                            Small Household Items
                          </option>
                          <option value="large household items">
                            Large Household Items
                          </option>
                          <option value="vehicles">Vehicles</option>
                          <option value="others">Others</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form-outline mb-4">
                    <label className="form-label">
                      A Short Description about your Listing.
                    </label>
                    <textarea
                      type="text"
                      name="description"
                      id="description"
                      className="form-control"
                      ref={register()}
                    />
                  </div>
                  <div className="row mb-4">
                    <div className="col">
                      <div className="form-outline row">
                        <div className="col">
                          <label className="form-label listing-type">
                            Size
                          </label>
                          <input
                            type="text"
                            onChange={(e) => setSize(e.target.value)}
                            name="size"
                            // value={this.state.size}
                            // onChange={this.handleInputChange}
                            className="form-control"
                            placeholder="Enter the size:"
                            ref={register()}
                          />
                        </div>
                        <div className="col">
                          <label className="form-label listing-type">
                            Unit
                          </label>

                          <select
                            id="listing-type"
                            name="unit"
                            onChange={(e) => setUnit(e.target.value)}
                            // value={this.state.unit}
                            // onChange={this.handleInputChange}
                            className="form-control"
                            ref={register()}
                          >
                            <option value="">Select Unit</option>
                            <option value="square inch">Square Inch</option>
                            <option value="square foot">Square Foot</option>
                            <option value="square meter">Square Meter</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="my-4">
                    <span
                      className="my-4 py-4"
                      style={{ fontSize: "14px", color: "#f2be2e" }}
                    >
                      {" "}
                      <FaInfoCircle className="bgstd" /> &nbsp; For week: “We
                      recommend S$1” &nbsp; || &nbsp; For month: “We recommend
                      S$3” &nbsp; || &nbsp; For Year: “We Recommend S$30{" "}
                    </span>
                  </div> */}
                  <div className="row mb-4">
                    <div className="col-lg-4 col-md-6 col-sm-12  ">
                      <label className="form-label listing-type">
                        Weekly Price Per Unit
                      </label>
                      <input
                        id="unitPrice"
                        name="unitPrice"
                        type="number"
                        placeholder="We
                        recommend S$1"
                        className={classNames("form-control", {
                          "is-invalid": errors.unitPrice,
                        })}
                        ref={register({
                          required: "Required Field",
                        })}
                        onChange={(e) => setWeeklyUnitPrice(e.target.value)}
                      ></input>
                      {errors.unitPrice ? (
                        <p style={{ color: "red", marginBottom: "-10px" }}>
                          <FiAlertTriangle /> {errors.unitPrice.message}
                        </p>
                      ) : null}
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12  ">
                      <label className="form-label listing-type">
                        Monthly Price Per Unit
                      </label>
                      <input
                        id="unitPrice"
                        name="unitPrice"
                        type="number"
                        placeholder="“We recommend
                        S$3 "
                        className={classNames("form-control", {
                          "is-invalid": errors.unitPrice,
                        })}
                        ref={register({
                          required: "Required Field",
                        })}
                        onChange={(e) => setMonthlyUnitPrice(e.target.value)}
                      ></input>
                      {errors.unitPrice ? (
                        <p style={{ color: "red", marginBottom: "-10px" }}>
                          <FiAlertTriangle /> {errors.unitPrice.message}
                        </p>
                      ) : null}
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12  ">
                      <label className="form-label listing-type">
                        Yearly Price Per Unit
                      </label>
                      <input
                        id="unitPrice"
                        name="unitPrice"
                        type="number"
                        placeholder="We Recommend S$30"
                        className={classNames("form-control", {
                          "is-invalid": errors.unitPrice,
                        })}
                        ref={register({
                          required: "Required Field",
                        })}
                        onChange={(e) => setYearlyUnitPrice(e.target.value)}
                      ></input>
                      {errors.unitPrice ? (
                        <p style={{ color: "red", marginBottom: "-10px" }}>
                          <FiAlertTriangle /> {errors.unitPrice.message}
                        </p>
                      ) : null}
                    </div>
                  </div>

                  <div className="form-outline mb-4">
                    <div className="form-outline mb-4">
                      <label htmlFor="">Upload Image</label>
                      <input
                        type="file"
                        name="propertyImage"
                        className="form-control"
                        id="customFile"
                        onChange={(e) => e.target.files}
                        multiple
                        ref={register()}
                      />
                      <label className="custom-file-label">
                        Choose more than One Images to Upload
                      </label>
                    </div>
                  </div>

                  <div className="col">
                    <label className="form-label">Amenities:</label> <br />
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="reception"
                        id="reception"
                        ref={register}
                      />
                      <label className="form-check-label">Reception</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        // checked={this.state.security}
                        // onChange={this.handleInputChange}
                        name="security"
                        id="security"
                        ref={register}
                      />
                      <label className="form-check-label">Security</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="airConditioning"
                        name="airConditioning"
                        ref={register}
                      />
                      <label className="form-check-label">
                        Air Conditioning
                      </label>
                    </div>
                  </div>

                  {/* <hr className="mt-5" />
                  <h5>Location Details</h5>
                  <hr className="mb-3" />
                  <div className="row mb-4">
                    <div className="col-sm">
                      <NewMap locationCoordinate={locationCoordinate} />
                    </div>
                  </div> */}

                  <hr className="mt-5" />
                  <h5>PLANS AND PRICING DETAILS</h5>
                  <hr className="mb-3" />
                  <div className="row form-check mb-5">
                    <label className="form-label">
                      Choose Payment Plan Type:
                    </label>{" "}
                    <br />
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="weeklyPlan"
                        id="weeklyPlan"
                        checked={weeklyClick === true ? true : false}
                        onClick={() => setWeeklyClick(!weeklyClick)}
                        ref={register}
                      />

                      <label
                        className="form-check-label"
                        htmlFor="inlineCheckbox1"
                      >
                        Weekly
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="monthlyPlan"
                        id="monthlyPlan"
                        checked={monthlyClick === true ? true : false}
                        onClick={() => setMonthlyClick(!monthlyClick)}
                        ref={register}
                      />
                      <label className="form-check-label">Monthly</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="yearlyPlan"
                        name="yearlyPlan"
                        checked={yearlyClick == true ? true : false}
                        onClick={() => setYearlyClick(!yearlyClick)}
                        ref={register({
                          // required:
                          //   weeklyClick === true ||
                          //   monthlyClick === true ||
                          //   yearlyClick === true
                          //     ? "req"
                          //     : false,
                        })}
                      />

                      <label className="form-check-label">Yearly</label>
                    </div>
                  </div>

                  <div className="row mb-5">
                    {weeklyClick === true ? (
                      <div className="col">
                        <div className="form-outline">
                          <label className="form-label">
                            Weekly {`($${weeklyUnitPrice} pre ${unit})`}
                          </label>
                          <input
                            type="text"
                            id="weekly"
                            name="weekly"
                            disabled
                            value={
                              weeklyUnitPrice * size === 0
                                ? OldData?.pricing?.weekly
                                : weeklyUnitPrice * size
                            }
                            className={classNames("form-control", {
                              "is-invalid": errors.weekly,
                            })}
                            placeholder="Enter Weekly Price."
                            ref={register({
                              required: "Required Field ",
                            })}
                          />
                          {errors.weekly ? (
                            <p style={{ color: "red", marginBottom: "-10px" }}>
                              <FiAlertTriangle /> {errors.weekly.message}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                    {monthlyClick === true ? (
                      <div className="col">
                        <div className="form-outline">
                          <label className="form-label">
                            Monthly {`($${monthlyUnitPrice} pre ${unit})`}
                          </label>
                          <input
                            type="text"
                            id="monthly"
                            name="monthly"
                            value={
                              monthlyUnitPrice * size === 0
                                ? OldData?.pricing?.monthly
                                : monthlyUnitPrice * size
                            }
                            disabled
                            className={classNames("form-control", {
                              "is-invalid": errors?.monthly,
                            })}
                            placeholder="Enter Monthly Price ."
                            ref={register({
                              required: "Required Field ",
                            })}
                          />
                          {errors.weekly ? (
                            <p style={{ color: "red", marginBottom: "-10px" }}>
                              <FiAlertTriangle /> {errors.monthly.message}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                    {yearlyClick === true ? (
                      <div className="col">
                        <div className="form-outline">
                          <label className="form-label">
                            Yearly {`($${yearlyUnitPrice} pre ${unit})`}
                          </label>
                          <input
                            type="text"
                            id="yearly"
                            name="yearly"
                            value={
                              yearlyUnitPrice * size === 0
                                ? OldData?.pricing?.yearly
                                : yearlyUnitPrice * size
                            }
                            disabled
                            className={classNames("form-control", {
                              "is-invalid": errors.yearly,
                            })}
                            placeholder="Enter Yearly Price."
                            ref={register({
                              required: "Required Field ",
                            })}
                          />
                          {errors.yearly ? (
                            <p style={{ color: "red", marginBottom: "-10px" }}>
                              <FiAlertTriangle /> {errors.yearly.message}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <button
                    type="submit"
                    // value="Submit"
                    className="signupbtnLogin btn-primary btn-block mb-4"
                  >
                    Update Listing
                  </button>
                </form>
              </div>
            </section>
          </div>
        </div>
        <Loader loader={loader} />
      </section>
    </>
  );
};

const mapStateToProps = (state) => ({
  refresh: state.refresh.refresh.refresh,
  refreshFunction: state.refresh.refresh.refreshFunction,
  ownerProperty: state.ownerProperty.ownerProperty,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EditListing);
