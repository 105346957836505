import axiosInstance from "./axiosBase";

export const read = (endpoint, token = null) => {
  if (token) {
    axiosInstance.defaults.headers["token"] = token;
  }
  return axiosInstance.get(endpoint);
};

export const create = (endpoint, data) => {
  return axiosInstance.post(endpoint, data);
};

export const update = (endpoint, data) => {
  return axiosInstance.put(endpoint, data);
};

export const deleteData = (endpoint) => {
  return axiosInstance.delete(endpoint);
};
