import React, { useState, useEffect } from "react";
import {getLocalStorage} from './Axios/tokenStorage';
import {token} from './Axios/baseURL';
import {adminToken} from "./Axios/baseURL"

import "antd/dist/antd.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "react-image-gallery/styles/css/image-gallery.css";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

// React Notification
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
import { ToastContainer } from "react-toastify";
import Home from "./Public/Home";
import About from "./Public/About";
import Signup from "./Public/Signup";
import Login from "./Public/Login";
import NewNav from "./Public/NewNav";
import NavAfter from "./Public/NavAfter";
import Footer from "./Public/Footer";
import Contact from "./Public/Contact";
import NotFound from "./Public/NotFound";
import VerifyRenter from "./Auth/VerifyRenter";
import VerifyHost from "./Auth/VerifyHost";
import SearchListing from "./Listing/SearchListing";
import ListingDetails from "./Listing/ListingDetails";
import MainDashboard from "./Dashboard/MainDashboard";
import MyListings from "./Dashboard/MyListings";
import Add from "./Dashboard/Add";
import AddBox from "./RenterDashboard/AddBox";
// import AddListing from "./Dashboard/AddListing";
import Reservation from "./Dashboard/Reservation";
import Featured from "./Dashboard/Featured";
import EditListing from "./Dashboard/EditListing";
import WeCanFindYou from "./Public/WeCanFindYou";
import Profile from "./Dashboard/Profile";
import EditProfile from "./Dashboard/EditProfile";
import HostLandingPage from "./Host/HostLandingPage";
import HostSignUp from "./Host/HostSignUp";
import HostReservation from "./Host/HostReservation";
import HostForgotPassword from "./Auth/HostForgotPassword";
import RenterForgotPassword from "./Auth/RenterForgotPassword";
import RenterResetPassword from "./Auth/RenterResetPassword";
import HostResetPassword from "./Auth/HostResetPassword";
import RenterMainDashboard from "./RenterDashboard/RenterMainDashboard";
import BoxQueryPayment from './RenterDashboard/boxQueryPayment';
import BoxQuery from './RenterDashboard/boxQuery';
import SpaceQuery from './RenterDashboard/spaceQuery';
import SpaceQueryPayment from './RenterDashboard/spaceQueryPayment';
import HostRequestDetail from './Admin/hostRequestDetail';
import BoxAcceptDeclinePage from './Dashboard/boxRequestAcceptDeclinePage';






import { Switch, Route, Redirect, BrowserRouter } from "react-router-dom";
import Payment from "./Payment/Payment";

//admin
import AdminLogin from "./Admin/AdminLogin";
import AdminPanel from "./Admin/AdminPanel";
import AdminSpaceBoxRequestDetail from './Admin/space-box-requestDetail';
// private router import

  import PrivateRouter from "./Router/PrivateRoute";
import RenterProfile from "./RenterDashboard/RenterProfile";
import RenterEditProfile from "./RenterDashboard/RenterEditProfile";
import RenterFavourites from "./RenterDashboard/RenterFavourites";
import RenterReservations from "./RenterDashboard/RenterReservations";
import RenterAvailableListings from "./RenterDashboard/RenterAvailableListings";
import RenterFeatured from "./RenterDashboard/RenterFeatured";

// const loginToken = getLocalStorage(token);
import FetchAllData from "./../src/service/fetchAllData";
//redux
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension";
//import logger from "redux-logger";
import { rootReducer } from "./redux/root-reducer/root-reducer";
import ScrollToTop from "./ScrollToTop";
import { useHistory } from "react-router-dom";
import Suspended from "./Public/Suspend";
import AdminRoute from "./Router/AdminRoute";


const App = () => {
  const [loginToken, setLoginToken] = useState(getLocalStorage(token));
  const history = useHistory();

  const handleLogin = () => {
    setLoginToken(getLocalStorage(token));
  };

  const admin_token = getLocalStorage(adminToken);

  const store = createStore(
    rootReducer,
   // composeWithDevTools(applyMiddleware(logger))
  );
  // useEffect(() => {
  //   if(getLocalStorage("role") === "admin"){
  //     history.push("/admin-panel")
  // }
  // else{
  //   history.push("/login")
  // }
  // }, [])

  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
        <ScrollToTop/>

         <> {loginToken ? <NavAfter token={loginToken} /> : <NewNav />}</>

          <Switch>
            {/* Admin Pages */}
            <Route exact path="/admin-login" component={AdminLogin}></Route>
            <Route exact path="/suspend" component={Suspended}></Route>
          
       
            <AdminRoute exact path="/admin-panel" component={AdminPanel}></AdminRoute>
            <AdminRoute exact path="/host-request-detail/:id" component={HostRequestDetail}></AdminRoute>
            <AdminRoute exact path="/request/:id" component={AdminSpaceBoxRequestDetail}></AdminRoute>


            {/* Public Pages */}
            <Route exact path="/" component={Home}></Route>
            <Route exact path="/about" component={About}></Route>
            <Route
              exact
              path="/search-location"
              component={SearchListing}
            ></Route>
            <Route
              exact
              path="/reset_password/:id"
              component={RenterResetPassword}
            ></Route>
            <Route
              exact
              path="/renter/forgot-password"
              component={RenterForgotPassword}
            ></Route>
            <Route
              exact
              path="/host/forgot-password"
              component={HostForgotPassword}
            ></Route>
            <Route
              exact
              path="/host/reset_password/:id"
              component={HostResetPassword}
            ></Route>

            <Route exact path="/contact" component={Contact}></Route>

            {/* Authentication Pages */}
            <Route
              exact
              path= "/login"
              component={() => <Login handleLogin={handleLogin} />}
            ></Route>
            <Route exact path="/signup" component={Signup}></Route>
            <Route exact path="/verify/:id" component={VerifyRenter}></Route>
            <Route exact path="/host/verify/:id" component={VerifyHost}></Route>
            <PrivateRouter exact path="/box-query-payment/:id" component={BoxQueryPayment}></PrivateRouter>
            <PrivateRouter exact path="/space-query-payment/:id" component={SpaceQueryPayment}></PrivateRouter>
            <PrivateRouter exact path="/box-query" component={BoxQuery}></PrivateRouter>
            <PrivateRouter exact path="/space-query" component={SpaceQuery}></PrivateRouter>



            {/* host pages */}
            <PrivateRouter
              exact
              path="/we-can-find-you"
              component={WeCanFindYou}
            ></PrivateRouter>
            <Route
              exact
              path="/host"
              component={HostLandingPage}
            ></Route>
            <Route exact path="/host/signup" component={HostSignUp}></Route>
            <PrivateRouter
              exact
              path="/reservation-request/:id"
              component={HostReservation}
            ></PrivateRouter>
            <PrivateRouter
              exact
              path="/payment/:id"
              component={Payment}
            ></PrivateRouter>

         <PrivateRouter
              exact
              path="/box-request"
              component={BoxAcceptDeclinePage}
          ></PrivateRouter>
            <PrivateRouter
              exact
              path="/dashboard-add-box"
              component={AddBox}
            ></PrivateRouter>
            <PrivateRouter
              exact
              path="/dashboard"
              component={MainDashboard}
            ></PrivateRouter>
            <PrivateRouter
              exact
              path="/dashboard-edit-listing/:id"
              component={EditListing}
            ></PrivateRouter>
            <PrivateRouter
              exact
              path="/dashboard-reservation"
              component={Reservation}
            ></PrivateRouter>
            <PrivateRouter
              exact
              path="/dashboard-add"
              component={Add}
            ></PrivateRouter>
            <PrivateRouter
              exact
              path="/dashboard-featured"
              component={Featured}
            ></PrivateRouter>
            <PrivateRouter
              exact
              path="/dashboard-profile"
              component={Profile}
            ></PrivateRouter>
            <PrivateRouter
              exact
              path="/dashboard-listings"
              component={MyListings}
            ></PrivateRouter>
            <PrivateRouter
              exact
              path="/dashboard-edit-profile/:id"
              component={EditProfile }
              // component={EditProfile}
            ></PrivateRouter>
            <PrivateRouter
              exact
              path="/list-details/:id"
              component={ListingDetails}
            ></PrivateRouter>

            {/* User pages */}
            <PrivateRouter
              exact
              path="/dashboard-renter"
              component={RenterMainDashboard}
            ></PrivateRouter>

            <PrivateRouter
              exact
              path="/dashboard-renter-favourites"
              component={RenterFavourites}
            ></PrivateRouter>
             <PrivateRouter
              exact
              path="/dashboard-renter-featured"
              component={RenterFeatured}
            ></PrivateRouter>
            <PrivateRouter
              exact
              path="/dashboard-renter-profile"
              component={RenterProfile}
            ></PrivateRouter>
            <PrivateRouter
              exact
              path="/dashboard-renter-listings"
              component={RenterAvailableListings}
            ></PrivateRouter>

            <PrivateRouter
              exact
              path="/dashboard-renter-reservations"
              component={RenterReservations}
            ></PrivateRouter>
            <PrivateRouter
              exact
              path="/dashboard-renter-edit-profile/:id"
              component={RenterEditProfile}
            ></PrivateRouter>
            {/* Test page */}
            {/* Not found page */}
            <Route component={NotFound} />

            <Redirect to="/" />
          </Switch>
        </BrowserRouter>
        <NotificationContainer />
        <ToastContainer />
      <FetchAllData />
      </Provider>
    </>
  );
};

export default App;
