import axios from "axios";
import { getLocalStorage } from "./tokenStorage";
import { token, base_URL, adminToken } from "./baseURL";

const headers = {
  headers: {
    "Content-Type": "application/json",
  },
};
if (localStorage.getItem(token)) {
  headers[token] = getLocalStorage(token);
}
if (localStorage.getItem(adminToken)) {
  headers[adminToken] = getLocalStorage(adminToken);
}
const axiosInstance = axios.create({
  baseURL: base_URL,
  headers,
});
console.log(getLocalStorage(adminToken));
console.log(getLocalStorage(token));

export default axiosInstance;
