import React, { useState } from "react";

import Ready from "../Public/Ready";
import { useForm } from "react-hook-form";
import { Redirect, useHistory } from "react-router-dom";
import { create } from "../Axios/axiosService";
import { getLocalStorage, setLocalStorage } from "../Axios/tokenStorage";
import { adminToken } from "../Axios/baseURL";
import Footer from "../Public/Footer";
import { connect } from "react-redux";
import { refreshPage } from "../Axios/tokenStorage";
import Loader from "./../service/loader/loader";

import notify from "../Notify/notify";

const Login = (props) => {
  const [loader, setLoader] = useState(false);
  const { register, handleSubmit } = useForm();
  const history = useHistory();
  const admin_token = getLocalStorage(adminToken);

  if (admin_token) {
    history.push("/admin-panel");
  }

  const onSubmit = (data) => {
    setLoader(true);
    create("/api/admin/admin-login", {
      email: data.email,
      password: data.password,
    })
      .then((res) => {
        setLocalStorage(adminToken, res.data.admin_token);
        setLocalStorage("role", "admin");
        notify.showSuccess("Welcome" + " " + data.email);
        history.push("/admin-panel");
        refreshPage(); // handleLogin();
        setLoader(false);
      })
      .catch((err) => {
        if (err.response) {
          notify.handleError(err.response.data.msg + ", Try again");
          setLoader(false);
        }
      });
  };

  return (
    <>
      <section id="login-page">
        <div className="container register">
          <div className="row justify-content-center mt-5">
            <div className="col-lg-5  register-right ">
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="renter"
                  role="tabpanel"
                  aria-labelledby="renter-tab"
                >
                  <h3 className="register-heading text-center">
                    Login as a Admin
                  </h3>

                  <div className="row register-form">
                    <div className="col-md-12 col-sm-12">
                      <form
                        className="form-group"
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <div className="mb-3 form-group">
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            id="email"
                            placeholder="Email"
                            ref={register({
                              required: "!!!! Required",
                            })}
                          />
                        </div>
                        <div className="mb-3 form-group">
                          <input
                            type="password"
                            className="form-control"
                            name="password"
                            id="password"
                            placeholder="Password"
                            ref={register({
                              required: "!!!! Required",
                            })}
                          />
                        </div>
                        {/* <div className="mb-3 form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="rememberMe"
                            name="rememberMe"
                            ref={register({
                              required: false,
                            })}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="remember-me"
                          >
                            Remember Me
                          </label>
                        </div> */}
                        <div className="d-flex  text-center flex-wrap flex-column flex-lg-row justify-content-between">
                          <button
                            type="submit"
                            className="signupbtnLogin mt-2"
                            style={{ width: "100%" }}
                          >
                            SIGN IN
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Loader loader={loader} />
      {/* <Ready /> */}
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({
  refresh: state.refresh.refresh.refresh,
  refreshFunction: state.refresh.refresh.refreshFunction,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
