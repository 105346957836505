import { RENTAL_SPACE_QUERY_REQUEST } from "../action/action";

const initialState = { rentalSpaceQueryRequest: [] };
export const rentalSpaceQueryRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case RENTAL_SPACE_QUERY_REQUEST:
      return { ...state, rentalSpaceQueryRequest: action.payload };

    default:
      return { ...state };
  }
};
