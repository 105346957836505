import { GET_OWNER_INFO } from "../action/action";

const initialState = { getOwnerInfo: [] };
export const getOwnerInfoReducer = (state = initialState, action) => {
  switch (action.type) {
  case GET_OWNER_INFO:
    return { ...state, getOwnerInfo: action.payload };

  default:
    return { ...state };
  }
};
