import React from "react";
import group1 from "../images/Group2.png";
import Footer from "./Footer";
import { useForm } from "react-hook-form";
import { create } from "../Axios/axiosService";
import notify from "../Notify/notify";
import { FiAlertTriangle } from "react-icons/fi";

const WeCanFindYou = () => {
  const { register, handleSubmit, errors, reset } = useForm();
  const onSubmit = (data) => {
    create("/api/user/findstozy", {
      location: data.location,
      storageType: data.storageType,
      listingType: data.listingType,
      planType: {
        weeklyPlan: data.weeklyPlan === true ? "weekly" : null,
        monthlyPlan: data.monthlyPlan === true ? "monthly" : null,
        yearlyPlan: data.yearlyPlan === true ? "yearly" : null,
      },
      storingItem: data.storingItem,
      storingDuration: data.storingDuration,
    })
      .then((res) => {
        notify.showSuccess(
          "Your query has been sent, wait for their response."
        );
        reset();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <section id="weCanFindYou">
        <div className="home-container mb-5">
          <div className="section2 col-lg-6 col-md-12 mb-5">
            <div className="text-center">
              <h3 style={{ color: "rgb(53, 49, 49)" }}>
                <h1> You are Welcome Here.</h1>
                Don't worry we will find you a Perfect Stozy Location.
              </h3>
              <img alt="" src={group1} className="image1 img-fluid" />
            </div>
          </div>
          <div className="sectionNew1 col-lg-6 col-md-12">
            <h3 style={{ color: "rgb(53, 49, 49)" }}>
              {" "}
              Please let us Know, What do you want.
            </h3>
            <h4>Fill out the following Queries.</h4>
            <form className="mt-3" onSubmit={handleSubmit(onSubmit)}>
              <div className="form-outline mb-4">
                <input
                  type="text"
                  className="form-control"
                  name="location"
                  placeholder="Location of Interest"
                  ref={register({
                    required: "Required Field ",
                  })}
                />
                {errors.location ? (
                  <p style={{ color: "red", marginBottom: "-10px" }}>
                    <FiAlertTriangle /> {errors.location.message}
                  </p>
                ) : null}
              </div>

              <div className="row mb-4">
                <div className="col">
                  <div className="form-outline">
                    <select
                      className="form-control"
                      name="listingType"
                      ref={register({
                        required: "Required Field ",
                      })}
                    >
                      <option value="">Type of Listing</option>
                      <option value="small room">Small Room</option>
                      <option value="small space">Small Space</option>
                      <option value="closet space">Closet Space</option>
                      <option value="large space">Large Space</option>
                      <option value="others">Others</option>
                    </select>
                    {errors.listingType ? (
                      <p style={{ color: "red", marginBottom: "-10px" }}>
                        <FiAlertTriangle /> {errors.listingType.message}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="col">
                  <div className="form-outline">
                    <select
                      name="storageType"
                      className="form-control"
                      ref={register({
                        required: true,
                      })}
                    >
                     <option value="">Type of Storage</option>
                          <option value="small personal items">
                            Small Personal Items
                          </option>
                          <option value="small household items">
                            Small Household Items
                          </option>
                          <option value="large household items">
                            Large Household Items
                          </option>
                          <option value="vehicles">Vehicles</option>
                          <option value="others">Others</option>
                    </select>
                    {errors.storageType ? (
                      <p style={{ color: "red", marginBottom: "-10px" }}>
                        <FiAlertTriangle /> {errors.storageType.message}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div
                className="row form-check mb-4"
                style={{ marginLeft: "-15px" }}
              >
                <label className="form-label">Choose Plan Type:</label> <br />
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="weeklyPlan"
                    id="weeklyPlan"
                    ref={register}
                  />
                  <label className="form-check-label" htmlFor="inlineCheckbox1">
                    Weekly
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="monthlyPlan"
                    id="monthlyPlan"
                    ref={register}
                  />
                  <label className="form-check-label">Monthly</label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="yearlyPlan"
                    name="yearlyPlan"
                    ref={register}
                  />
                  <label className="form-check-label">Yearly</label>
                </div>
              </div>
              <div className="form-outline mb-4">
                <input
                  type="text"
                  className="form-control"
                  name="storingItem"
                  placeholder="What do you want to Store?"
                  ref={register({
                    required: true,
                  })}
                />
              </div>
              <div className="form-outline mb-4">
                <select
                  name="storingDuration"
                  className="form-control"
                  ref={register({
                    required: true,
                  })}
                >
                  <option selected>How Long do you Plan for Storing?</option>
                  <option value="a week">A Week</option>
                  <option value="several weeks">Several Weeks</option>
                  <option value="a month">A Month</option>
                  <option value="several months">Several Months</option>
                  <option value="a year">A year</option>
                  <option value="several year">Several Year</option>
                </select>
                {errors.storingDuration ? (
                  <p style={{ color: "red", marginBottom: "-10px" }}>
                    <FiAlertTriangle /> {errors.storingDuration.message}
                  </p>
                ) : null}
              </div>

              <button
                type="submit"
                className="signupbtnLogin"
                style={{ width: "100%" }}
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
export default WeCanFindYou;
