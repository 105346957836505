import React from "react";
import { Link } from "react-router-dom";
import { role, token } from "./../Axios/baseURL";
import { getLocalStorage } from "./../Axios/tokenStorage";

const Ready = () => {
  return (
    <>
      <section id="home-page5">
        <div className="container">
          <div className="row main-row ">
            <div className="col-lg-12 col-sm-12 text-center">
              <h1>READY TO JOIN OUR COMPANY</h1>
              <h2 className="mt-4" style={{ textDecoration: "capitalize" }}>
                Speedily list and Search Nearby Parking Units.
              </h2>
              <div className="wrapper mt-5">
                <Link to="/search-location" className="signupbtnLogin1">
                  <span>FIND SPACE</span>
                </Link>
              </div>
              {getLocalStorage(token) ? (
                <div className="wrapper">
                  {getLocalStorage(role) === "owner" ? (
                    <Link to="/dashboard-add" className="signupbtnLoginAlt">
                      <span>LIST YOUR SPACE</span>
                    </Link>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Ready;
