import React, { useState } from "react";
import { Input, Tooltip } from "antd";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import { Link } from "react-router-dom";
import { create } from "../Axios/axiosService";
import notify from "../Notify/notify";
import $ from "jquery";
import PaginationFunction from "../Pagination/Pagination";
import RenterSidebar from "./RenterSidebar";
import SyncLoader from "react-spinners/SyncLoader";
import { connect } from "react-redux";
import Loader from "./../service/loader/loader";

const MyListings = (props) => {
  const [loading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(parseInt("1"));
  const [postPerPage] = useState(parseInt("6"));
  const listingData = props?.getAllProperty?.filter(
    (arg) => arg?.isVerified === true
  );

  const addToFavourites = (id) => {
    setLoader(true);
    create("api/favourites/", {
      property: id,
    })
      .then((res) => {
        $(`#favouriteListing-${id}`).modal("hide");
        // notify.showSuccess(res.data.statusText);
        notify.showSuccess(res.data.msg);
        props.refreshFunction(1);
        setLoader(false);
      })
      .catch((err) => {
        notify.handleError(err.response.data.msg);
        setLoader(false);
      });
  };

  const availableListing = listingData.filter(
    (arg) => arg.booking_status === "available"
  );
  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const currentListing = availableListing.slice(
    indexOfFirstPost,
    indexOfLastPost
  );

  const paginateFunction = (pageNo) => {
    setCurrentPage(pageNo);
  };

  const { Search } = Input;
  const [searchterm, setSearchTerm] = useState("");
  const filterData = currentListing?.filter((item) =>
    searchterm === ""
      ? item
      : item.title.toLowerCase().includes(searchterm.toLowerCase()) ||
        item.typeOfListing.toLowerCase().includes(searchterm.toLowerCase()) ||
        item.typeOfStorage.toLowerCase().includes(searchterm.toLowerCase())
  );

  return (
    <>
      <section id="main-dashboard">
        <div className="sidebar-row">
          <div className="sidebar-container">
            <RenterSidebar />
          </div>
          <div className="dashboard-content">
            <div className="dashboard-title">
              <h5>Dashboard | Listings</h5>
            </div>
            {loading ? (
              <>
                {" "}
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "80vh", top: "50%" }}
                >
                  <SyncLoader size={18} color="#1d6aba" margin="2" />
                </div>
              </>
            ) : (
              <div className="dashboard-second  animate__animated animate__fadeInUp">
                <div className="topbar d-flex justify-content-between flex-wrap mt-4 mb-4">
                  <h5 className="pl-3 mb-4 col-lg-6 col-md-12">
                    Available Listings
                  </h5>
                  <div className="search-bar col-lg-6 col-md-12">
                    <Search
                      placeholder="Search Listing"
                      allowClear
                      enterButton
                      size="large"
                      // onSearch={onSearch}
                      onChange={(e) => {
                        setSearchTerm(e.target.value);
                      }}
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div className="mylistings table-responsive">
                  {filterData?.length !== 0 ? (
                    <>
                      <table className="table  table-hover text-center table-striped text-capitalize">
                        <thead>
                          <tr>
                            <th scope="col">S.N.</th>
                            <th scope="col">Title</th>
                            <th scope="col">Storage Tpe</th>
                            <th scope="col">Listing Type</th>
                            <th scope="col">Plan Type</th>
                            <th scope="col"> &nbsp;Price &nbsp;&nbsp; </th>
                            <th scope="col">Status</th>
                            <th scope="col">Actions</th>
                          </tr>
                        </thead>

                        {filterData?.map((item, index) => (
                          <>
                            <tbody key={index}>
                              <tr>
                                <th scope="row">
                                  {indexOfFirstPost + index + 1}
                                </th>
                                <td>
                                  <Link
                                    to={{
                                      pathname: `/list-details/${item._id}`,
                                    }}
                                  >
                                    <b style={{ color: "#1D6ABA" }}>
                                      {item.title}
                                    </b>
                                  </Link>
                                  <br />
                                  <small> {item.address.location}</small>
                                </td>
                                <td style={{ textTransform: "capitalize" }}>
                                  <small> {item.typeOfStorage}</small>
                                </td>
                                <td style={{ textTransform: "capitalize" }}>
                                  <small>{item.typeOfListing}</small>{" "}
                                </td>
                                <td>
                                  <ul className="list-unstyled">
                                    <li
                                      style={{
                                        color: "#1d6aba",
                                      }}
                                    >
                                      <small>
                                        {item.planType.weeklyPlan ===
                                        "available"
                                          ? "WeeklyPlan"
                                          : null}
                                      </small>
                                    </li>
                                    <li
                                      style={{
                                        color: "#009933",
                                      }}
                                    >
                                      <small>
                                        {" "}
                                        {item.planType.monthlyPlan ===
                                        "available"
                                          ? "MonthlyPlan"
                                          : null}
                                      </small>
                                    </li>
                                    <li
                                      style={{
                                        color: "#ee00ff",
                                      }}
                                    >
                                      <small>
                                        {item.planType.yearlyPlan ===
                                        "available"
                                          ? "YearlyPlan"
                                          : null}
                                      </small>
                                    </li>
                                  </ul>
                                </td>
                                <td>
                                  <ul className="list-unstyled ">
                                    <li
                                      style={{
                                        color: "#1d6aba",
                                      }}
                                    >
                                      <small>
                                        {item.planType.weeklyPlan ===
                                        "available" ? (
                                          <>{item.pricing.weekly}$</>
                                        ) : null}
                                      </small>
                                    </li>
                                    <li
                                      style={{
                                        color: "#009933",
                                      }}
                                    >
                                      <small>
                                        {item.planType.monthlyPlan ===
                                        "available" ? (
                                          <>{item.pricing.monthly}$</>
                                        ) : null}
                                      </small>
                                    </li>
                                    <li
                                      style={{
                                        color: "#ee00ff",
                                      }}
                                    >
                                      <small>
                                        {item.planType.yearlyPlan ===
                                        "available" ? (
                                          <>{item.pricing.yearly}$</>
                                        ) : null}
                                      </small>
                                    </li>
                                  </ul>
                                </td>
                                <td>
                                  {item.booking_status === "available" ? (
                                    <h5 className="new">Available</h5>
                                  ) : (
                                    <h5 className="accepted">Booked</h5>
                                  )}
                                </td>
                                <td>
                                  {props?.renterFavouritesProperty?.some(
                                    (arg) => arg?.property?._id === item._id
                                  ) === true ? (
                                    <Tooltip title="Already added to Favourites">
                                      <button className="manage-button1">
                                        {" "}
                                        <FaHeart className="icon" />{" "}
                                      </button>
                                    </Tooltip>
                                  ) : (
                                    <Tooltip title="Add to Favourites">
                                      <button
                                        // style={
                                        //   props?.renterFavouritesProperty?.some(
                                        //     (arg) => arg?.property?._id === item._id
                                        //   ) === true
                                        //     ? { backgroundColor: "red" }
                                        //     : { backgroundColor: "green" }
                                        // }
                                        className="manage-button1"
                                        // data-target={`#favouriteListing-${item._id}`}
                                        // data-toggle="modal"
                                        onClick={() => {
                                          addToFavourites(item._id);
                                        }}
                                      >
                                        {" "}
                                        <FaRegHeart />
                                      </button>
                                    </Tooltip>
                                  )}

                                  {/* <Tooltip title="View">
                                <Link
                                  to={{
                                    pathname: `/list-details/${item._id}`,
                                  }}
                                >
                                  <button className="manage-button1">
                                    {" "}
                                    <FaArrowAltCircleRight className="icon" />{" "}
                                  </button>
                                </Link>
                                </Tooltip>*/}
                                </td>
                              </tr>
                            </tbody>
                            <div
                              className="modal fade"
                              id={`favouriteListing-${item._id}`}
                              tabindex="-1"
                              role="dialog"
                              aria-labelledby="favouriteListing"
                              aria-hidden="true"
                            >
                              <div
                                className="modal-dialog modal-dialog-centered"
                                role="document"
                              >
                                <div className="modal-content">
                                  <div className="modal-header text-center ">
                                    <h5 className="modal-title ml-auto">
                                      Do you want to make this your Favourite
                                      Listing?
                                    </h5>
                                    <button
                                      type="button"
                                      className="close"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                  <div className="modal-footer  justify-content-center">
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      onClick={() => {
                                        addToFavourites(item._id);
                                      }}
                                    >
                                      Yes
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-secondary"
                                      data-dismiss="modal"
                                    >
                                      No
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                      </table>

                      <PaginationFunction
                        postPerPage={postPerPage}
                        totalPost={availableListing.length}
                        paginate={paginateFunction}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                      />
                    </>
                  ) : (
                    <h4 className="text-center p-4">No Results</h4>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* faavourite action modal */}
        <Loader loader={loader} />
      </section>
    </>
  );
};
const mapStateToProps = (state) => ({
  getAllProperty: state.getAllProperty.getAllProperty,
  renterFavouritesProperty:
    state.renterFavouritesProperty.renterFavouritesProperty,
  refresh: state.refresh.refresh.refresh,
  refreshFunction: state.refresh.refresh.refreshFunction,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MyListings);
