import { ADMIN_ALL_BOX_QUERY_REQUEST } from "../action/action";

const initialState = { allBoxRequest: [] };
export const allBoxRequestReducer = (state = initialState, action) => {
  switch (action.type) {
  case ADMIN_ALL_BOX_QUERY_REQUEST:
    return { ...state, allBoxRequest: action.payload };

  default:
    return { ...state };
  }
};
