import React, { useState } from "react";
import { FaArrowAltCircleRight } from "react-icons/fa";

import CountUp from "react-countup";
import SyncLoader from "react-spinners/SyncLoader";

import { Tooltip } from "antd";
import { Link } from "react-router-dom";
// import { MdDeleteForever } from "react-icons/md";
import RenterSidebar from "./RenterSidebar";
import { connect } from "react-redux";

const RenterMainDashboard = (props) => {
  const [loading] = useState(false);
  const data = props?.renterBookedProperty?.slice()?.reverse();
  const favdata = props?.renterFavouritesProperty?.slice()?.reverse();
  const featuredData = props.allFeaturedProperty.property;

  const filterFeaturedData = featuredData?.filter(
    (args) => args.booking_status === "available"
  );
  return (
    <>
      <section id="main-dashboard">
        <div className="sidebar-row">
          <div className="sidebar-container">
            <RenterSidebar />
          </div>

          <div className="dashboard-content">
            <div className="dashboard-title">
              <h5>Renter Dashboard</h5>
            </div>
            {loading ? (
              <>
                {" "}
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "80vh", top: "50%", opacity: "0.5" }}
                >
                  <SyncLoader size={18} color="#1d6aba" margin="2" />
                </div>
              </>
            ) : (
              <>
                <div className="row dashboard-top  d-flex justify-content-between flex-wrap">
                  <div className="col-sm-12 col-md-6 col-lg-4 animate__animated animate__fadeInLeft">
                    <div className="tiles">
                      <ul className="list-unstyled ">
                        <li className="title">
                          Your Bookings{" "}
                          <Link to="/dashboard-renter-reservations">
                            <button className="manage-link">Manage</button>
                          </Link>
                        </li>
                        <li className="count">
                          <CountUp end={data.length} duration={2}></CountUp>
                        </li>
                        <li>{data.length} New Bookings</li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4 animate__animated animate__fadeInUp">
                    <div className="tiles">
                      <ul className="list-unstyled ">
                        <li className="title">
                          Favourites
                          <Link to="/dashboard-renter-favourites">
                            <button className="manage-link">Manage</button>
                          </Link>
                        </li>
                        <li className="count">
                          <CountUp end={favdata.length} duration={2} />
                        </li>
                        <li>{favdata.length} New Favourites</li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4 animate__animated animate__fadeInDown">
                    <div className="tiles">
                      <ul className="list-unstyled ">
                        <li className="title">
                          Invoices{" "}
                          <button className="manage-link">Manage</button>{" "}
                        </li>
                        <li className="count">
                          {" "}
                          <CountUp end={0} duration={2} />$
                        </li>
                        <li>Used till now.</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="renter-dashboard-second animate__animated animate__fadeInUp">
                  <div className="dashboard-second-title d-flex flex-wrap justify-content-between">
                    <h4>Top Featured Listings</h4>
                    <Link to="/dashboard-renter-featured">
                      View all <FaArrowAltCircleRight />{" "}
                    </Link>
                  </div>
                  <div className="table-responsive">
                    {featuredData?.length !== 0 ? (
                      <table className="table table-striped table-hover  text-center text-capitalize">
                        <thead>
                          <tr>
                            <th scope="col">S.N.</th>
                            <th scope="col">Title</th>
                            <th scope="col">Storage Tpe</th>
                            <th scope="col">Listing Type</th>
                            <th scope="col">Plan Type</th>
                            <th scope="col">Price</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        {/*top five only shown here*/}
                        {filterFeaturedData?.slice(0, 5).map((item, index) => (
                          <>
                            <tbody>
                              <tr>
                                <th scope="row">{index + 1}</th>
                                <td>
                                  <Link
                                    to={{
                                      pathname: `/list-details/${item?._id}`,
                                    }}
                                  >
                                    <b style={{ color: "#1D6ABA" }}>
                                      {item?.title}
                                    </b>
                                  </Link>
                                  <br />
                                  <small> {item?.address?.location}</small>
                                </td>
                                <td style={{ textTransform: "capitalize" }}>
                                  <small> {item?.typeOfStorage}</small>
                                </td>
                                <td style={{ textTransform: "capitalize" }}>
                                  <small>{item?.typeOfListing}</small>{" "}
                                </td>
                                <td>
                                  <ul className="list-unstyled">
                                    <li
                                      style={{
                                        color: "#1d6aba",
                                      }}
                                    >
                                      <small>
                                        {item?.planType?.weeklyPlan ===
                                        "available"
                                          ? "WeeklyPlan"
                                          : null}
                                      </small>
                                    </li>
                                    <li
                                      style={{
                                        color: "#009933",
                                      }}
                                    >
                                      <small>
                                        {" "}
                                        {item?.planType?.monthlyPlan ===
                                        "available"
                                          ? "MonthlyPlan"
                                          : null}
                                      </small>
                                    </li>
                                    <li
                                      style={{
                                        color: "#ee00ff",
                                      }}
                                    >
                                      <small>
                                        {item?.planType?.yearlyPlan ===
                                        "available"
                                          ? "YearlyPlan"
                                          : null}
                                      </small>
                                    </li>
                                  </ul>
                                </td>
                                <td>
                                  <ul className="list-unstyled ">
                                    <li
                                      style={{
                                        color: "#1d6aba",
                                      }}
                                    >
                                      <small>
                                        {item?.planType?.weeklyPlan ===
                                        "available" ? (
                                          <>{item?.pricing?.weekly}$</>
                                        ) : null}
                                      </small>
                                    </li>
                                    <li
                                      style={{
                                        color: "#009933",
                                      }}
                                    >
                                      <small>
                                        {item?.planType?.monthlyPlan ===
                                        "available" ? (
                                          <>{item?.pricing?.monthly}$</>
                                        ) : null}
                                      </small>
                                    </li>
                                    <li
                                      style={{
                                        color: "#ee00ff",
                                      }}
                                    >
                                      <small>
                                        {item?.planType?.yearlyPlan ===
                                        "available" ? (
                                          <>{item?.pricing?.yearly}$</>
                                        ) : null}
                                      </small>
                                    </li>
                                  </ul>
                                </td>
                                <td>
                                  {" "}
                                  <Tooltip title="View Details">
                                    <Link
                                      to={{
                                        pathname: `/list-details/${item?._id}`,
                                      }}
                                    >
                                      <button className="manage-button">
                                        {" "}
                                        <FaArrowAltCircleRight className="icon" />{" "}
                                      </button>
                                    </Link>
                                  </Tooltip>
                                </td>
                              </tr>
                            </tbody>
                          </>
                        ))}
                      </table>
                    ) : (
                      <div>
                        {" "}
                        <h3 className="text-center p-4">
                          {" "}
                          No Featured Listings till now,
                        </h3>
                      </div>
                    )}
                  </div>
                </div>

                <div className="dashboard-renter-second">
                  <div className="dashboard-second-title d-flex flex-wrap justify-content-between">
                    <h4>Your Favourite Listings</h4>
                    <Link to="/dashboard-renter-favourites">
                      View all <FaArrowAltCircleRight />{" "}
                    </Link>
                  </div>

                  <div className="table-responsive">
                    {favdata?.length !== 0 ? (
                      <table className="table  table-hover table-striped text-center text-capitalize">
                        <thead>
                          <tr>
                            <th scope="col">S.N.</th>
                            <th scope="col">Title</th>
                            <th scope="col">Storage Tpe</th>
                            <th scope="col">Listing Type</th>
                            <th scope="col">Plan Type</th>
                            <th scope="col">Price</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        {favdata?.slice(0, 4).map((item, index) => (
                          <tbody>
                            <tr>
                              <th scope="row">{index + 1}</th>
                              <td>
                                <Link
                                  to={{
                                    pathname: `/list-details/${item?.property?._id}`,
                                  }}
                                >
                                  <b style={{ color: "#1D6ABA" }}>
                                    {item?.property?.title}
                                  </b>
                                </Link>
                                <br />
                                <small>
                                  {" "}
                                  {item?.property?.address?.location}
                                </small>
                              </td>
                              <td style={{ textTransform: "capitalize" }}>
                                <small> {item?.property?.typeOfStorage}</small>
                              </td>
                              <td style={{ textTransform: "capitalize" }}>
                                <small>{item?.property?.typeOfListing}</small>{" "}
                              </td>
                              <td>
                                <ul className="list-unstyled">
                                  <li
                                    style={{
                                      color: "#1d6aba",
                                    }}
                                  >
                                    <small>
                                      {item?.property?.planType?.weeklyPlan ===
                                      "available"
                                        ? "WeeklyPlan"
                                        : null}
                                    </small>
                                  </li>
                                  <li
                                    style={{
                                      color: "#009933",
                                    }}
                                  >
                                    <small>
                                      {" "}
                                      {item?.property?.planType?.monthlyPlan ===
                                      "available"
                                        ? "MonthlyPlan"
                                        : null}
                                    </small>
                                  </li>
                                  <li
                                    style={{
                                      color: "#ee00ff",
                                    }}
                                  >
                                    <small>
                                      {item?.property?.planType?.yearlyPlan ===
                                      "available"
                                        ? "YearlyPlan"
                                        : null}
                                    </small>
                                  </li>
                                </ul>
                              </td>
                              <td>
                                <ul className="list-unstyled ">
                                  <li
                                    style={{
                                      color: "#1d6aba",
                                    }}
                                  >
                                    <small>
                                      {item?.property?.planType?.weeklyPlan ===
                                      "available" ? (
                                        <>{item?.property?.pricing?.weekly}$</>
                                      ) : null}
                                    </small>
                                  </li>
                                  <li
                                    style={{
                                      color: "#009933",
                                    }}
                                  >
                                    <small>
                                      {item?.property?.planType?.monthlyPlan ===
                                      "available" ? (
                                        <>{item?.property?.pricing?.monthly}$</>
                                      ) : null}
                                    </small>
                                  </li>
                                  <li
                                    style={{
                                      color: "#ee00ff",
                                    }}
                                  >
                                    <small>
                                      {item?.property?.planType?.yearlyPlan ===
                                      "available" ? (
                                        <>{item?.property?.pricing?.yearly}$</>
                                      ) : null}
                                    </small>
                                  </li>
                                </ul>
                              </td>
                              <td>
                                {" "}
                                <Tooltip title="View Details">
                                  <Link
                                    to={{
                                      pathname: `/list-details/${item?.property?._id}`,
                                    }}
                                  >
                                    <button className="manage-button">
                                      {" "}
                                      <FaArrowAltCircleRight className="icon" />{" "}
                                    </button>
                                  </Link>
                                </Tooltip>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                    ) : (
                      <h3 className="text-center p-4">
                        No Favourite Listings Listings till now,
                      </h3>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
};
const mapStateToProps = (state) => ({
  renterBookedProperty: state.renterBookedProperty.renterBookedProperty,
  renterFavouritesProperty:
    state.renterFavouritesProperty.renterFavouritesProperty,
  allFeaturedProperty: state.allFeaturedProperty.allFeaturedProperty,
  refresh: state.refresh.refresh.refresh,
  refreshFunction: state.refresh.refresh.refreshFunction,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RenterMainDashboard);
