import { OWNER_PROPERTY } from "../action/action";

const initialState = { ownerProperty: [] };
export const ownerPropertyReducer = (state = initialState, action) => {
  switch (action.type) {
  case OWNER_PROPERTY:
    return { ...state, ownerProperty: action.payload };

  default:
    return { ...state };
  }
};
