import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { FiAlertTriangle } from "react-icons/fi";
import classNames from "classnames";
import NewMap from "../Map/NewMap";
import notify from "../Notify/notify";
import { useHistory } from "react-router-dom";
import RenterSidebar from "./RenterSidebar";
import { connect } from "react-redux";
import Loader from "../service/loader/loader";
import SyncLoader from "react-spinners/SyncLoader";
import { create } from "../Axios/axiosService";

/*const getDimension = async (file) => {
  let reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    reader.onload = () => {
      var image = new Image();
      image.src = reader.result;
      image.onload = function () {
        resolve({ width: this.width, height: this.height });
      };
    };
    reader.readAsDataURL(file);
  });
};*/

// const token = localStorage.getItem("token") || sessionStorage.getItem("token");
// console.log("login token is " + token);

const AddBox = (props) => {
  const [loader, setLoader] = useState(false);
  const [loading] = useState(true);
  const [price, setPrice] = useState(0);
  const [size, setSize] = useState(0);

  const history = useHistory();
  const [storagePlan, setStoragePlan] = useState("");

  const [location, setLocation] = useState({});
  const [coordinate, setCoordinate] = useState({});

  const locationCoordinate = (coordinate, location) => {
    setCoordinate(coordinate);
    setLocation(location);
  };
  const { register, handleSubmit, errors } = useForm({
    mode: "onTouched",
  });

  const onSubmit = (data) => {
    setLoader(true);

    const myData = {
      box_size: data.box_size,
      start_date: data.start_date,
      pickup_time: data.pickup_time,
      plan_type: data.plan_type,
      storage_plan: data.storage_plan,
      message: data.message,
      location_of_interest: location,
      isFragile: data.isFragile,
      storing_item: data.storing_item,
      price: size * price,
    };
    console.log(myData);

    create("api/box/boxquery", myData)
      .then((res) => {
        props.refreshFunction(9);
        notify.showSuccess("Your Listing is added Successfully");
        history.push("/box-query");
        setLoader(false);
      })
      .catch((err) => {
        if (err.response) {
          notify.handleError(err.response.data.msg._message + ", Try again");
          console.log(err);
          setLoader(false);
        }
      });
  };

  return (
    <>
      {loading !== true ? (
        <>
          {" "}
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "80vh", top: "50%" }}
          >
            <SyncLoader size={18} color="#1d6aba" margin="2" />
          </div>
        </>
      ) : (
        <section id="main-dashboard">
          <div className="sidebar-row">
            <div className="sidebar-container">
              <RenterSidebar />
            </div>
            <div className="dashboard-content">
              <div className="dashboard-title">
                <h5>Dashboard | Box Request</h5>
              </div>
              <section
                id="add-listing-here"
                className="animate__animated animate__fadeInUp"
              >
                <div className="add-listing pt-3 ">
                  <h5>BASIC DETAILS</h5>
                  <hr className="mb-3" />
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row mb-4">
                      <div className="col-md-3">
                        <div className="form-outline">
                          <label className="form-label">Box Size</label>
                          <select
                            type="text"
                            name="box_size"
                            id="box_size"
                            onClick={(e) =>
                              setSize(
                                e.target.value === "Small"
                                  ? 200
                                  : e.target.value === "Medium"
                                  ? 400
                                  : e.target.value === "Large"
                                  ? 600
                                  : 0
                              )
                            }
                            className={classNames("form-control", {
                              "is-invalid": errors.box_size,
                            })}
                            placeholder="Enter the Box Size."
                            ref={register({
                              required: "Required Field ",
                              minLength: {
                                value: 5,
                                message: "Select One",
                              },
                            })}
                          >
                            <option value="">Select One</option>
                            <option value="Small">Small</option>
                            <option value="Medium">Medium</option>
                            <option value="Large">Large</option>
                          </select>
                          {errors.box_size ? (
                            <p style={{ color: "red", marginBottom: "-10px" }}>
                              <FiAlertTriangle /> {errors.box_size.message}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="form-outline">
                          <label className="form-label storage-type">
                            Start Date
                          </label>
                          <input
                            type="date"
                            id="start_date"
                            name="start_date"
                            className={classNames("form-control", {
                              "is-invalid": errors.start_date,
                            })}
                            ref={register({
                              required: true,
                            })}
                          />
                          {errors.start_date && (
                            <p style={{ color: "red", marginBottom: "-10px" }}>
                              <FiAlertTriangle /> Required Field
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-outline">
                          <label className="form-label" htmlFor="listing-title">
                            PickUp Time
                          </label>
                          <input
                            type="time"
                            name="pickup_time"
                            className="form-control"
                            ref={register({
                              required: "Required",
                            })}
                          />
                          {errors.pickup_time ? (
                            <p style={{ color: "red", marginBottom: "-10px" }}>
                              <FiAlertTriangle /> {errors.pickup_time.message}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <label className="form-label" htmlFor="listingType">
                          Storing Items
                        </label>
                        <input
                          type="text"
                          name="storing_item"
                          id="storing_item"
                          placeholder="Enter Storing Items"
                          className={classNames("form-control", {
                            "is-invalid": errors.storing_item,
                          })}
                          ref={register({
                            required: "Required Field ",
                          })}
                        />

                        {errors.storing_item ? (
                          <p style={{ color: "red", marginBottom: "-10px" }}>
                            <FiAlertTriangle /> {errors.storing_item.message}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <hr className="mt-5" />

                    <div className="row">
                      <div className="col-md-4">
                        <label className="form-label">Fragile</label>
                        <select
                          type="text"
                          name="isFragile"
                          id="isFragile"
                          className={classNames("form-control", {
                            "is-invalid": errors.isFragile,
                          })}
                          placeholder="Select One"
                          ref={register({
                            required: "Required Field ",
                            minLength: {
                              message: "Select One",
                            },
                          })}
                        >
                          <option value="">Select One</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                        {errors.isFragile ? (
                          <p style={{ color: "red", marginBottom: "-10px" }}>
                            <FiAlertTriangle /> {errors.isFragile.message}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-4">
                        <label className="form-label">Plan Type</label>
                        <select
                          type="text"
                          name="plan_type"
                          id="isFragile"
                          className={classNames("form-control", {
                            "is-invalid": errors.plan_type,
                          })}
                          placeholder="Select One"
                          onClick={(e) => {
                            setStoragePlan(e.target.value);
                            setPrice(
                              e.target.value === "Weekly"
                                ? 1
                                : e.target.value === "Monthly"
                                ? 5
                                : e.target.value === "Yearly"
                                ? 52
                                : 0
                            );
                          }}
                          ref={register({
                            required: "Required Field ",
                            minLength: {
                              value: 5,
                              message: "Select One",
                            },
                          })}
                        >
                          <option value="">Select One</option>
                          <option value="Weekly">Weekly</option>
                          <option value="Monthly">Monthly</option>
                          <option value="Yearly">Yearly</option>
                        </select>
                        {errors.plan_type ? (
                          <p style={{ color: "red", marginBottom: "-10px" }}>
                            <FiAlertTriangle /> {errors.plan_type.message}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-4">
                        <label className="form-label">Storage Type</label>
                        <select
                          type="text"
                          name="storage_plan"
                          id="isFragile"
                          className={classNames("form-control", {
                            "is-invalid": errors.storage_plan,
                          })}
                          placeholder="Select One"
                          ref={register({
                            required: "Required Field ",
                            minLength: {
                              message: "Select One",
                            },
                          })}
                        >
                          <option value="">Select One</option>
                          {storagePlan === "Weekly" ? (
                            <>
                              <option value="A Week">A Week</option>
                              <option value="Several Weeks">
                                Several Weekly
                              </option>
                            </>
                          ) : null}
                          {storagePlan === "Monthly" ? (
                            <>
                              <option value="A Month">A Month</option>
                              <option value="Several Month">
                                Several Month
                              </option>
                            </>
                          ) : null}
                          {storagePlan === "Yearly" ? (
                            <>
                              <option value="A Year">A Year</option>
                              <option value=" Several Year">
                                Several Year
                              </option>
                            </>
                          ) : null}
                        </select>
                        {errors.storage_plan ? (
                          <p style={{ color: "red", marginBottom: "-10px" }}>
                            <FiAlertTriangle /> {errors.storage_plan.message}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <hr className="mt-5" />
                    <h5>Location Of Interest</h5>
                    <hr className="mb-3" />
                    <div className="row mb-4">
                      <div className="col-sm">
                        {/* <Autofill /> */}
                        {/* <AddMap /> */}
                        <NewMap locationCoordinate={locationCoordinate} />
                      </div>
                    </div>

                    <div className="form-outline mb-4">
                      <label className="form-label">Message</label>
                      <textarea
                        type="text"
                        name="message"
                        id="message"
                        className={classNames("form-control", {
                          "is-invalid": errors.message,
                        })}
                        placeholder="Message"
                        ref={register({
                          required: "Required Field",
                          
                        })}
                      />
                      {errors.message ? (
                        <p style={{ color: "red", marginBottom: "-10px" }}>
                          <FiAlertTriangle /> {errors.message.message}
                        </p>
                      ) : null}
                    </div>
                    {/* <h2>Choose Package</h2>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="price"
                        id="flexRadioDefault1"
                        onClick={() => setPrice(1)}
                      />
                      <label class="form-check-label" for="flexRadioDefault1">
                        Weekly
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="price"
                        id="flexRadioDefault2"
                        onClick={() => setPrice(5)}
                      />
                      <label class="form-check-label" for="flexRadioDefault2">
                        Monthly
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        name="price"
                        type="radio"
                        id="flexRadioDefault2"
                        onClick={() => setPrice(52)}
                      />
                      <label class="form-check-label" for="flexRadioDefault2">
                        Yearly
                      </label>
                    </div>
                    {errors.price ? (
                      <p style={{ color: "red", marginBottom: "-10px" }}>
                        <FiAlertTriangle /> {errors.price.message}
                      </p>
                    ) : null} */}
                    {/* <h2>Cost : ${size*price} </h2>*/}
                    <button
                      type="submit"
                      className="signupbtnLogin btn-primary btn-block mb-4 mt-4"
                    >
                      Submit
                    </button>
                  </form>
                </div>
              </section>
            </div>
          </div>
          <Loader loader={loader} />
        </section>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  refresh: state.refresh.refresh.refresh,
  refreshFunction: state.refresh.refresh.refreshFunction,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AddBox);
