import { ADMIN_ALL_SPACE_QUERY_REQUEST } from "../action/action";

const initialState = { allSpaceRequest: [] };
export const allSpaceRequestReducer = (state = initialState, action) => {
  switch (action.type) {
  case ADMIN_ALL_SPACE_QUERY_REQUEST:
    return { ...state, allSpaceRequest: action.payload };

  default:
    return { ...state };
  }
};
