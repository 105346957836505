import React, { useRef, useState } from "react";
import { FiAlertTriangle } from "react-icons/fi";
import LoginImg from "../images/login.png";
import Ready from "../Public/Ready";
import Footer from "../Public/Footer";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import classNames from "classnames";
// import axios from "axios";
import { create } from "../Axios/axiosService";
import { getLocalStorage, setLocalStorage } from "../Axios/tokenStorage";
import { adminToken, token } from "../Axios/baseURL";
import notify from "../Notify/notify";
import Loader from "./../service/loader/loader";

const Signup = (props) => {
  const [loader, setLoader] = useState(false);
  const [termsAgreed, setTermsAgreed] = useState(false)
  const { register, handleSubmit, errors, watch } = useForm();
  const history = useHistory();

  const password = useRef({});
  password.current = watch("password", "");

  if (getLocalStorage(token)) {
    history.push("/");
  } else if (getLocalStorage(adminToken)) {
    history.push("/admin-panel");
  }
  const onSubmit = (data) => {
    
    console.log(data)
    setLoader(true);
    data.name = data.firstName + " " + data.lastName;
    const myData = {
      name: data.name,
      email: data.email,
      address: data.address,
      phone: data.phone,
      password: data.password,
    };
    let finalData = new FormData();

    for (const key of Object.keys(myData)) {
      // if (key === "profileImage") {
      //   finalData.append("profileImage", myData.profileImage);
      // } else {
      finalData.append(key, myData[key]);
      // }
    }
if(!termsAgreed) {
      notify.handleError('Please agree to Terms and Conditions.')
      setLoader(false);
      return
    }
    create("/api/auth/register", finalData)
      .then((res) => {
        setLoader(false);
        props.history.push("/login");
        notify.showSuccess(res.data.message);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };
  const getDimension = async (file) => {
    let reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException("Problem parsing input file."));
      };

      reader.onload = () => {
        var image = new Image();
        image.src = reader.result;
        image.onload = function () {
          resolve({ width: this.width, height: this.height });
        };
      };
      reader.readAsDataURL(file);
    });
  };
  return (
    <section>
      <section id="login-page">
        <div className="login-main">
          <div className="col-md-12 col-lg-7 animate__animated animate__fadeInLeft">
            <div className="login-page">
              <h3 className="mt-2 mb-4 text-center">
                CREATE AN ACCOUNT AS RENTER
              </h3>
              <hr className="mb-4" />
              <form className="form-group " onSubmit={handleSubmit(onSubmit)}>
                {/* <div className="d-flex  text-center flex-wrap flex-column flex-lg-row justify-content-between">
                <button className="signupbtn-google">
                  <FaGoogle className="social-icons-google" />
                  Sign up with Google
                </button>
                <button className="signupbtn-facebook">
                  <FaFacebookF className="social-icons-facebook" />
                  Sign up with Facebook
                </button>
              </div> */}
                {/* <h5>
                <span>or</span>
              </h5> */}
                <div className="row mb-3">
                  <div className="col-lg-6 col-md-6 col-sm-12 mb-md-0 mb-3">
                    <input
                      type="text"
                      className={classNames("form-control", {
                        "is-invalid": errors.firstName,
                      })}
                      name="firstName"
                      id="firstName"
                      placeholder="First Name"
                      data-toggle="tooltip"
                      ref={register({
                        required: "Required",
                        minLength: {
                          value: 2,
                          message: "Enter Real Name",
                        },
                      })}
                    />
                    {errors.firstName ? (
                      <p
                        style={{
                          color: "red",
                          marginBottom: "-10px",
                          fontSize: "12px",
                        }}
                      >
                        <FiAlertTriangle /> {errors.firstName.message}
                      </p>
                    ) : null}
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12 ">
                    <input
                      type="text"
                      className={classNames("form-control", {
                        "is-invalid": errors.lastName,
                      })}
                      name="lastName"
                      id="lastName"
                      placeholder="Last Name"
                      ref={register({
                        required: "Required",
                        minLength: {
                          value: 2,
                          message: "Enter Real Name",
                        },
                      })}
                    />
                    {errors.lastName ? (
                      <p
                        style={{
                          color: "red",
                          marginBottom: "-10px",
                          fontSize: "12px",
                        }}
                      >
                        <FiAlertTriangle /> {errors.lastName.message}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-lg-6 col-md-6 col-sm-12  mb-md-0 mb-3">
                    <input
                      type="text"
                      className={classNames("form-control", {
                        "is-invalid": errors.address,
                      })}
                      name="address"
                      id="address"
                      placeholder="Current Address"
                      ref={register({
                        required: "Required",
                      })}
                    />
                    {errors.address ? (
                      <p
                        style={{
                          color: "red",
                          marginBottom: "-10px",
                          fontSize: "12px",
                        }}
                      >
                        <FiAlertTriangle /> {errors.address.message}
                      </p>
                    ) : null}
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12 ">
                    <input
                      type="text"
                      className={classNames("form-control", {
                        "is-invalid": errors.phone,
                      })}
                      name="phone"
                      id="phone"
                      placeholder="Phone Number"
                      ref={register({
                        required: "Required",
                        // pattern: {
                        //   value: /^\d{10}$/,
                        //   message: "Enter 10 Digit  Number ",
                        // },
                      })}
                    />
                    {errors.phone ? (
                      <p
                        style={{
                          color: "red",
                          marginBottom: "-10px",
                          fontSize: "12px",
                        }}
                      >
                        <FiAlertTriangle /> {errors.phone.message}
                      </p>
                    ) : null}
                  </div>
                </div>

                <div className="mb-3 form-group">
                  <input
                    type="email"
                    className={classNames("form-control", {
                      "is-invalid": errors.email,
                    })}
                    name="email"
                    id="email"
                    placeholder="Email"
                    ref={register({
                      required: "Required",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Invalid email address",
                      },
                    })}
                  />
                  {errors.email ? (
                    <p
                      style={{
                        color: "red",
                        marginBottom: "-10px",
                        fontSize: "12px",
                      }}
                    >
                      <FiAlertTriangle /> {errors.email.message}
                    </p>
                  ) : null}
                </div>
                <div className="row mb-3 form-group">
                  <div className="col-lg-6 col-md-6 col-sm-12  mb-md-0 mb-3">
                    <input
                      type="password"
                      className={classNames("form-control", {
                        "is-invalid": errors.password,
                      })}
                      name="password"
                      id="password"
                      placeholder="Password"
                      ref={register({
                        required: "8 digit Password Required",
                        validate: (value) => {
                          return (
                            [
                              /[a-z]/,
                              /[A-Z]/,
                              /[0-9]/,
                              /[^a-zA-Z)-9]/,
                            ].every((pattern) => pattern.test(value)) ||
                            "Password must Contain Uppercase, LowerCase, Number and a Special Character"
                          );
                        },
                      })}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 ">
                    <input
                      type="password"
                      className={classNames("form-control", {
                        "is-invalid": errors.cpassword,
                      })}
                      name="cpassword"
                      placeholder="Confirm Password"
                      ref={register({
                        validate: (value) =>
                          value === password.current ||
                          "The Password do not match",
                      })}
                    />

                    {errors.cpassword && (
                      <p
                        style={{
                          fontSize: "12px",
                          marginBottom: "-10px",
                          color: "red",
                        }}
                      >
                        {" "}
                        {errors.cpassword.message}
                      </p>
                    )}
                  </div>
                </div>
                {errors.password ? (
                  <p
                    style={{
                      marginBottom: "-10px",
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    <FiAlertTriangle /> {errors.password.message}{" "}
                  </p>
                ) : null}
                <div className="d-flex  text-center flex-wrap flex-column flex-lg-row justify-content-between pt-3">
                  <button
                    className="signupbtnLogin mb-3"
                    type="submit"
                    style={{ width: "100%" }}
                  >
                    SIGN UP
                  </button>
                </div>
                <div className="text-center mt-2">
                  
                  <input type="checkbox" onChange={() => setTermsAgreed(true)} style={{marginRight:'6px'}}/>Agree ot our <a href='./stozy-terms-of-service.pdf' target='_blank' rel="noreferrer">Terms And Condition</a>
                  
                </div>
                <div className="text-center mt-2">
                  {" "}
                  Already have an account? <Link to="/login">Login here</Link>
                </div>
                
              </form>
            </div>
          </div>
          <div className="image-container img-fluid col-md-5 d-none d-lg-block animate__animated animate__fadeInRight">
            <img alt="" src={LoginImg} className="image2" />
          </div>
        </div>
      </section>
      <Ready />
      <Loader loader={loader} />

      <Footer />
    </section>
  );
};

export default Signup;
