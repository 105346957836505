import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import notify from "../Notify/notify";
import RenterSidebar from "./RenterSidebar";
import { connect } from "react-redux";
import Loader from "./../service/loader/loader";
import { update } from "../Axios/axiosService";
import { base_URL } from "../Axios/baseURL";

const EditProfile = (props) => {
  const [loader, setLoader] = useState(false);

  const { id } = useParams();
  const { register, handleSubmit, setValue } = useForm({
    mode: "onTouched",
  });
  const profile = props?.renterInfo[0];

  useEffect(() => {
    setValue("email", profile?.email);
    setValue("fullName", profile?.name);
    setValue("address", profile?.address);
    setValue("number", profile?.phone);
    setValue("gender", profile?.gender);
    // setValue("profileImage", data.profileImage[0]);
    // eslint-disable-next-line
  }, [props]);

  const history = useHistory();

  const onSubmit = (data) => {
    setLoader(true);
    const myData = {
      name: data.fullName,
      address: data.address,
      phone: data.number,
      profileImage: data.profileImage[0] || profile?.profileImage,
    };

    let finalData = new FormData();
    for (const key of Object.keys(myData)) {
      finalData.append(key, myData[key]);
    }
    update(`/api/user/${id}`, finalData)
      // update(`api/user/${id}`, {
      //   name: data.fullName,
      //   address: data.address,
      //   phone: data.number,
      // })
      .then((res) => {
        props.refreshFunction(3);
        notify.showSuccess("Your profile is Updated successfully");
        history.push("/dashboard-renter-profile");
        setLoader(false);
      })
      .catch((err) => {
        if (err.response) {
          notify.handleError(err.response.data.msg);
          // console.log(err.response.status);
          // console.log(err.response.headers);});
          setLoader(false);
        }
      });
  };

  return (
    <>
      <section id="main-dashboard">
        <div className="sidebar-row">
          <div className="sidebar-container">
            <RenterSidebar />
          </div>

          <div className="dashboard-content">
            <div className="dashboard-title">
              <h5>Dashboard | Edit Profile</h5>
            </div>
            <div className="profilePage">
              <div className="profileDetails d-flex flex-wrap justify-content-between">
                <div className="col-lg-5 text-center">
                  <img
                    alt=""
                    name="profileImage"
                    src={`${base_URL}/file/images/` + profile?.profileImage}
                    style={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />{" "}
                  <br /> <br />
                  <div className="form-group ml-5">
                    <label htmlFor="profileImage" className="text-center ml-0">
                      Update Profile Picture
                    </label>
                    <input
                      type="file"
                      className="form-control-file ml-5"
                      id="profileImage"
                      name="profileImage"
                      ref={register}
                      onChange={(e) => e.target.files}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row mb-4">
                      <div className="col">
                        <div className="form-outline">
                          <label className="form-label">Full Name</label>
                          <input
                            type="text"
                            name="fullName"
                            className="form-control"
                            placeholder="Enter your Full Name."
                            ref={register()}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-sm-12 col-md-6 mb-3">
                        <div className="form-outline">
                          <label className="form-label">Address</label>
                          <input
                            type="text"
                            name="address"
                            className="form-control"
                            placeholder="Enter your Address"
                            ref={register()}
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-outline">
                          <label className="form-label">Phone Number</label>
                          <input
                            type="text"
                            name="number"
                            id="number"
                            className="form-control"
                            ref={register()}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col">
                        <div className="form-outline">
                          <label className="form-label">E-mail</label>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            className="form-control"
                            placeholder="Enter your E-mail."
                            ref={register()}
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <button type="submit" className="signupbtnLogin w-100 mt-3">
                      Update
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Loader loader={loader} />
      <section id="profilePage"></section>
    </>
  );
};
const mapStateToProps = (state) => ({
  renterInfo: state.renterInfo.renterInfo,
  refresh: state.refresh.refresh.refresh,
  refreshFunction: state.refresh.refresh.refreshFunction,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
