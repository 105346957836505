import { REFRESH_PAGE } from "../action/action";

const initialState = { refresh: {} };
export const refreshReducer = (state = initialState, action) => {
  switch (action.type) {
  case REFRESH_PAGE:
    return { ...state, refresh: action.payload, refreshFunction:action.payload };

  default:
    return { ...state };
  }
};
