/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  ALL_FEATURED_PROPERTY,
  GET_ALL_PROPERTY,
  GET_OWNER_INFO,
  OWNER_FEATURED_PROPERTY,
  OWNER_PROPERTY,
  REFRESH_PAGE,
  RENTER_FAVOURITIES_PROPERTY,
  RENTER_BOOKED_PROPERTY,
  RENTER_INFO,
  OWNER_BOOKED_PROPERTY,
  ALL_BOOKED_PROPERTY,
  RENTAL_BOX_QUERY_REQUEST,
  ADMIN_ALL_BOX_QUERY_REQUEST,
  ADMIN_ALL_SPACE_QUERY_REQUEST,
  RENTAL_SPACE_QUERY_REQUEST
} from "../redux/action/action";
import { read } from "../../src/Axios/axiosService";
import { role, token,adminToken, base_URL } from "../../src/Axios/baseURL";

import { getLocalStorage } from "../../src/Axios/tokenStorage";
import axios from "axios";

const FetchData = (props) => {
  const [refreshComponent, setRefreshComponent] = useState(0);

  useEffect(() => {
    allProperty();
    allFeaturedProperty();
    if (getLocalStorage(adminToken)) {
      if (getLocalStorage(role) === "admin") {
        adminAllBoxQuery();
        adminAllSpaceQuery()
      }
    }
    if (getLocalStorage(token)) {
      if (getLocalStorage(role) === "renter") {
        renterFavouritesProperty();
        renterBookedProperty();
        renterInfo();
        renterBoxQuery();
        renterSpaceQuery()
      } else {
        ownerInfo();
        ownerFeaturedProperty();
        ownerProperty();
        ownerBookedProperty();
        allBookedProperty();
      }
    }
    props.refresh({
      refresh: refreshComponent,
      refreshFunction: setRefreshComponent,
    });
    // eslint-disable-next-line
  }, []);
  //all
  useEffect(() => {
    allProperty();
    allFeaturedProperty();
    switch (refreshComponent) {
      case 1:
        renterFavouritesProperty(); //1

        break;
      case 2:
        renterBookedProperty(); //2

        break;
      case 3:
        renterInfo(); //3

        break;
      case 4:
        ownerInfo(); //4

        break;
      case 5:
        ownerFeaturedProperty(); //5
        break;
      case 6:
        ownerProperty(); //6

        break;
      case 7:
        ownerBookedProperty(); //7

        break;
      case 8:
        allBookedProperty(); //8

        break;
      case 9:
        renterBoxQuery(); //9
        break;
      case 10:
        adminAllBoxQuery(); //10
        break;
        case 11:
          adminAllSpaceQuery(); //11
         break;
         case 12:
          renterSpaceQuery(); //11
         break;
    } 

    setRefreshComponent(0);
    // eslint-disable-next-line
  }, [refreshComponent]);
  //user
  /*useEffect(()=>{
  renterFavouritesProperty();

},[rentalFavouritProp])


useEffect(()=>{

  renterBookedProperty();

},[rentalBookedProp])
useEffect(()=>{

  renterInfo();
},[rentalInformation])

//owner useeffect call
useEffect(()=>{
  ownerInfo();

},[ownerInformation])
useEffect(()=>{
  ownerFeaturedProperty();

},[ownerFeaturedProp])
useEffect(()=>{
  ownerProperty();

},[ownerProp])
useEffect(()=>{
  ownerBookedProperty();

},[ownerBookedProp])
useEffect(()=>{
  allBookedProperty()

},[allBookedProp])*/

  const allProperty = () => {
    read("/api/getallproperty/")
      .then((res) => {
        props.allPropery(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //all
  //sabai ko featured property
  const allFeaturedProperty = () => {
    read("/api/getallfeaturedproperty")
      .then((res) => {
        props.allFeaturedProperty(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //host
  const ownerInfo = () => {
    read("/api/owner/getmyinfo")
      .then((res) => {
        props.getOwnerInfo(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //host
  const ownerFeaturedProperty = () => {
    read("/api/property/getallfeatured")
      .then((res) => {
        props.ownerFeaturedProperty(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //host
  const ownerProperty = () => {
    read("/api/property")
      .then((res) => {
        props.ownerProperty(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //owner
  const ownerBookedProperty = () => {
    read("/api/owner/getmybooking")
      .then((res) => {
        props.ownerBookedProperty(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const allBookedProperty = () => {
    read("/api/owner/listallbookings")
      .then((res) => {
        props.allBookedProperty(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //renter
  const renterFavouritesProperty = () => {
    read("/api/favourites/getmyfavourites")
      .then((res) => {
        props.renterFavouritesProperty(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //renter
  const renterInfo = () => {
    read("/api/user/getmyinfo")
      .then((res) => {
        props.renterInfo(res.data);
      })
      .catch((err) => {
        console.log(err);
        //        localStorage.clear();
      });
  };
  //renter
  const renterBookedProperty = () => {
    read("/api/booking")
      .then((res) => {
        props.renterBookedProperty(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const renterBoxQuery = () => {
    read("/api/user/getmyboxquery")
      .then((res) => {
        props.rentalBoxQueryRequest(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const renterSpaceQuery = () => {
    read("/api/user/getmyspacequery")
      .then((res) => {
        props.rentalSpaceQueryRequest(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const adminAllBoxQuery = () => {
    read("/api/admin/viewboxrequest")
      .then((res) => {
        props.adminAllBoxQueryRequest(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const adminAllSpaceQuery = () => {
    read("/api/admin/viewspacerequest")
      .then((res) => {
        props.adminAllSpaceQueryRequest(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return <div></div>;
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  allPropery: (arg) => dispatch({ type: GET_ALL_PROPERTY, payload: arg }),
  allFeaturedProperty: (arg) =>
    dispatch({ type: ALL_FEATURED_PROPERTY, payload: arg }),

  getOwnerInfo: (arg) => dispatch({ type: GET_OWNER_INFO, payload: arg }),
  ownerFeaturedProperty: (arg) =>
    dispatch({ type: OWNER_FEATURED_PROPERTY, payload: arg }),
  ownerProperty: (arg) => dispatch({ type: OWNER_PROPERTY, payload: arg }),
  renterFavouritesProperty: (arg) =>
    dispatch({ type: RENTER_FAVOURITIES_PROPERTY, payload: arg }),
  renterBookedProperty: (arg) =>
    dispatch({ type: RENTER_BOOKED_PROPERTY, payload: arg }),
  refresh: (arg) => dispatch({ type: REFRESH_PAGE, payload: arg }),
  renterInfo: (arg) => dispatch({ type: RENTER_INFO, payload: arg }),
  ownerBookedProperty: (arg) =>
    dispatch({ type: OWNER_BOOKED_PROPERTY, payload: arg }),
  allBookedProperty: (arg) =>
    dispatch({ type: ALL_BOOKED_PROPERTY, payload: arg }),
  rentalBoxQueryRequest: (arg) =>
    dispatch({ type: RENTAL_BOX_QUERY_REQUEST, payload: arg }),
  adminAllBoxQueryRequest: (arg) =>
    dispatch({ type: ADMIN_ALL_BOX_QUERY_REQUEST, payload: arg }),
    adminAllSpaceQueryRequest: (arg) =>
    dispatch({ type:ADMIN_ALL_SPACE_QUERY_REQUEST , payload: arg }),
    rentalSpaceQueryRequest: (arg) =>
    dispatch({ type: RENTAL_SPACE_QUERY_REQUEST, payload: arg }),
    
});

export default connect(mapStateToProps, mapDispatchToProps)(FetchData);
