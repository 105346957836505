import React from "react";
import { Redirect, Route } from "react-router-dom";
import { getLocalStorage } from "../Axios/tokenStorage";
import { token } from "../Axios/baseURL";

const PrivateRouter = ({ component: Component, ...rest }) => {
  const authorize = getLocalStorage(token);

  return (
    <Route
      {...rest}
      render={(props) =>
        authorize ? (
          <Component {...props} />
        ) : (
          (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          )
        )
      }
    />
  );
};
export default PrivateRouter;
