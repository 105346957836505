import React from "react";
import { Tooltip } from "antd";
import { FaEdit, FaStar } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { Link } from "react-router-dom";
import { AiOutlineStar } from "react-icons/ai";

const ListingItem = ({
  item,
  deleteItem,
  featured,
  index,
  ownerFeatureProperty,
  indexOfFirstPost,
}) => {
  return (
    <>
      <tbody>
        <tr>
          <th scope="row"> {indexOfFirstPost + index + 1}. </th>
          <td>
            <Link
              to={{
                pathname: `/list-details/${item._id}`,
              }}
            >
              <b style={{ color: "#1D6ABA", textTransform: "capitalize" }}>
                {item.title}
              </b>
            </Link>
            <br />
            <small> {item?.address?.location}</small>
          </td>
          <td style={{ textTransform: "capitalize" }}>
            <small> {item?.typeOfStorage}</small>
          </td>
          <td style={{ textTransform: "capitalize" }}>
            <small>{item?.typeOfListing}</small>{" "}
          </td>
          <td>
            <ul className="list-unstyled">
              <li
                style={{
                  color: "#1d6aba",
                }}
              >
                <small>
                  {item?.planType?.weeklyPlan === "available"
                    ? "WeeklyPlan"
                    : null}
                </small>
              </li>
              <li
                style={{
                  color: "#009933",
                }}
              >
                <small>
                  {" "}
                  {item.planType.monthlyPlan === "available"
                    ? "MonthlyPlan"
                    : null}
                </small>
              </li>
              <li
                style={{
                  color: "#ee00ff",
                }}
              >
                <small>
                  {item.planType.yearlyPlan === "available"
                    ? "YearlyPlan"
                    : null}
                </small>
              </li>
            </ul>
          </td>
          <td>
            <ul className="list-unstyled ">
              <li
                style={{
                  color: "#1d6aba",
                }}
              >
                <small>
                  {item.planType.weeklyPlan === "available" ? (
                    <>{item.pricing.weekly}$</>
                  ) : null}
                </small>
              </li>
              <li
                style={{
                  color: "#009933",
                }}
              >
                <small>
                  {item.planType.monthlyPlan === "available" ? (
                    <>{item.pricing.monthly}$</>
                  ) : null}
                </small>
              </li>
              <li
                style={{
                  color: "#ee00ff",
                }}
              >
                <small>
                  {item.planType.yearlyPlan === "available" ? (
                    <>{item.pricing.yearly}$</>
                  ) : null}
                </small>
              </li>
            </ul>
          </td>
          <td>
            {item?.isVerified === true ? (
              <h5 className="accepted">Accepted</h5>
            ) : null}
            {item.isVerified === false ? (
              <h5 className="pending">Rejected</h5>
            ) : null}
          </td>
          <td>
            {item?.isVerified === true ? (
              <>
                {item?.booking_status === "available" ? (
                  <h5 className="new">Available</h5>
                ) : item?.booking_status === "pending" ? (
                  <h5 className="pending">Pending</h5>
                ) : (
                  <h5 className="accepted">Booked</h5>
                )}
              </>
            ) : (
              <small>Not Verified</small>
            )}
          </td>
          <td>
            <div className="d-flex justify-content-center">
              <Tooltip title="Edit">
                <Link
                  to={{
                    pathname: `/dashboard-edit-listing/${item._id}`,
                  }}
                >
                  <button className="manage-button1">
                    <FaEdit className="icon" />
                  </button>
                </Link>
              </Tooltip>
              <Tooltip title="Delete">
                <button
                  className="manage-button1"
                  data-toggle="modal"
                  data-target={`#deleteListing-${item._id}`}
                >
                  {" "}
                  <MdDeleteForever className="icon" />{" "}
                </button>
              </Tooltip>
              {ownerFeatureProperty.some((arg) => arg._id === item?._id) ===
              true ? (
                <Tooltip title="Featured Property">
                  <button
                    // style={
                    //   ownerFeatureProperty.some(
                    //     (arg) => arg._id === item?._id
                    //   ) === true
                    //     ? { backgroundColor: "royalblue" }
                    //     : { backgroundColor: "red" }
                    // }
                    className="manage-button1"
                  >
                    <FaStar className="icon" />
                  </button>
                </Tooltip>
              ) : (
                <Tooltip title="Upgrade to Featured">
                  <button
                    className="manage-button1"
                    onClick={() => {
                      featured(item?._id);
                    }}
                  >
                    <AiOutlineStar className="icon" />
                  </button>
                </Tooltip>
              )}
            </div>
            {/*<Tooltip title="View Details">
                                <Link
                                  to={{
                                    pathname: `/list-details/${item?._id}`,
                                  }}
                                >
                                  <button className="manage-button">
                                    {" "}
                                    <FaArrowAltCircleRight className="icon" />{" "}
                                  </button>
                                </Link>
                                </Tooltip>*/}
          </td>
        </tr>
      </tbody>

      <div
        className="modal fade"
        id={`deleteListing-${item._id}`}
        tabindex="-1"
        role="dialog"
        aria-labelledby="deleteListing"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title ml-auto">
                Do you want to delete this Listing from your Dashboard?
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-footer  justify-content-center">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  deleteItem(item._id);
                }}
              >
                Yes
              </button>

              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListingItem;
