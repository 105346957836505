import React from "react";
import "./app.css";
import { MapContainer, Marker, TileLayer, Tooltip } from "react-leaflet";
import { FaLocationArrow } from "react-icons/fa";

export default function App(props) {
  const { listingGrid } = props;
  return (
    <MapContainer center={[1.290270, 103.851959]} zoom={8}>
      <TileLayer
        url="https://{s}.tile.jawg.io/jawg-streets/{z}/{x}/{y}{r}.png?access-token={accessToken}"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        //5vqKwRTKrr0qxSIzWCD5MLSeYmozcBDsegboSlw87roFcLCeRyjrpAow87s6p67A//
        accessToken="5vqKwRTKrr0qxSIzWCD5MLSeYmozcBDsegboSlw87roFcLCeRyjrpAow87s6p67A"
      />

      {/* var OpenStreetMap_Mapnik = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
	maxZoom: 19,
	attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
}); */}
      {
        
        listingGrid?.map((park, indx) => (
        <Marker
          // id={`dynamicTooltip${indx}`}
          key={park?.address?._id}
          position={[park?.address?.latitude!=='null'&&park?.address?.latitude, park?.address?.longitude!=='null'&&park?.address?.longitude]}
          eventHandlers={
            listingGrid?.length !== 1
              ? {
                  click: (e) => {
                    const cardDiv = document.getElementById(
                      `dynamicCard${indx}`
                    );
                    if (!cardDiv) {
                      // show go to next page msg
                      // or tyo css pagination ko element ma euta animation banau
                    }
                    window.scroll({
                      behavior: "smooth",
                      left: 0,
                      top: cardDiv
                        ? cardDiv.offsetTop
                        : document.body.scrollHeight,
                    });
                  },
                }
              : null
          }
        >
          <Tooltip id={`dynamicTooltip${indx}`}>
            {" "}
            <h5>
              {" "}
              <b style={{ color: "#1d6aba" }}></b> {park?.title}{" "}
            </h5>
            <p>
              {" "}
              <b style={{ color: "#1d6aba" }}>
                {" "}
                <FaLocationArrow />
              </b>{" "}
              {park?.address?.location}
            </p>
          </Tooltip>
        </Marker>
        ))
          
    }
    </MapContainer>
  );
}
