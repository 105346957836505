import React, { useState } from "react";
import { create, read } from "../Axios/axiosService";
import notify from "../Notify/notify";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import DropDown from "./../shared/selectDropdownOption/selectDropdown";
import { Link } from "react-router-dom";
import PriceSlider from "../Listing/PriceSlider";
import { FaAngleRight } from "react-icons/fa";
import { GoLocation } from "react-icons/go";
import Autocomplete2 from "../Map/AutoComplete2";
import NewMap from "../Map/NewMap";
import Loader from "../service/loader/loader";
import { base_URL } from "../Axios/baseURL";


const SpaceBoxRequestDetail = (props) => {
  
  const { id } = useParams();

  const [loader, setLoader] = useState(false);
  const allBoxSpaceRequest = props?.allSpaceRequest?.data?.concat(
    props?.allBoxRequest?.data
  );
  const Property = props?.getAllProperty?.filter(
    (arg) => arg?.isVerified === true && arg?.booking_status === "available"
  );

  const selectedRequest = allBoxSpaceRequest?.filter(
    (arg) => arg?._id === id
  )[0];
  const [listingType, setListingType] = useState("");
  const [storageType, setStorageType] = useState("");

  const [location, setLocation] = useState("");
  const [coordinate, setCoordinate] = useState({});

  const [priceSearch, setPriceSearch] = useState([0, 10000]);
  const [weekly, setWeekly] = useState(false);
  const [monthly, setMonthly] = useState(false);
  const [yearly, setYearly] = useState(false);
  const [selectedSpace, setSelectedSpace] = useState();

  const locationCoordinate = (coordinate, location) => {
    setCoordinate(coordinate);
    setLocation(location);
  };

  //const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const listingGrid = props?.getAllProperty
    ?.filter(
      (arg) => arg.booking_status === "available" && arg?.isVerified === true
    )
    .reverse();

  const selectedProperty = listingGrid?.filter(
    (arg) => arg._id === selectedSpace
  )[0];
  // Filter function
  let filteredData = listingGrid;
  // Storage type
  if (storageType.length > 0) {
    filteredData = filteredData.filter((val) =>
      val.typeOfStorage.includes(storageType)
    );
  }
  // listening type
  if (listingType.length > 0) {
    filteredData = filteredData.filter((val) =>
      val.typeOfListing.includes(listingType)
    );
  }

  if (location) {
    filteredData = filteredData.filter((arg) =>
      arg?.address?.location?.toLowerCase().includes(location.toLowerCase())
    );
  }

  if (priceSearch[0] !== 0 || priceSearch[1] !== 10000) {
    filteredData = filteredData?.filter(
      (val) =>
        (val.pricing.weekly > priceSearch[0] &&
          val.pricing.weekly < priceSearch[1]) ||
        (val.pricing.monthly > priceSearch[0] &&
          val.pricing.monthly < priceSearch[1]) ||
        (val.pricing.yearly > priceSearch[0] &&
          val.pricing.yearly < priceSearch[1])
    );
  }
  if (weekly === true) {
    filteredData = filteredData?.filter(
      (val) => val.planType.weeklyPlan === "available"
    );
  }
  if (monthly === true) {
    filteredData = filteredData?.filter(
      (val) => val.planType.monthlyPlan === "available"
    );
  }
  if (yearly === true) {
    filteredData = filteredData?.filter(
      (val) => val.planType.yearlyPlan === "available"
    );
  }

  const boxAccepted = () => {
    setLoader(true);
    const acceptId = {
      requestId: id,
    };
    create("/api/admin/acceptboxrequest", acceptId)
      .then((res) => {
        props.refreshFunction(10);
        notify.showSuccess("Your Request is accepted Successfully");
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };
  const boxCancle = () => {
    setLoader(true);
    const rejectId = {
      requestId: id,
    };
    create("/api/admin/rejectboxrequest", rejectId)
      .then((res) => {
        props.refreshFunction(10);
        notify.showSuccess("Your Request is Rejected");
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };
  const spaceAccepted = () => {
    console.log(id)
    setLoader(true);
    const acceptId = {
      requestId: id,
    };
    create("/api/admin/acceptspacerequest", acceptId)
      .then((res) => {
        props.refreshFunction(11);
        notify.showSuccess("Request Accepted");
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };
  const spaceCancel = () => {
    setLoader(true);
    const rejectId = {
      requestId: id,
    };
    create("/api/admin/rejectspacerequest", rejectId)
      .then((res) => {
        props.refreshFunction(11);
        notify.showSuccess("Request Cancelled");
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const sendReservation = () => {
    setLoader(true);
    const rejectId = {
      propertyId: selectedSpace,
      requestId: id,
    };

    console.log(rejectId)

    if (rejectId?.propertyId!==undefined) {
      create("/api/admin/assign-property", rejectId)
      .then((res) => {
        console.log(res);
        props.refreshFunction(11);
        notify.showSuccess("Property Assigned");
        setLoader(false);
      })
      .catch((err) => {
        console.log(err?.response);
        // if(pro)
        setLoader(false);
      });
    } else {
      setLoader(false);

      notify.handleError("Please select a property to assign");
    }
    
  };

  return (
    <>
      <section className="spbRequest">
        <div className="row py-5 justify-content-center">
          <div className="col-lg-5 animate__animated animate__fadeInLeft">
            {selectedRequest?.box_size ? (
              <>
                <h4 className="text-center mb-3"> Box Request Information</h4>
                <hr />
                <ul className="list-unstyled text-capitalize">
                  <li>
                    Requested By:{" "}
                    <span className="text-muted">
                      {selectedRequest?.requested_by?.name}
                    </span>
                  </li>
                  <li>
                    Storing Item:{" "}
                    <span className="text-muted">
                      {selectedRequest?.storing_item}
                    </span>
                  </li>
                  <li>
                    Box Size:{" "}
                    <span className="text-muted">
                      {selectedRequest?.box_size}
                    </span>
                  </li>
                  <li>
                    Plan Type:{" "}
                    <span className="text-muted">
                      {selectedRequest?.plan_type}
                    </span>
                  </li>
                  <li>
                    Is Fragile:{" "}
                    <span className="text-muted">
                      {selectedRequest?.isFragile === true ? "Yes" : "No"}
                    </span>
                  </li>
                  <li>
                    Start Date:{" "}
                    <span className="text-muted">
                      {new Date(
                        selectedRequest?.createdAt
                      ).toLocaleDateString()}
                    </span>
                  </li>
                  <li>
                    Location of Interest:{" "}
                    <span className="text-muted">
                      {selectedRequest?.location_of_interest}
                    </span>
                  </li>
                </ul>
                <hr />
                <p>
                  Selected Property: <span className="text-muted"></span>
                  {selectedProperty ? (
                    <div
                      className="card"
                      style={{ marginBottom: "40px", width: "100%" }}
                    >
                      <div className="row ">
                        <div className="col-md-4">
                          <img
                            alt=""
                            src={
                              `${base_URL}/file/images/` +
                              selectedProperty?.propertyImage[0]
                            }
                            className="card-img-top"
                            style={{ objectFit: "cover" }}
                          />
                          <ul className="list-unstyled pt-2 pl-2">
                            <li className="font-weight-bold">
                              <h5 style={{ color: "#1D6ABA" }}>
                                {selectedProperty?.pricing?.weekly ? (
                                  <>
                                    {selectedProperty?.pricing?.weekly}${" "}
                                    <sub>per week</sub>
                                  </>
                                ) : selectedProperty?.pricing?.monthly ? (
                                  <>
                                    {selectedProperty?.pricing?.monthly}${" "}
                                    <sub>per month</sub>
                                  </>
                                ) : (
                                  <>
                                    {selectedProperty?.pricing?.yearly}${" "}
                                    <sub>per year</sub>
                                  </>
                                )}
                              </h5>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-8 px-3">
                          <div className="card-block px-3">
                            <h5 className="card-title pt-3 text-capitalize">
                              <b>{selectedProperty.title}</b>
                              <p style={{ fontSize: "15px" }}>
                                <GoLocation />{" "}
                                {selectedProperty?.address?.location}
                              </p>
                            </h5>

                            <ul className="list-unstyled text-left">
                              <li className="font-weight-bold text-capitalize">
                                <FaAngleRight /> Storage Type:{" "}
                                <b style={{ color: "#616161" }}>
                                  {selectedProperty?.typeOfStorage}
                                </b>
                              </li>
                              <li className="font-weight-bold text-capitalize">
                                <FaAngleRight /> Listing Type:{" "}
                                <b style={{ color: "#616161" }}>
                                  {" "}
                                  {selectedProperty?.typeOfListing}
                                </b>
                              </li>
                              <li className="font-weight-bold text-capitalize">
                                <FaAngleRight /> Size:&nbsp;&nbsp;
                                <b style={{ color: "#616161" }}>
                                  {" "}
                                  {selectedProperty?.size}
                                  &nbsp;{selectedProperty?.unit}
                                </b>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    "Property Not selected"
                  )}
                </p>

                <div className="btns text-center my-3">
                  <button
                    onClick={() => sendReservation()}
                    className="signupbtnLogin1 mr-5"
                  >
                    <span>Assign Property</span>
                  </button>
                </div>
              </>
            ) : (
              <>
                <h4 className="text-center mb-3"> Space Request Information</h4>

                <hr />
                <ul className="list-unstyled text-capitalize">
                  <li>
                    Requested By:{" "}
                    <span className="text-muted">
                      {selectedRequest?.requested_by?.name}
                    </span>
                  </li>
                  <li>
                    Storing Item:{" "}
                    <span className="text-muted">
                      {selectedRequest?.storing_item}
                    </span>
                  </li>
                  <li>
                    Space Size:{" "}
                    <span className="text-muted">
                      {selectedRequest?.space_size}
                    </span>
                  </li>
                  <li>
                    Plan Type:{" "}
                    <span className="text-muted">
                      {selectedRequest?.plan_type}
                    </span>
                  </li>
                  <li>
                    Is Fragile:{" "}
                    <span className="text-muted">
                      {selectedRequest?.isFragile === true ? "Yes" : "No"}
                    </span>
                  </li>
                  <li>
                    Start Date:{" "}
                    <span className="text-muted">
                      {new Date(
                        selectedRequest?.createdAt
                      ).toLocaleDateString()}
                    </span>
                  </li>
                  <li>
                    Location of Interest:{" "}
                    <span className="text-muted">
                      {selectedRequest?.location_of_interest}
                    </span>
                  </li>
                </ul>
                <hr />
                <p>
                  Selected Space: <span className="text-muted"></span>
                  {selectedProperty ? (
                    <div
                      className="card"
                      style={{ marginBottom: "40px", width: "100%" }}
                    >
                      <div className="row ">
                        <div className="col-md-4">
                          <img
                            alt=""
                            src={
                              `${base_URL}/file/images/` +
                              selectedProperty?.propertyImage[0]
                            }
                            className="card-img-top"
                            style={{ objectFit: "cover" }}
                          />
                          <ul className="list-unstyled pt-2 pl-2">
                            <li className="font-weight-bold">
                              <h5 style={{ color: "#1D6ABA" }}>
                                {selectedProperty?.pricing?.weekly ? (
                                  <>
                                    {selectedProperty?.pricing?.weekly}${" "}
                                    <sub>per week</sub>
                                  </>
                                ) : selectedProperty?.pricing?.monthly ? (
                                  <>
                                    {selectedProperty?.pricing?.monthly}${" "}
                                    <sub>per month</sub>
                                  </>
                                ) : (
                                  <>
                                    {selectedProperty?.pricing?.yearly}${" "}
                                    <sub>per year</sub>
                                  </>
                                )}
                              </h5>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-8 px-3">
                          <div className="card-block px-3">
                            <h5 className="card-title pt-3 text-capitalize">
                              {selectedProperty?.title}
                              <p style={{ fontSize: "15px" }}>
                                <GoLocation />{" "}
                                {selectedProperty?.address?.location}
                              </p>
                            </h5>

                            <ul className="list-unstyled text-left">
                              <li className="font-weight-bold text-capitalize">
                                <FaAngleRight /> Storage Type:{" "}
                                <b style={{ color: "#616161" }}>
                                  {selectedProperty?.typeOfStorage}
                                </b>
                              </li>
                              <li className="font-weight-bold text-capitalize">
                                <FaAngleRight /> Listing Type:{" "}
                                <b style={{ color: "#616161" }}>
                                  {" "}
                                  {selectedProperty?.typeOfListing}
                                </b>
                              </li>
                              <li className="font-weight-bold text-capitalize">
                                <FaAngleRight /> Size:&nbsp;&nbsp;
                                <b style={{ color: "#616161" }}>
                                  {" "}
                                  {selectedProperty?.size}
                                  &nbsp;{selectedProperty?.unit}
                                </b>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    "Property Not selected"
                  )}
                </p>

                <div className="btns text-center my-3">
                  <button
                    onClick={() => spaceAccepted()}
                    className="signupbtnLogin1 mr-5"
                  >
                    <span>Assign Space</span>
                  </button>
                </div>
              </>
            )}
          </div>
          <div className="half-gridAlt col-lg-5 col-md-12 order-sm-1 col-sm-12 animate__animated animate__fadeInRight">
            <div className="search-section">
              <>
                <div className="search-filter">
                  <form>
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="form-outline mb-3">
                          <label htmlFor="">Match Location</label>
                          <NewMap locationCoordinate={locationCoordinate} />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                {filteredData?.length !== 0 ? (
                  <div className="d-flex justify-content-between flex-wrap ">
                    {filteredData.map((item, indx) => (
                      <div
                        className="card"
                        style={{
                          marginBottom: "40px",
                          width: "100%",
                          cursor: "pointer",
                        }}
                        id={`dynamicCard${indx}`}
                        onClick={() => setSelectedSpace(item?._id)}
                      >
                        <div className="row ">
                          <div className="col-md-4">
                            <img
                              alt=""
                              src={
                                `${base_URL}/file/images/` +
                                item.propertyImage[0]
                              }
                              className="card-img-top"
                              style={{ objectFit: "cover" }}
                            />
                            <ul className="list-unstyled pt-2 pl-2">
                              <li className="font-weight-bold">
                                <h5 style={{ color: "#1D6ABA" }}>
                                  {item?.pricing?.weekly ? (
                                    <>
                                      {item?.pricing?.weekly}${" "}
                                      <sub>per week</sub>
                                    </>
                                  ) : item?.pricing?.monthly ? (
                                    <>
                                      {item?.pricing?.monthly}${" "}
                                      <sub>per month</sub>
                                    </>
                                  ) : (
                                    <>
                                      {item?.pricing?.yearly}${" "}
                                      <sub>per year</sub>
                                    </>
                                  )}
                                </h5>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-8 px-3">
                            <div className="card-block px-3">
                              <h5 className="card-title pt-3 text-capitalize">
                                <b>{item.title}</b>
                                <p style={{ fontSize: "15px" }}>
                                  <GoLocation /> {item?.address?.location}
                                </p>
                              </h5>
                              <ul className="list-unstyled text-left">
                                <li className="font-weight-bold text-capitalize">
                                  <FaAngleRight /> Storage Type:{" "}
                                  <b style={{ color: "#616161" }}>
                                    {item.typeOfStorage}
                                  </b>
                                </li>
                                <li className="font-weight-bold text-capitalize">
                                  <FaAngleRight /> Listing Type:{" "}
                                  <b style={{ color: "#616161" }}>
                                    {" "}
                                    {item.typeOfListing}
                                  </b>
                                </li>
                                <li className="font-weight-bold text-capitalize">
                                  <FaAngleRight /> Size:&nbsp;&nbsp;
                                  <b style={{ color: "#616161" }}>
                                    {" "}
                                    {item.size}
                                    &nbsp;{item.unit}
                                  </b>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    {/* <PaginationFunction
                  postPerPage={postPerPage}
                  totalPost={listingGrid.length}
                  paginate={paginateFunction}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                /> */}
                  </div>
                ) : (
                  <>
                    <div className="text-center">
                      <h4>
                        "No results", keep searching with other locations.
                      </h4>
                    </div>
                  </>
                )}
              </>
            </div>
          </div>
        </div>
        <Loader loader={loader} />
      </section>
    </>
  );
};

const mapStateToProps = (state) => ({
  getAllProperty: state.getAllProperty.getAllProperty,
  allSpaceRequest: state.allSpaceRequest.allSpaceRequest,
  allBoxRequest: state.allBoxRequest.allBoxRequest,
  refresh: state.refresh.refresh.refresh,
  refreshFunction: state.refresh.refresh.refreshFunction,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpaceBoxRequestDetail);
