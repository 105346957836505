import React, { useState, useEffect } from "react";
import { Input } from "antd";
// import PP from "../images/pp.jpg";
import { Link } from "react-router-dom";
// import { FaArrowAltCircleRight, FaEdit, FaStar } from "react-icons/fa";
// import { MdDeleteForever } from "react-icons/md";
import HostSidebar from "./HostSidebar";
import PaginationFunction from "../Pagination/Pagination";
import SyncLoader from "react-spinners/SyncLoader";
import { connect } from "react-redux";
import notify from "../Notify/notify";
import { create, read } from "../Axios/axiosService";
import $ from "jquery";

const MyListings = (props) => {
  const [loading] = useState(false);
  const [data, setData] = useState([]);
  const [reqQuery, setReqQuery] = useState([]);
  const [state, setState] = useState(false);

  useEffect(() => {
    read("/api/property/getboxrequestproperty")
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => console.log(err));
  }, [state]);

  useEffect(() => {
    create("/api/property/getboxquery", {})
      .then((res) => setReqQuery(res.data))
      .catch((err) => console.log(err));
  }, [state]);

  const acceptRequest = (ID) => {
    create("/api/property/acceptboxrequest", {
      requestId: ID,
    })
      .then((res) => {
        $(`#acceptReservation-${ID}`).modal("hide");

        notify.showSuccess("Your Request is accepted Successfully");

        setState(!state);
      })
      .catch((err) => {
        console.log(err);
        notify.handleError("Try again");
      });
  };
  const rejectRequest = (ID, Pid) => {
    create("/api/property/rejectboxrequest", {
      requestId: ID,
      propertyId: Pid,
    })
      .then((res) => {
        setState(!state);
        $(`#rejectReservation-${ID}`).modal("hide");

        notify.showSuccess("Your Request is Rejected");
      })
      .catch((err) => {
        console.log(err);
        notify.handleError("Try again");
      });
  };

  let AllReqId = [];
  for (let index = 0; index < data.length; index++) {
    data[index].boxId.forEach((da) => AllReqId.push(da));
  }
  let uniqueID = AllReqId.filter(function (elem, index, self) {
    return index === self.indexOf(elem);
  });
  const requestArray = reqQuery
    .filter((arg, i) => uniqueID.includes(arg._id))
    .slice()
    .reverse();

  const filterByTag = (list, searchTag) => {
    if (searchTag && searchTag !== "all") {
      return list.filter((listItem) => listItem.booking_status === searchTag);
    }
    return list;
  };

  const countByTag = (list, searchTag) => {
    if (searchTag && searchTag !== "all") {
      return list.filter((listItem) => listItem.booking_status === searchTag)
        .length;
    }
    return list.length;
  };

  const listingData = props.ownerProperty;

  const { Search } = Input;
  const [searchterm, setSearchTerm] = useState("");
  const filterData = requestArray?.filter((item) =>
    searchterm === ""
      ? item
      : item.location_of_interest
          .toLowerCase()
          .includes(searchterm.toLowerCase()) ||
        item.storing_item.toLowerCase().includes(searchterm.toLowerCase())
  );

  const [filter, setFilter] = useState("all");

  const [currentPage, setCurrentPage] = useState(parseInt("1"));
  const [postPerPage] = useState(parseInt("6"));
  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const currentListing = filterByTag(filterData, filter).slice(
    indexOfFirstPost,
    indexOfLastPost
  );

  const paginateFunction = (pageNo) => {
    setCurrentPage(pageNo);
  };
  return (
    <>
      <section id="main-dashboard">
        <div className="sidebar-row">
          <div className="sidebar-container">
            <HostSidebar />
          </div>

          <div className="dashboard-content">
            <div className="dashboard-title">
              <h5>Dashboard | Box Requests</h5>
            </div>
            {loading ? (
              <>
                {" "}
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "80vh", top: "50%" }}
                >
                  <SyncLoader size={18} color="#1d6aba" margin="2" />
                </div>
              </>
            ) : (
              <div className="dashboard-second animate__animated animate__fadeInUp">
                <h5 className="p-3">Manage Your Box Requests</h5>

                <div className="dashboard-second-title d-flex flex-wrap justify-content-between">
                  <div className="more-buttons col-lg-6 col-md-12"></div>
                  <div className="search-bar col-lg-6 col-md-12 mt-4 mt-lg-0">
                    <Search
                      placeholder="Search Listing"
                      allowClear
                      enterButton
                      size="large"
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        setSearchTerm(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className=" table-responsive">
                  {currentListing.length > 0 ? (
                    <>
                      <table className="table  table-hover text-center table-striped">
                        <thead>
                          <tr>
                            <th scope="col">S.N.</th>
                            <th scope="col">Location</th>
                            <th scope="col">Storage Item</th>
                            <th scope="col">Listing Type</th>
                            <th scope="col">Plan Type</th>
                            <th scope="col">Price</th>
                            <th scope="col">Status</th>
                            <th scope="col">Actions</th>
                          </tr>
                        </thead>
                        {currentListing.map((item, indx) => (
                          <>
                            <tbody key={indx}>
                              <tr>
                                <th scope="row">{indx + 1}.</th>
                                <td>
                                  <small>{item?.location_of_interest}</small>
                                </td>
                                <td style={{ textTransform: "capitalize" }}>
                                  <Link>
                                    <> {item?.storing_item}</>
                                  </Link>
                                </td>
                                <td style={{ textTransform: "capitalize" }}>
                                  <small>{item?.box_size}</small>{" "}
                                </td>
                                <td>{item?.plan_type}</td>
                                <td>${item?.price}</td>
                                <td>
                                  {" "}
                                  {item?.isAssigned === true ? (
                                    <h5 className="new">Accepted Request</h5>
                                  ) : (
                                    <h5 className="pending">Pending</h5>
                                  )}
                                </td>
                                <td>
                                  {item.isAssigned === false ? (
                                    <>
                                      <div className="d-flex justify-content-around">
                                        <button
                                          onClick={() =>
                                            acceptRequest(item?._id)
                                          }
                                          className="manage-button"
                                          // data-toggle="modal"
                                          // data-target={`#acceptReservation-${item._id}`}
                                        >
                                          Accept
                                        </button>
                                        <button
                                          onClick={() =>
                                            rejectRequest(
                                              item?._id,
                                              item?.assigned_property
                                            )
                                          }
                                          className="manage-button"
                                          // data-toggle="modal"
                                          // data-target={`#rejectReservation-${item._id}`}
                                        >
                                          Reject
                                        </button>
                                      </div>
                                    </>
                                  ) : null}
                                </td>{" "}
                              </tr>
                            </tbody>
                            {/* Request confirm Modal body */}
                            <div
                              className="modal fade"
                              id={`#acceptReservation-${item._id}`}
                              tabindex="-1"
                              role="dialog"
                              aria-labelledby="acceptReservation"
                              aria-hidden="true"
                            >
                              <div
                                className="modal-dialog modal-dialog-centered"
                                role="document"
                              >
                                <div className="modal-content">
                                  <div className="modal-header text-center ">
                                    <h5 className="modal-title ml-auto">
                                      Are you sure want to confirm this
                                      Reservation?
                                    </h5>
                                    <button
                                      type="button"
                                      className="close"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                  <div className="modal-footer  justify-content-center">
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      onClick={() => acceptRequest(item?._id)}
                                    >
                                      Yes
                                    </button>

                                    <button
                                      type="button"
                                      className="btn btn-secondary"
                                      data-dismiss="modal"
                                    >
                                      No
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* Request reject Modal body */}
                            <div
                              className="modal fade"
                              id={`#rejectReservation-${item._id}`}
                              tabindex="-1"
                              role="dialog"
                              aria-labelledby="declineReservation"
                              aria-hidden="true"
                            >
                              <div
                                className="modal-dialog modal-dialog-centered"
                                role="document"
                              >
                                <div className="modal-content">
                                  <div className="modal-header text-center ">
                                    <h5 className="modal-title ml-auto">
                                      Are you sure want to Decine this
                                      Reservation Request?
                                    </h5>
                                    <button
                                      type="button"
                                      className="close"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                  <div className="modal-footer  justify-content-center">
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      onClick={() =>
                                        rejectRequest(
                                          item?._id,
                                          item?.assigned_property
                                        )
                                      }
                                    >
                                      Yes
                                    </button>

                                    <button
                                      type="button"
                                      className="btn btn-secondary"
                                      data-dismiss="modal"
                                    >
                                      No
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                      </table>

                      <PaginationFunction
                        postPerPage={postPerPage}
                        totalPost={requestArray?.length}
                        paginate={paginateFunction}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                      />
                    </>
                  ) : (
                    <h3 className="text-center p-4">
                      {" "}
                      No reservation property available
                    </h3>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => ({
  ownerProperty: state.ownerProperty.ownerProperty,
  ownerFeatureProperty: state.ownerFeaturedProperty.ownerFeaturedProperty,
  refresh: state.refresh.refresh.refresh,
  refreshFunction: state.refresh.refresh.refreshFunction,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MyListings);
