import React, { useState } from "react";
import { Link } from "react-router-dom";
import PriceSlider from "./PriceSlider";
import { FaAngleRight } from "react-icons/fa";
import { GoLocation } from "react-icons/go";
import Autocomplete2 from "../Map/AutoComplete2";
import { connect } from "react-redux";
import NewMap from "../Map/NewMap";
import { base_URL } from "../Axios/baseURL";

const SearchListing = (props) => {
  //const [currentPage, setCurrentPage] = useState(parseInt("1"));
  //const [postPerPage] = useState(parseInt("15"));
  const [listingType, setListingType] = useState("");
  const [storageType, setStorageType] = useState("");

  const [location, setLocation] = useState("");
  const [coordinate, setCoordinate] = useState({});

  const [priceSearch, setPriceSearch] = useState([0, 10000]);
  const [weekly, setWeekly] = useState(false);
  const [monthly, setMonthly] = useState(false);
  const [yearly, setYearly] = useState(false);

  const locationCoordinate = (coordinate, location) => {
    setCoordinate(coordinate);
    setLocation(location);
  };

  //const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const listingGrid = props?.getAllProperty
    ?.filter(
      (arg) => arg.booking_status === "available" && arg?.isVerified === true
    )
    .reverse();

  // Filter function
  let filteredData = listingGrid;
  // Storage type
  if (storageType.length > 0) {
    filteredData = filteredData.filter((val) =>
      val.typeOfStorage.includes(storageType)
    );
  }
  // listening type
  if (listingType.length > 0) {
    filteredData = filteredData.filter((val) =>
      val.typeOfListing.includes(listingType)
    );
  }

  if (location) {
    filteredData = filteredData.filter((arg) =>
      arg?.address?.location?.toLowerCase().includes(location.toLowerCase())
    );
  }

  if (priceSearch[0] !== 0 || priceSearch[1] !== 10000) {
    filteredData = filteredData?.filter(
      (val) =>
        (val.pricing.weekly > priceSearch[0] &&
          val.pricing.weekly < priceSearch[1]) ||
        (val.pricing.monthly > priceSearch[0] &&
          val.pricing.monthly < priceSearch[1]) ||
        (val.pricing.yearly > priceSearch[0] &&
          val.pricing.yearly < priceSearch[1])
    );
  }
  if (weekly === true) {
    filteredData = filteredData?.filter(
      (val) => val.planType.weeklyPlan === "available"
    );
  }
  if (monthly === true) {
    filteredData = filteredData?.filter(
      (val) => val.planType.monthlyPlan === "available"
    );
  }
  if (yearly === true) {
    filteredData = filteredData?.filter(
      (val) => val.planType.yearlyPlan === "available"
    );
  }

  //const indexOfLastPost = currentPage * postPerPage;
  //const indexOfFirstPost = indexOfLastPost - postPerPage;
  //const currentListing = filteredData.slice(indexOfFirstPost, indexOfLastPost);
  //const paginateFunction = (pageNo) => {
  //  setCurrentPage(pageNo);
  //};
  return (
    <>
      <section id="listing-grid">
        <div className="listing-grid">
          {/* // data-aos="fade-right" // data-aos-duration="1000" d-none d-lg-block  */}

          {props?.location?.state ? (
            <div className="half-map col-lg-6 animate__animated animate__fadeInLeft">
              <Autocomplete2
                listingGrid={props?.location?.state?.result?.filteredData?.filter(
                  (arg) => arg.booking_status === "available"
                )}
              />
            </div>
          ) : (
            <div className="half-map col-lg-6 animate__animated animate__fadeInLeft">
              <Autocomplete2 listingGrid={listingGrid} />
            </div>
          )}
          <div className="half-grid col-lg-5 col-md-12 order-sm-1 col-sm-12 animate__animated animate__fadeInRight">
            <div className="search-section">
              {props?.location?.state ? (
                <>
                  {props?.location?.state?.result?.filteredData?.length !==
                  0 ? (
                    <>
                      <h4 className="text-center">
                        {" "}
                        <i>Your Search results are:</i>
                      </h4>

                      {props?.location?.state?.result?.filteredData
                        ?.filter((arg) => arg.booking_status === "available")
                        .map((item, indx) => {
                          return (
                            <div
                              className="card"
                              style={{ marginBottom: "10px", width: "100%" }}
                              id={`dynamicCard${indx}`}
                            >
                              <div className="row ">
                                <div className="col-md-4">
                                  <img
                                    alt=""
                                    src={
                                      `${base_URL}/file/images/` +
                                      item.propertyImage[0]
                                    }
                                    className="card-img-top"
                                    style={{ objectFit: "cover" }}
                                  />
                                  <ul className="list-unstyled pt-2 pl-2">
                                    <li className="font-weight-bold">
                                      <h5 style={{ color: "#1D6ABA" }}>
                                        {item?.pricing?.weekly ? (
                                          <>
                                            {item?.pricing?.weekly}${" "}
                                            <sub>per week</sub>
                                          </>
                                        ) : item?.pricing?.monthly ? (
                                          <>
                                            {item?.pricing?.monthly}${" "}
                                            <sub>per month</sub>
                                          </>
                                        ) : (
                                          <>
                                            {item?.pricing?.yearly}${" "}
                                            <sub>per year</sub>
                                          </>
                                        )}
                                      </h5>
                                    </li>
                                  </ul>
                                </div>
                                <div className="col-md-8 px-3">
                                  <div className="card-block px-3">
                                    <h5 className="card-title pt-3 text-capitalize">
                                      <b>
                                        <Link
                                          to={{
                                            pathname: `/list-details/${item._id}`,
                                          }}
                                        >
                                          {item.title}
                                        </Link>
                                      </b>
                                      <p style={{ fontSize: "15px" }}>
                                        <GoLocation /> {item?.address?.location}
                                      </p>
                                    </h5>
                                    <ul className="list-unstyled text-left">
                                      <li className="font-weight-bold text-capitalize">
                                        <FaAngleRight /> Storage Type:{" "}
                                        <b style={{ color: "#616161" }}>
                                          {item.typeOfStorage}
                                        </b>
                                      </li>
                                      <li className="font-weight-bold text-capitalize">
                                        <FaAngleRight /> Listing Type:{" "}
                                        <b style={{ color: "#616161" }}>
                                          {" "}
                                          {item.typeOfListing}
                                        </b>
                                      </li>
                                      <li className="font-weight-bold text-capitalize">
                                        <FaAngleRight /> Size:&nbsp;&nbsp;
                                        <b style={{ color: "#616161" }}>
                                          {" "}
                                          {item.size}
                                          &nbsp;{item.unit}
                                        </b>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </>
                  ) : (
                    <h3 className="text-center">
                      Sorry, No results. <br /> <br />
                      <br /> <br />
                      {/* <Link
                        to="/search-location"
                        className="signupbtnLogin1"
                        style={{ height: "min-content" }}
                      >
                        <span>Back to Home Page</span>{" "}
                      </Link> */}
                    </h3>
                  )}
                </>
              ) : (
                <>
                  <div
                    className="search-filter"
                    // data-aos="fade-left"
                    // data-aos-duration="1000"
                  >
                    <form>
                      <div className="row">
                        <div className="col-lg-6 col-md-12">
                          <div className="form-outline mb-3">
                            <NewMap locationCoordinate={locationCoordinate} />
                          </div>
                        </div>
                        <div className="col mb-3">
                          <div className="form-outline">
                            <select
                              id="listing-type"
                              className="form-control"
                              onClick={(e) => setListingType(e.target.value)}
                            >
                              <option value="">Type of Listing</option>
                              <option value="small room">Small Room</option>
                              <option value="small space">Small Space</option>
                              <option value="closet space">Closet Space</option>
                              <option value="large space">Large Space</option>
                              <option value="others">Others</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="row mb-1">
                        <div className="col-lg-5 col-md-12">
                          <div className="form-outline">
                            <select
                              id="storage-type"
                              className="form-control"
                              onClick={(e) => setStorageType(e.target.value)}
                            >
                              <option value="">Type of Storage</option>
                              <option value="small personal items">
                                Small Personal Items
                              </option>
                              <option value="small household items">
                                Small Household Items
                              </option>
                              <option value="large household items">
                                Large Household Items
                              </option>
                              <option value="vehicles">Vehicles</option>
                              <option value="others">Others</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-outline">
                            <PriceSlider setPriceSearch={setPriceSearch} />
                          </div>
                        </div>
                      </div>

                      <div
                        className="row form-check mb-3"
                        style={{ marginLeft: "-10px" }}
                      >
                        <label className="form-label">Choose Plan Type:</label>{" "}
                        {}
                        <br />
                        <div className="form-check form-check-inline">
                          <input
                            id="weeklyPlan"
                            className="form-check-input"
                            type="checkbox"
                            name="weeklyPlan"
                            onClick={() => {
                              setWeekly(!weekly);
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="weeklyPlan"
                          >
                            Weekly
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            id="monthlyPlan"
                            className="form-check-input"
                            type="checkbox"
                            name="monthlyPlan"
                            onClick={() => setMonthly(!monthly)}
                          />
                          <label
                            htmlFor="monthlyPlan"
                            className="form-check-label"
                          >
                            Monthly
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            id="yearlyPlan"
                            type="checkbox"
                            name="yearlyPlan"
                            onClick={() => setYearly(!yearly)}
                          />

                          <label
                            htmlFor="yearlyPlan"
                            className="form-check-label"
                          >
                            Yearly
                          </label>
                        </div>
                      </div>
                    </form>
                  </div>
                  {filteredData?.length !== 0 ? (
                    <div className="d-flex justify-content-between flex-wrap ">
                      {filteredData.map((item, indx) => (
                        <div
                          className="card"
                          style={{ marginBottom: "40px", width: "100%" }}
                          id={`dynamicCard${indx}`}
                        >
                          <div className="row ">
                            <div className="col-md-4">
                              <img
                                alt=""
                                src={
                                  `${base_URL}/file/images/` +
                                  item.propertyImage[0]
                                }
                                className="card-img-top"
                                style={{ objectFit: "cover" }}
                              />
                              <ul className="list-unstyled pt-2 pl-2">
                                <li className="font-weight-bold">
                                  <h5 style={{ color: "#1D6ABA" }}>
                                    {item?.pricing?.weekly ? (
                                      <>
                                        {item?.pricing?.weekly}${" "}
                                        <sub>per week</sub>
                                      </>
                                    ) : item?.pricing?.monthly ? (
                                      <>
                                        {item?.pricing?.monthly}${" "}
                                        <sub>per month</sub>
                                      </>
                                    ) : (
                                      <>
                                        {item?.pricing?.yearly}${" "}
                                        <sub>per year</sub>
                                      </>
                                    )}
                                  </h5>
                                </li>
                              </ul>
                            </div>
                            <div className="col-md-8 px-3">
                              <div className="card-block px-3">
                                <h5 className="card-title pt-3 text-capitalize">
                                  <b>
                                    <Link
                                      to={{
                                        pathname: `/list-details/${item._id}`,
                                      }}
                                    >
                                      {item.title}
                                    </Link>
                                  </b>
                                  <p style={{ fontSize: "15px" }}>
                                    <GoLocation /> {item?.address?.location}
                                  </p>
                                </h5>
                                <ul className="list-unstyled text-left">
                                  <li className="font-weight-bold text-capitalize">
                                    <FaAngleRight /> Storage Type:{" "}
                                    <b style={{ color: "#616161" }}>
                                      {item.typeOfStorage}
                                    </b>
                                  </li>
                                  <li className="font-weight-bold text-capitalize">
                                    <FaAngleRight /> Listing Type:{" "}
                                    <b style={{ color: "#616161" }}>
                                      {" "}
                                      {item.typeOfListing}
                                    </b>
                                  </li>
                                  <li className="font-weight-bold text-capitalize">
                                    <FaAngleRight /> Size:&nbsp;&nbsp;
                                    <b style={{ color: "#616161" }}>
                                      {" "}
                                      {item.size}
                                      &nbsp;{item.unit}
                                    </b>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      {/* <PaginationFunction
                  postPerPage={postPerPage}
                  totalPost={listingGrid.length}
                  paginate={paginateFunction}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                /> */}
                    </div>
                  ) : (
                    <>
                      <div className="text-center">
                        <h4>"No results"</h4>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </section>
      {/* )} */}
    </>
  );
};
const mapStateToProps = (state) => ({
  getAllProperty: state.getAllProperty.getAllProperty,
  refresh: state.refresh.refresh.refresh,
  refreshFunction: state.refresh.refresh.refreshFunction,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SearchListing);
