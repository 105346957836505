import { ALL_BOOKED_PROPERTY } from "../action/action";

const initialState = { allBookedProperty: [] };
export const allBookedPropertyReducer = (state = initialState, action) => {
  switch (action.type) {
  case ALL_BOOKED_PROPERTY:
    return { ...state, allBookedProperty: action.payload };

  default:
    return { ...state };
  }
};
