import ImageGallery from "react-image-gallery";
import React, { useEffect, useState } from "react";
import { read } from "../Axios/axiosService";
import { useParams } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
import { base_URL } from "../Axios/baseURL";

const MyComponent = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  const [listingDetail, setListingDetail] = useState([]);

  useEffect(() => {
    const getListingDetail = () => {
      read("/api/getallproperty")
        .then((res) => {
          setListingDetail(res.data);
          setLoading(false);
        })
        .catch((err) => {
          if (err.response) {
            console.log(err);
          }
        });
    };

    getListingDetail();
  }, []);

  const selectedData = listingDetail.filter((arg) => arg._id === id)[0];
  // console.log(selectedData);
  const images = selectedData
    ? selectedData?.propertyImage?.map((e) => {
        return {
          original:`${base_URL}/file/images/` + e,
          thumbnail: `${base_URL}/file/images/` + e,
        };
      })
    : [
        {
          original:
            "https://www.staticwhich.co.uk/static/images/products/no-image/no-image-available.png",
          thumbnail:
            "https://www.staticwhich.co.uk/static/images/products/no-image/no-image-available.png",
        },
      ];

  // console.log(images);
  return (
    <>
      {/* {loading ? (y
        <>
          {" "}
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "80vh", top: "50%" }}
          >
            <SyncLoader size={18} color="#1d6aba" margin="2" />
          </div>
        </>
      ) : ( */}
      <div>
        <ImageGallery items={images} />
      </div>
      {/* )} */}
    </>
  );
};

export default MyComponent;
