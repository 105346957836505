import React, { useState } from "react";
import { Input } from "antd";
import { Link } from "react-router-dom";
import RenterSidebar from "./RenterSidebar";
import { connect } from "react-redux";
import PaginationFunction from "../Pagination/Pagination";
import SyncLoader from "react-spinners/SyncLoader";

const RenterFavourite = (props) => {
  const [loading] = useState(false);
  const [currentPage, setCurrentPage] = useState(parseInt("1"));
  const [postPerPage] = useState(parseInt("6"));

  const featuredData = props?.allFeaturedProperty?.property?.slice()?.reverse();

  const { Search } = Input;
  const [searchterm, setSearchTerm] = useState("");
  const filterData = featuredData?.filter((item) =>
    searchterm === ""
      ? item
      : item?.title?.toLowerCase()?.includes(searchterm.toLowerCase()) ||
        item?.typeOfListing
          ?.toLowerCase()
          ?.includes(searchterm.toLowerCase()) ||
        item?.typeOfStorage?.toLowerCase()?.includes(searchterm.toLowerCase())
  );
  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const currentListing = filterData?.slice(indexOfFirstPost, indexOfLastPost);

  const paginateFunction = (pageNo) => {
    setCurrentPage(pageNo);
  };
  return (
    <>
      <section id="main-dashboard">
        <div className="sidebar-row">
          <div className="sidebar-container">
            <RenterSidebar />
          </div>

          <div className="dashboard-content">
            <div className="dashboard-title">
              <h5>Dashboard | Featured</h5>
            </div>
            {loading ? (
              <>
                {" "}
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "80vh", top: "50%" }}
                >
                  <SyncLoader size={18} color="#1d6aba" margin="2" />
                </div>
              </>
            ) : (
              <div className="dashboard-second  animate__animated animate__fadeInUp">
                <div className="topbar d-flex justify-content-between flex-wrap mt-4 mb-4">
                  <h5 className="pl-3 mb-4 col-lg-6 col-md-12">
                    Top Featured Listings
                  </h5>
                  <div className="search-bar col-lg-6 col-md-12">
                    <Search
                      placeholder="Search Listing"
                      allowClear
                      enterButton
                      size="large"
                      onChange={(e) => {
                        setSearchTerm(e.target.value);
                      }}
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>

                <div className="mylistings table-responsive">
                  {currentListing?.length > 0 ? (
                    <>
                      <table className="table  table-hover table-striped text-center text-capitalize">
                        <thead>
                          <tr>
                            <th scope="col">S.N.</th>
                            <th scope="col">Title</th>
                            <th scope="col">Storage Tpe</th>
                            <th scope="col">Listing Type</th>
                            <th scope="col">Plan Type</th>
                            <th scope="col">Price</th>
                          </tr>
                        </thead>

                        {currentListing.map((item, index) => (
                          <>
                            <tbody>
                              <tr>
                                <th scope="row">
                                  {indexOfFirstPost + index + 1}
                                </th>
                                <td>
                                  <Link
                                    to={{
                                      pathname: `/list-details/${item?._id}`,
                                    }}
                                  >
                                    <b style={{ color: "#1D6ABA" }}>
                                      {item?.title}
                                    </b>
                                  </Link>
                                  <br />
                                  <small> {item?.address?.location}</small>
                                </td>
                                <td style={{ textTransform: "capitalize" }}>
                                  <small> {item?.typeOfStorage}</small>
                                </td>
                                <td style={{ textTransform: "capitalize" }}>
                                  <small>{item?.typeOfListing}</small>{" "}
                                </td>
                                <td>
                                  <ul className="list-unstyled">
                                    <li
                                      style={{
                                        color: "#1d6aba",
                                      }}
                                    >
                                      <small>
                                        {item?.planType?.weeklyPlan ===
                                        "available"
                                          ? "WeeklyPlan"
                                          : null}
                                      </small>
                                    </li>
                                    <li
                                      style={{
                                        color: "#009933",
                                      }}
                                    >
                                      <small>
                                        {" "}
                                        {item?.planType?.monthlyPlan ===
                                        "available"
                                          ? "MonthlyPlan"
                                          : null}
                                      </small>
                                    </li>
                                    <li
                                      style={{
                                        color: "#ee00ff",
                                      }}
                                    >
                                      <small>
                                        {item?.planType?.yearlyPlan ===
                                        "available"
                                          ? "YearlyPlan"
                                          : null}
                                      </small>
                                    </li>
                                  </ul>
                                </td>
                                <td>
                                  <ul className="list-unstyled ">
                                    <li
                                      style={{
                                        color: "#1d6aba",
                                      }}
                                    >
                                      <small>
                                        {item?.planType?.weeklyPlan ===
                                        "available" ? (
                                          <>{item?.pricing?.weekly}$</>
                                        ) : null}
                                      </small>
                                    </li>
                                    <li
                                      style={{
                                        color: "#009933",
                                      }}
                                    >
                                      <small>
                                        {item?.planType?.monthlyPlan ===
                                        "available" ? (
                                          <>{item?.pricing?.monthly}$</>
                                        ) : null}
                                      </small>
                                    </li>
                                    <li
                                      style={{
                                        color: "#ee00ff",
                                      }}
                                    >
                                      <small>
                                        {item?.planType?.yearlyPlan ===
                                        "available" ? (
                                          <>{item?.pricing?.yearly}$</>
                                        ) : null}
                                      </small>
                                    </li>
                                  </ul>
                                </td>
                                <td>
                                  {" "}
                                  {/*<Tooltip title="View Details">
                                <Link
                                  to={{
                                    pathname: `/list-details/${item?._id}`,
                                  }}
                                >
                                  <button className="manage-button">
                                    {" "}
                                    <FaArrowAltCircleRight className="icon" />{" "}
                                  </button>
                                </Link>
                                </Tooltip>*/}
                                </td>
                              </tr>
                            </tbody>
                          </>
                        ))}
                      </table>
                      <PaginationFunction
                        postPerPage={postPerPage}
                        totalPost={featuredData?.length}
                        paginate={paginateFunction}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                      />
                    </>
                  ) : (
                    <h4 className="text-center p-4">No Results.</h4>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => ({
  renterBookedProperty: state.renterBookedProperty.renterBookedProperty,
  renterFavouritesProperty:
    state.renterFavouritesProperty.renterFavouritesProperty,
  allFeaturedProperty: state.allFeaturedProperty.allFeaturedProperty,
  refresh: state.refresh.refresh.refresh,
  refreshFunction: state.refresh.refresh.refreshFunction,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(RenterFavourite);
