import React, { useRef, useState } from "react";
// import { FcGoogle } from "react-icons/fc";
// import { FaFacebookF } from "react-icons/fa";
import { FiAlertTriangle } from "react-icons/fi";
import LoginImg from "../images/login.png";
import Ready from "../Public/Ready";
import Footer from "../Public/Footer";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import axios from "axios";
import notify from "../Notify/notify";
import Loader from "./../service/loader/loader";
import { base_URL } from "../Axios/baseURL";

const HostSignUp = (props) => {
  const [loader, setLoader] = useState(false);
  const [termsAgreed, setTermsAgreed] = useState(false)
  const { register, handleSubmit, errors, watch } = useForm();

  const password = useRef({});
  password.current = watch("password", "");

  const onSubmit = (data) => {
    setLoader(true);
    data.name = data.firstName + " " + data.lastName;

    const myData = {
      fullName: data.name,
      email: data.email,
      address: data.address,
      password: data.password,     
      gender: data.gender,
      contactNo: data.phoneNumber,
    };

    let finalData = new FormData();
    for (const key of Object.keys(myData)) {
      // if (key === "profileImage") {
      //   finalData.append("profileImage", myData.profileImage);
      // } else {
      finalData.append(key, myData[key]);
      // }
    }
    if(!termsAgreed) {
      notify.handleError('Please agree to Terms and Conditions.')
      setLoader(false);
      return
    }

    axios({
      method: "post",
      url: `${base_URL}/api/auth/owner/register`,
      data: finalData,
    })
      // .post("http://157.245.108.131:8080/api/auth/owner/register", {
      //   data: finalData,
      // })
      .then(function (response) {
        setLoader(false);
        props.history.push("/login");
        notify.showSuccess(response.data.message);
      })
      .catch((err) => {
        console.log(err);
        notify.handleError(err.response.data.error);
        setLoader(false);
      });
  };
  const getDimension = async (file) => {
    let reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException("Problem parsing input file."));
      };

      reader.onload = () => {
        var image = new Image();
        image.src = reader.result;
        image.onload = function () {
          resolve({ width: this.width, height: this.height });
        };
      };
      reader.readAsDataURL(file);
    });
  };
  return (
    <>
      <section id="login-page">
        <div className="row login-main">
          <div className="col-lg-7 col-md-12 animate__animated animate__fadeInLeft">
            <div className="login-page">
              <h1 className="mb-4 text-center">BECOME A HOST</h1>
              <form className="form-group " onSubmit={handleSubmit(onSubmit)}>
                <div className="row mb-3">
                  <div className="col-md-6 col-sm-12 mb-3 mb-md-0">
                    <input
                      type="text"
                      className={classNames("form-control", {
                        "is-invalid": errors.firstName,
                      })}
                      name="firstName"
                      id="firstName"
                      // value={signup.firstName}
                      placeholder="First Name"
                      data-toggle="tooltip"
                      ref={register({
                        required: "Required",
                        minLength: {
                          value: 2,
                          message: "Enter Real Name",
                        },
                      })}
                    />
                    {errors.firstName ? (
                      <p style={{ color: "red", marginBottom: "-10px" }}>
                        <FiAlertTriangle /> {errors.firstName.message}
                      </p>
                    ) : null}
                  </div>
                  <div className="col">
                    <input
                      type="text"
                      className={classNames("form-control", {
                        "is-invalid": errors.lastName,
                      })}
                      name="lastName"
                      // value={signup.lastName}
                      id="lastName"
                      placeholder="Last Name"
                      ref={register({
                        required: "Required",
                        minLength: {
                          value: 2,
                          message: "Enter Real Name",
                        },
                      })}
                    />
                    {errors.lastName ? (
                      <p style={{ color: "red", marginBottom: "-10px" }}>
                        <FiAlertTriangle /> {errors.lastName.message}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="mb-3 form-group">
                  <input
                    type="email"
                    className={classNames("form-control", {
                      "is-invalid": errors.email,
                    })}
                    name="email"
                    id="email"
                    placeholder="Email"
                    ref={register({
                      required: "Required",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Invalid email address",
                      },
                    })}
                  />
                  {errors.email ? (
                    <p style={{ color: "red", marginBottom: "-10px" }}>
                      <FiAlertTriangle /> {errors.email.message}
                    </p>
                  ) : null}
                </div>
                <div className="row mb-lg-3 mb-0">
                  <div className="col-md-6 col-sm-12 mb-3 mb-md-0">
                    <input
                      type="text"
                      className={classNames("form-control", {
                        "is-invalid": errors.phoneNumber,
                      })}
                      name="phoneNumber"
                      id="phoneNumber"
                      placeholder="Phone Number"
                      ref={register({
                        required: "Required",
                        // pattern: {
                        //   value: /^\d{10}$/,
                        //   message: "Enter 10 Digit  Number ",
                        // },
                      })}
                    />
                    {errors.phoneNumber ? (
                      <p style={{ color: "red", marginBottom: "-10px" }}>
                        <FiAlertTriangle /> {errors.phoneNumber.message}
                      </p>
                    ) : null}
                  </div>
                  <div className="col-md-6 col-sm-12 mb-3 mb-md-0">
                    <select
                      id="gender"
                      className={classNames("form-control", {
                        "is-invalid": errors.gender,
                      })}
                      ref={register({
                        required: "Required",
                      })}
                      name="gender"
                    >
                      <option value="">Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </select>
                    {errors.gender ? (
                      <p style={{ color: "red", marginBottom: "-10px" }}>
                        <FiAlertTriangle /> {errors.gender.message}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="form-group mb-3 mt-3">
                  <input
                    type="text"
                    className={classNames("form-control", {
                      "is-invalid": errors.address,
                    })}
                    name="address"
                    id="address"
                    placeholder="Your Current Address"
                    ref={register({
                      required: "Required",
                    })}
                  />
                  {errors.address ? (
                    <p style={{ color: "red", marginBottom: "-10px" }}>
                      <FiAlertTriangle /> {errors.address.message}
                    </p>
                  ) : null}
                </div>


                <small id="emailHelp" className="form-text text-muted">
                  Password must contain (UPPERCASE,LOWERCASE, NUMBER AND A
                  SPECIAL CHARACTER)
                </small>
                <div className="row form-group">
                  <div className="col-md-6 col-sm-12 mb-3 mb-md-0">
                    <input
                      type="password"
                      className={classNames("form-control", {
                        "is-invalid": errors.password,
                      })}
                      name="password"
                      id="password"
                      placeholder="Password"
                      ref={register({
                        required: true,
                        minLength: {
                          value: 8,
                          message: "Password must be 8 chars",
                        },
                        validate: (value) => {
                          return [
                            /[a-z]/,
                            /[A-Z]/,
                            /[0-9]/,
                            /[^a-zA-Z)-9]/,
                          ].every((pattern) => pattern.test(value));
                        },
                      })}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 mb-3 mb-md-0">
                    <input
                      type="password"
                      className={classNames("form-control", {
                        "is-invalid": errors.cpassword,
                      })}
                      name="cpassword"
                      // value={signup.cpassword}
                      id="cpassword"
                      placeholder="Confirm Password"
                      ref={register({
                        validate: (value) =>
                          value === password.current ||
                          "The Password do not match",
                      })}
                    />
                    {/* {errors.cpassword ? (
                  <p style={{ color: "red", marginBottom: "-10px" }}>
                    <FiAlertTriangle /> {errors.cpassword.message}
                  </p>
                ) : null} */}
                    {errors.cpassword && (
                      <p
                        style={{
                          fontSize: "12px",
                          marginBottom: "-10px",
                          color: "red",
                        }}
                      >
                        {" "}
                        {errors.cpassword.message}
                      </p>
                    )}
                  </div>
                </div>
                {errors.password ? (
                  <p
                    style={{
                      fontSize: "12px",
                      marginBottom: "-40px",
                      color: "red",
                    }}
                  >
                    {" "}
                    !!! {errors.password.message}{" "}
                  </p>
                ) : null}
                {/* <div className="form-check">
                  <input
                    type="checkbox"
                    className={classNames("form-check-input", {
                      "is-invalid": errors.agreeTerms,
                    })}
                    id="agreeTerms"
                    name="agreeTerms"
                    ref={register({
                      required: "You must agree to our Terms and Conditions",
                    })}
                  />
                  <label className="form-check-label" htmlFor="agreeTerms">
                    I agree all the terms and conditions.
                  </label>
                  {errors.agreeTerms ? (
                    <p style={{ color: "red", marginBottom: "-10px" }}>
                      <FiAlertTriangle /> {errors.agreeTerms.message}
                    </p>
                  ) : null}
                </div> */}
                <div className="d-flex  text-center flex-wrap flex-column flex-lg-row justify-content-between pt-4">
                  <button
                    className="signupbtnLogin mb-4"
                    type="submit"
                    style={{ width: "100%" }}
                  >
                    SIGN UP{" "}
                  </button>
                </div>
                <div className="text-center mt-2">
                  
                  <input type="checkbox" onChange={() => setTermsAgreed(true)} style={{marginRight:'6px'}}/>Agree ot our <a href='./terms.pdf' target='_blank' rel="noreferrer">Terms And Condition</a>
                  
                </div>
                <div className="text-center">
                  Already have an account? <Link to="/login"> Login here</Link>
                </div>
              </form>
            </div>
          </div>

          <div className="image-container col-lg-5 col-md-12 mt-3 animate__animated animate__fadeInRight">
            <div>
              <img alt="" src={LoginImg} className="imageHost" />
            </div>
            <div className="host-listing-section text-center ml-5 mt-5">
              <Link
                to="/host"
                className="signupbtnLogin1"
                style={{ height: "min-content" }}
              >
                <span>Know more about Hosting</span>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <Loader loader={loader} />
      <Footer />
    </>
  );
};

export default HostSignUp;
