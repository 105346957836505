import { RENTER_INFO } from "../action/action";

const initialState = { renterInfo: [] };
export const renterInfoReducer = (state = initialState, action) => {
  switch (action.type) {
  case RENTER_INFO:
    return { ...state, renterInfo: action.payload };

  default:
    return { ...state };
  }
};
