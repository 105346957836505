import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import classNames from "classnames";

export default function App(props) {
  const { name, errors, register } = props;
  const [address, setAddress] = React.useState("");
  const [coordinates, setCoordinates] = React.useState({
    lat: null,
    lng: null,
  });

  props.locationCoordinate(coordinates,address);
  
  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);
    setCoordinates(latLng);
  };

  return (
    <div>
      <PlacesAutocomplete
        value={address}
        onChange={setAddress}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="containerMain">
            <div className="row">
              <div className="col-lg-12">
                <div className="form-outline">
                  <input
                    name={name}
                    {...getInputProps({ placeholder: "Type Location" })}
                    className={classNames("form-control", {
                      "is-invalid": errors?.location,
                    })}
                    ref={
                      register
                        ? register({
                            required: "Required Field",
                          })
                        : null
                    }
                  />
                </div>
              </div>
              {/* <div className="col">
                <p>
                  Latitude: <br /> {coordinates?.lat}
                </p>
              </div>
              <div className="col">
                <p>
                  Latitude: <br />
                  {coordinates?.lat}
                </p>
              </div> */}
            </div>

            <div>
              {loading ? <div>...loading</div> : null}

              {suggestions.map((suggestion) => {
                const style = {
                  backgroundColor: suggestion.active ? "#41b6e6" : "#fff",
                };

                return (
                  <div
                    onClick={(e) =>
                      props.locationCoordinate(coordinates, address)
                    }
                    {...getSuggestionItemProps(suggestion, { style })}
                  >
                    {suggestion?.description}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
}
