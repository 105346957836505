import React from "react";
import { Tooltip } from "antd";
import { FaArrowAltCircleRight, FaInfoCircle } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { getLocalStorage } from "../Axios/tokenStorage";
import { token } from "../Axios/baseURL";
import { create } from "../Axios/axiosService";
import notify from "../Notify/notify";
import PP from "../images/pp.jpg";
import { FcPrint } from "react-icons/fc";
import RenterSidebar from "./RenterSidebar";
import StripeCheckout from "react-stripe-checkout";
import { connect } from "react-redux";

const PaymentContainer = (props) => {
  const { id } = useParams();

  const PaymentDetail = props?.rentalBoxQueryRequest?.data?.filter(
    (arg) => arg._id === id
  )[0];


  const property = {
    title: PaymentDetail?.storing_item,
    price: PaymentDetail?.price,
    planType: PaymentDetail?.plan_type,
    paymentType:
      PaymentDetail?.plan_type === "Weekly"
        ? "week"
        : PaymentDetail?.plan_type === "Yearly"
        ? "year"
        : "month",
  };

  getLocalStorage(token);

  const bookingId = PaymentDetail?.property?._id;

  const cancelSubscription = () => {
    create("/api/user/cancel-box-sub", { requestId: PaymentDetail?._id })
      .catch((err) => {
        console.log(err);
      })
      .then((res) => {
        props.refreshFunction(9);
        notify.showSuccess("Subscriptioin Cancel Successfully");
      });
  };

  const makePayment = (token) => {
    const body = {
      token: token,
      property,
      requestId: id,
    };

    create(`/api/user/box-payment`, body)
      .then((res) => {
        notify.showSuccess(
          "Congratulations, your payment has been done. Enjoy your time"
        );
        props.refreshFunction(9);
      })
      .catch((err) => {
        console.log(err);
        // notify.handleError(err.response.data.msg);
      });
  };

  const subscribePayment = (token) => {
    const body = {
      token: token,
      property,
      requestId: id,
    };

    create(`/api/user/box-subscription`, body)
      .then((res) => {
        notify.showSuccess(
          "Congratulations, your payment has been done. Enjoy your time"
        );
        props.refreshFunction(9);
      })
      .catch((err) => {
        console.log(err);
        // notify.handleError(err.response.data.msg);
      });
  };

  const date = PaymentDetail?.createdAt;

  const newDate = new Date(date).toDateString();

  const startDate = PaymentDetail?.start_date;
  const newStartDate = new Date(startDate).toDateString();
  return (
    <>
      {" "}
      <section id="main-dashboard">
        <div className="sidebar-row">
          <div className="sidebar-container">
            <RenterSidebar />
          </div>

          <div className="dashboard-content">
            <div className="dashboard-title">
              <h5>Dashboard | Payment </h5>
            </div>
            {/* {PaymentDetail.map((item) => ( */}
            <div className="d-flex justify-content-between flex-wrap mt-5">
              <div className="hostReservationDashboard col-lg-6 p-4 ">
                <div className="d-flex justify-content-between">
                  <div className="reservation-title">
                    <h2>
                      Payment <b className="new">New</b>
                    </h2>
                  </div>
                  <div className="reservationActions">
                    <div className="section">
                      <Tooltip title="Print">
                        <Link>
                          <button className="manage-button1">
                            <FcPrint className="icon" />
                          </button>
                        </Link>
                      </Tooltip>

                      <Tooltip title="View Property Details">
                        <Link
                          to={{
                            pathname: `/list-details/${bookingId}`,
                          }}
                        >
                          <button className="manage-button1">
                            {" "}
                            <FaArrowAltCircleRight className="icon" />{" "}
                          </button>
                        </Link>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="d-flex justify-content-between">
                  <div className="col">
                    {" "}
                    <ul className="list-unstyled text-capitalize">
                      <li className="font-weight-bold">Date: </li>
                      <li style={{ color: "gray" }}> {newDate}</li>
                    </ul>
                  </div>
                  <div className="col">
                    <ul className="list-unstyled text-capitalize ">
                      <li className="font-weight-bold">Property Title: </li>
                      <Tooltip title="View Property Details" placement="bottom">
                        <b
                          style={{
                            color: "blue",
                          }}
                        >
                          {PaymentDetail?.storing_item}
                        </b>
                      </Tooltip>
                    </ul>
                  </div>

                  <div className="col">
                    <img
                      alt=""
                      src={PP}
                      style={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "50%",
                        objectFit: "cover",
                        float: "right",
                        marginRight: "10px",
                      }}
                    />
                  </div>
                </div>
                <hr />
                <div className="d-flex justify-content-between pt-3">
                  <div className="col-2">
                    <b>Details</b>
                  </div>
                  <div className="col-5">
                    <ul className="list-unstyled ">
                      <li>
                        <b> Check In:</b>{" "}
                        <b style={{ color: "gray" }}>{newStartDate}</b>
                      </li>
                      <li>
                        Pickup time:{" "}
                        <b style={{ color: "gray" }}>
                          {" "}
                          {PaymentDetail?.pickup_time}
                        </b>
                      </li>
                    </ul>
                  </div>
                  <div className="col-5">
                    <ul className="list-unstyled text-capitalize">
                      <li className="font-weight-bold">
                        Plan:{" "}
                        <b style={{ color: "gray" }}>
                          {" "}
                          {PaymentDetail?.plan_type}
                        </b>
                      </li>
                      <li>
                        Duration:
                        <b style={{ color: "gray" }}>
                          {" "}
                          {PaymentDetail?.storage_plan}
                        </b>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
                <hr />
                <FaInfoCircle /> Dear Sir/Ma'am, Your money will be deducted
                from your account on{" "}
                <b style={{ color: "#1d6aba" }}> {PaymentDetail?.planType} </b>
                package.
                {/* <div className="d-flex justify-content-start pt-3">
                  <div className="col-2">
                    <b>Payment</b>
                  </div>
                  <div className="col-5">
                    <ul className="list-unstyled ">
                      <li>
                        <b>100$ * 1 Week = </b>{" "}
                        <b style={{ color: "gray" }}> 100$ </b>
                      </li>
                    </ul>
                  </div>
                </div> */}
              </div>

              <div className="hostConfirmation col-lg-4 p-4">
                <div className="payment-title">
                  <h2>Payment</h2>
                </div>

                <hr />
                <div className="row">
                  <div className="col-lg-10">
                    <ul className="list-unstyled ">
                      <li className="font-weight-bold">
                        <b style={{ fontSize: "20px" }}>
                          {" "}
                          Total{" "}
                          <small>
                            (Your total&nbsp;
                            <b style={{ color: "#1d6aba" }}>
                              {PaymentDetail?.planType}
                            </b>{" "}
                            Price)
                          </small>
                        </b>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-2">
                    <ul className="list-unstyled  float-right">
                      <li className="font-weight-bold">
                        <b style={{ fontSize: "20px" }}>
                          {" "}
                          {PaymentDetail?.price}${" "}
                        </b>
                      </li>
                    </ul>
                  </div>
                </div>
                {PaymentDetail?.booking_status === "payment pending" ? (
                  <div className="buttons align-content-end">
                    {PaymentDetail?.storage_plan === "A Week" ||
                    PaymentDetail?.storage_plan === "A Year" ||
                    PaymentDetail?.storage_plan === "A Month" ? (
                      <>
                        {PaymentDetail?.isPaid === false ? (
                          <>
                            <StripeCheckout
                              stripeKey="pk_test_51ISwUgDGQGvtIAjYzn6XiPTmBP6rZWVa9VXXJzwZM57opcrE9BSi8t7DnIRYrFLJR5G0noCFDxXSXZ3zCy4vbGQN00jKvO1yy0"
                              token={makePayment}
                              name="stozy"
                              amount={property?.price * 100}
                              billingAddress
                              shippingAddress
                            >
                              <button className="signupbtnLogin w-100 mt-3">
                                Pay Now with Stripe
                              </button>
                            </StripeCheckout>
                          </>
                        ) : (
                          <h2 style={{ color: "green", textAlign: "center" }}>
                            {PaymentDetail?.booking_status}
                          </h2>
                        )}
                      </>
                    ) : (
                      <>
                        {PaymentDetail?.isPaid === false ? (
                          <>
                            {" "}
                            <StripeCheckout
                              stripeKey="pk_test_51ISwUgDGQGvtIAjYzn6XiPTmBP6rZWVa9VXXJzwZM57opcrE9BSi8t7DnIRYrFLJR5G0noCFDxXSXZ3zCy4vbGQN00jKvO1yy0"
                              token={subscribePayment}
                              name="stozy"
                              amount={property?.price * 100}
                              billingAddress
                              shippingAddress
                            >
                              <button className="signupbtnLogin w-100 mt-3">
                                Subscribe Plan
                              </button>
                            </StripeCheckout>
                          </>
                        ) : (
                          <h2 style={{ color: "green", textAlign: "center" }}>
                            {PaymentDetail?.booking_status}
                          </h2>
                        )}
                      </>
                    )}

                    {/* <button
                    className="signupbtnCreate w-100 mt-3"
                    // data-target="#deleteReservation"
                    // data-toggle="modal"
                  >
                    Decline
                 </button>*/}
                  </div>
                ) : PaymentDetail?.booking_status === "booked" ? (
                  <>
                    <h2 style={{ color: "green", textAlign: "center" }}>
                      {PaymentDetail?.booking_status}
                    </h2>
                    <button
                      onClick={() => cancelSubscription()}
                      className="signupbtnLogin w-100 mt-3"
                    >
                      Cancel Subscription
                    </button>
                  </>
                ) : (
                  <h2 style={{ color: "green", textAlign: "center" }}>
                    {PaymentDetail?.booking_status}
                  </h2>
                )}

                {/*(PaymentDetail?.storage_plan !== "A Week" ||
                PaymentDetail?.storage_plan !== "A Year" ||
                PaymentDetail?.storage_plan !== "A Month" &&
                  PaymentDetail?.isPaid===true ) ?  null : (
                  <button onClick={()=>cancelSubscription()} className="signupbtnLogin w-100 mt-3">
                    Cancel Subscription
                  </button>
                  )*/}
              </div>
            </div>
            {/* ))} */}
          </div>
        </div>
      </section>
    </>
  );
};
const mapStateToProps = (state) => ({
  rentalBoxQueryRequest: state.rentalBoxQueryRequest.rentalBoxQueryRequest,
  refresh: state.refresh.refresh.refresh,
  refreshFunction: state.refresh.refresh.refreshFunction,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentContainer);
