import { combineReducers } from "redux";
import { allFeaturedPropertyReducer } from "./../reducer/allFeaturedProperty";
import { renterFavouritesPropertyReducer } from "./../reducer/allRenterFavouritesProperty";
import { getAllPropertyListReducer } from "./../reducer/getAllProperty";
import { getOwnerInfoReducer } from "./../reducer/getOwnerInfo";
import { ownerFeaturedPropertyReducer } from "./../reducer/ownerFeaturedProperty";
import { ownerPropertyReducer } from "./../reducer/ownerProperty";
import { refreshReducer } from "./../reducer/refreshPage";
import { renterBookedPropertyReducer } from "../reducer/renterBookedProperty";
import { renterInfoReducer } from "./../reducer/renterInfo";
import { allBookedPropertyReducer } from "./../reducer/allBookedProperty";
import { rentalBoxQueryRequestReducer } from "./../reducer/rentalBoxQuery";
import { allBoxRequestReducer } from "./../reducer/adminAllBoxRequestList";
import { allSpaceRequestReducer } from "./../reducer/adminAllSpaceRequestList";
import {rentalSpaceQueryRequestReducer  } from "./../reducer/rentalSpaceQuery";







export const rootReducer = combineReducers({
  allFeaturedProperty:allFeaturedPropertyReducer,
  renterFavouritesProperty:renterFavouritesPropertyReducer,
  getAllProperty:getAllPropertyListReducer,
  getOwnerInfo:getOwnerInfoReducer,
  ownerFeaturedProperty:ownerFeaturedPropertyReducer,
  ownerProperty:ownerPropertyReducer,
  refresh:refreshReducer,
  renterBookedProperty:renterBookedPropertyReducer,
  renterInfo:renterInfoReducer,
  allBookedProperty:allBookedPropertyReducer,
  rentalBoxQueryRequest:rentalBoxQueryRequestReducer,
  allBoxRequest:allBoxRequestReducer,
  allSpaceRequest:allSpaceRequestReducer,
  rentalSpaceQueryRequest:rentalSpaceQueryRequestReducer
});
