import React, { useState } from "react";

import { Link } from "react-router-dom";
import { FaEdit, FaStar, FaStarHalfAlt } from "react-icons/fa";
import HostSidebar from "./HostSidebar";
import SyncLoader from "react-spinners/SyncLoader";
import { connect } from "react-redux";
import female from "./../images/PP_femaile.png";
import male from "./../images/pp.jpg";
import { base_URL } from "../Axios/baseURL";

const Profile = (props) => {
  //const [profileDetail, setProfileDetail] = useState([]);
  const profileDetail = props.ownerInfo;

  const [loading] = useState(false);
  // console.log("this is", token);

  const date = profileDetail.createdAt;
  const joinedDate = new Date(date).toLocaleDateString();

  return (
    <>
      <section id="main-dashboard">
        <div className="sidebar-row">
          <div className="sidebar-container">
            <HostSidebar />
          </div>

          <div className="dashboard-content">
            <div className="dashboard-title">
              <h5>Dashboard | Profile</h5>
            </div>
            {loading ? (
              <>
                {" "}
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "80vh", top: "50%" }}
                >
                  <SyncLoader size={18} color="#1d6aba" margin="2" />
                </div>
              </>
            ) : (
              <div className="profilePage text-capitalize">
                <div className="profileDetails d-flex flex-wrap justify-content-between">
                  <div
                    className="text-center col-lg-5 mb-3 animate__animated animate__fadeInLeft"
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid grey",
                      padding: "14px",
                      borderRadius: "8px",
                    }}
                  >
                    {profileDetail?.profileImage ? (
                      <img
                        alt=""
                        src={
                          `${base_URL}/file/images/` +
                          profileDetail?.profileImage[0]
                        }
                        // {profileDetail.gender === "female" ? PPF : PP}
                        style={{
                          width: "110px",
                          height: "110px",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <img
                        style={{
                          width: "110px",
                          height: "110px",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                        src={profileDetail.gender === "female" ? female : male}
                        alt=""
                      />
                    )}
                    <br /> <br />
                    <ul className="list-unstyled mb-4">
                      <li>
                        <h5 style={{ textTransform: "capitalize" }}>
                          {profileDetail.fullName}
                        </h5>
                      </li>
                      <li>
                        <FaStar style={{ color: "#1d6aba" }} />
                        <FaStar style={{ color: "#1d6aba" }} />
                        <FaStar style={{ color: "#1d6aba" }} />
                        <FaStar style={{ color: "#1d6aba" }} />
                        <FaStarHalfAlt style={{ color: "#1d6aba" }} />
                      </li>
                      <li>Joined: {joinedDate}</li>
                      <li className="mt-2">
                        {" "}
                        <Link
                          to={{
                            pathname: `/dashboard-edit-profile/${profileDetail._id}`,
                          }}
                        >
                          {" "}
                          <FaEdit /> Edit Profile{" "}
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="col-lg-6 mb-3 animate__animated animate__fadeInRight"
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid grey",
                      padding: "14px 18px",
                      borderRadius: "8px",
                    }}
                  >
                    <div className="d-flex justify-content-between">
                      <ul className="list-unstyled">
                        <li>
                          {" "}
                          <p>
                            {" "}
                            <b className="text-dark">Gender </b>: &nbsp;
                            {profileDetail.gender}
                          </p>
                        </li>
                        <li>
                          {" "}
                          <p>
                            {" "}
                            <b className="text-dark">Role </b>: &nbsp;{" "}
                            {profileDetail.role}
                          </p>
                        </li>

                        <li className="text-lowercase ">
                          {" "}
                          <p>
                            {" "}
                            <b className="text-dark text-capitalize">
                              {" "}
                              E-mail{" "}
                            </b>
                            : &nbsp;
                            {profileDetail.email}
                          </p>
                        </li>

                        <li>
                          {" "}
                          <p>
                            {" "}
                            <b className="text-dark"> Phone Number </b>: &nbsp;
                            {profileDetail.contactNo}
                          </p>
                        </li>
                        <li>
                          {" "}
                          <p>
                            {" "}
                            <b className="text-dark">Location </b>: &nbsp;
                            {profileDetail.address}
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};
const mapStateToProps = (state) => ({
  ownerInfo: state.getOwnerInfo.getOwnerInfo,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
