import React, { useRef } from "react";
import { useParams } from "react-router-dom";
import { FiAlertTriangle } from "react-icons/fi";
import classNames from "classnames";
import {  Tooltip } from "antd";
import notify from "../Notify/notify";

import { useForm } from "react-hook-form";
import { create } from "../Axios/axiosService";

const ForgotPassword = (props) => {
  const { register, handleSubmit, errors, watch } = useForm();
  const { id } = useParams();

  const password = useRef({});
  password.current = watch("password", "");

  const onSubmit = (data) => {
    create(`api/auth/reset-password/${id}`, {
      password: data.password,
    })
      .then((res) => {
       notify.showSuccess("Your Password is changed Successfully");
        props.history.push('/login')
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <section id="reset">
        <div className="reset col-lg-6 col-md-12 col-sm-12 ml-auto mr-auto">
          <h3>Reset Password(Renter)</h3>
          <hr />
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group mt-3">
              <label htmlFor="recipient-name" className="col-form-label">
                Email
              </label>
              <input
                type="email"
                className={classNames("form-control", {
                  "is-invalid": errors.email,
                })}
                name="email"
                placeholder="Email address"
                ref={register({
                  required: "Required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                })}
              />
              {errors.email ? (
                <p
                  style={{
                    color: "red",
                    marginBottom: "-10px",
                    fontSize: "12px",
                  }}
                >
                  <FiAlertTriangle /> {errors.email.message}
                </p>
              ) : null}
            </div>
            <div className="form-group mt-3">
              <div className="row">
                <div className="col">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Password{" "}
                    <Tooltip
                      title="!! Password must be minimum 8 characters andContain Uppercase, LowerCase, Number and
                      a Special Character"
                    >
                      <FiAlertTriangle />
                    </Tooltip>
                  </label>
                  <input
                    type="password"
                    name="password"
                    className={classNames("form-control", {
                      "is-invalid": errors.password,
                    })}
                    id="password"
                    placeholder="Password"
                    ref={register({
                      required: "Required",
                      validate: (value) => {
                        return (
                          [
                            /[a-z]/,
                            /[A-Z]/,
                            /[0-9]/,
                            /[^a-zA-Z)-9]/,
                          ].every((pattern) => pattern.test(value)) ||
                          "!! Password must Contain Uppercase, LowerCase, Number and a Special Character"
                        );
                      },
                    })}
                  />
                  {errors.password ? (
                    <small style={{ color: "red", fontSize: "12px" }}>
                      <FiAlertTriangle /> {errors.password.message}
                    </small>
                  ) : null}
                </div>
                <div className="col">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    name="cpassword"
                    className={classNames("form-control", {
                      "is-invalid": errors.cpassword,
                    })}
                    id="cpassword"
                    placeholder="Confirm Password"
                    ref={register({
                      validate: (value) =>
                        value === password.current ||
                        "The Password do not match",
                    })}
                  />

                  {errors.cpassword ? (
                    <p
                      style={{
                        fontSize: "12px",
                        marginBottom: "-40px",
                        color: "red",
                      }}
                    >
                      {" "}
                      {errors.cpassword.message}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>

            <button type="submit" className="signupbtnLogin w-100 mr-3 mt-3">
              Reset
            </button>
          </form>
        </div>
      </section>
    </>
  );
};

export default ForgotPassword;
