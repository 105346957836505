import React from "react";
import { FaBars } from "react-icons/fa";
import { Link } from "react-router-dom";
import logo from "../images/logo.png";
import { connect } from "react-redux";

// const token = localStorage.getItem("login_token");

const NavAfter = (props) => {
  //const [userDetail, setUserDetail] = useState([]);

  // const token = localStorage.getItem("token");
  // console.log("this is", token);

  /*useEffect(() => {
    const getProfileDetail = () => {
      console.log(role);
      // if (role === "owner") {
      read("api/owner/getmyinfo")
        // axios({
        //   method: "get",
        //   headers: {
        //     Authorization: token,
        //   },
        //   url: "http://157.245.108.131:8080/api/user/getmyinfo",
        // })
        .then((res) => {
          setProfileDetail(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
      // } else {
      read("api/user/getmyinfo", token)
        // axios({
        //   method: "get",
        //   headers: {
        //     Authorization: token,
        //   },
        //   url: "http://157.245.108.131:8080/api/user/getmyinfo",
        // })
        .then((res) => {
          setUserDetail(res.data[0]);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    // };
    getProfileDetail();
  }, [token]);*/

  const profileDetail = props?.getOwnerInfo;
  const userDetail = props?.renterInfo[0];

  // logout function
  const logout = () => {
    localStorage.clear();
    window.location.href = "/";
  };
  return (
    <>
      <nav className="main-nav navbar navbar-expand-lg sticky-top ">
        <div
          className="navbar-toggler-right"
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "stretch",
          }}
          id="dropper1"
        >
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#dashboard"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
            style={{
              flex: "1",
              // alignSelf: "left",

              marginLeft: "auto",
            }}
          >
            <span className="navbar-toggler-icon">
              <FaBars className="navbar-toggler-icon" />{" "}
            </span>
          </button>
          <div style={{ flex: "15", textAlign: "center" }}>
            <Link
              to="/"
              className="navbar-brand"
              style={{ fontSize: "26px", fontWeight: "bolder" }}
            >
              <img src={logo} height="60px" />
            </Link>
          </div>
        </div>

        {profileDetail.role === "owner" ? (
          <div className="collapse navbar-collapse" id="dashboard">
            <div className="dropdown-divider"></div>
            <li className="nav-item dropdown ml-2">
              <Link
                className="nav-link dropdown-toggle"
                to="#"
                id="navbarDropdown"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="text-capitalize">
                  {profileDetail?.role === "owner"
                    ? profileDetail?.fullName
                    : userDetail?.name}
                </span>
              </Link>

              <div className="dropdown-menu " aria-labelledby="navbarDropdown">
                <Link
                  className="dropdown-item"
                  to={
                    profileDetail.role === "owner"
                      ? "/dashboard-profile"
                      : "/dashboard-renter-profile"
                  }
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  Profile
                </Link>
                <div className="dropdown-divider"></div>
                <Link
                  className="dropdown-item"
                  to={
                    profileDetail.role === "owner"
                      ? "/dashboard"
                      : "/dashboard-renter"
                  }
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  Dashboard
                </Link>
                <div className="dropdown-divider"></div>
                <button
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#exampleModal"
                >
                  Logout
                </button>
              </div>
            </li>

            <ul className="navbar-nav ml-4">
              <div className="dropdown-divider"></div>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/about"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  About Us
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/search-location"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  Stozy Your Stuff
                </Link>
              </li>
              {profileDetail.role === "owner" ? (
                <li className="nav-item">
                  <Link
                    to="/dashboard-add"
                    className="nav-link"
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                  >
                    List your Space
                  </Link>
                </li>
              ) : null}
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to={
                    profileDetail.role === "owner"
                      ? "/dashboard"
                      : "/dashboard-renter"
                  }
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  Manage Booking
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/contact"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  Contact Us
                </Link>
              </li>
            </ul>
            <ul className="navbar-nav ml-4">
              <div className="dropdown-divider"></div>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/dashboard"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  Dashboard
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/dashboard-profile"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  Profile
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/dashboard-listings"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  My Listing
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/dashboard-add"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  Add New Listing
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/dashboard-add-box"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  Add Box
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/dashboard-reservation"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  Reservations
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/dashboard-reservation"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  Featured
                </Link>
              </li>
            </ul>
          </div>
        ) : (
          <div className="collapse navbar-collapse" id="dashboard">
            <div className="dropdown-divider"></div>
            <li className="nav-item dropdown ml-2">
              <Link
                className="nav-link dropdown-toggle"
                to="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="text-capitalize">
                  {profileDetail?.role === "owner"
                    ? profileDetail?.fullName
                    : userDetail?.name}
                </span>
              </Link>
              <div
                className="dropdown-menu dropdown-menu"
                aria-labelledby="navbarDropdown"
                style={{ margin: "auto" }}
              >
                <Link
                  className="dropdown-item"
                  to={
                    profileDetail.role === "owner"
                      ? "/dashboard-profile"
                      : "/dashboard-renter-profile"
                  }
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  Profile
                </Link>
                <div className="dropdown-divider"></div>
                <Link
                  className="dropdown-item"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                  to={
                    profileDetail.role === "owner"
                      ? "/dashboard"
                      : "/dashboard-renter"
                  }
                >
                  Dashboard
                </Link>
                <div className="dropdown-divider"></div>

                <button
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#exampleModal"
                >
                  Logout
                </button>
              </div>
            </li>
            <ul className="navbar-nav ml-4">
              <div className="dropdown-divider"></div>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/about"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  About Us
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/search-location"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  Stozy Your Stuff
                </Link>
              </li>
              {profileDetail.role === "owner" ? (
                <li className="nav-item">
                  <Link
                    to="/dashboard-add"
                    className="nav-link"
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                  >
                    List your Space
                  </Link>
                </li>
              ) : null}
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to={
                    profileDetail.role === "owner"
                      ? "/dashboard"
                      : "/dashboard-renter"
                  }
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  Manage Booking
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/contact"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  Contact Us
                </Link>
              </li>
            </ul>
            <ul className="navbar-nav ml-4">
              <div className="dropdown-divider"></div>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/dashboard-renter"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  Dashboard
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="dashboard-renter-profile"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  Profile
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/dashboard-renter-listings"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  Available Listings
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/dashboard-add-box">
                  Box Request
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/box-query">
                  Box Request List
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/dashboard-renter-reservations"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  Your Bookings
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/dashboard-renter-favourites"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  Favourites
                </Link>
              </li>

              {/* <li data-toggle="modal" data-target="#exampleModal">
                Log out
              </li> */}
            </ul>
          </div>
        )}

        {/* // nav for bigger screen // */}
        <div className="collapse navbar-collapse flex-column" id="navbar">
          <div className="first-nav d-flex justify-content-between w-100 p-3">
            <div className="col-2">
              <ul className="navbar-nav  w-100  float-left px-3">
                <li className="nav-item active">
                  <Link
                    to="/"
                    className="navbar-brand "
                    style={{ fontSize: "24px", fontWeight: "bolder" }}
                  >
                    <img src={logo} height="60px" />
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-8">
              <ul className="navbar-nav second-nav justify-content-center w-100  px-3 p-2 align-items-center">
                <li>
                  <Link to="/" className="navTab">
                    {" "}
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/about" className="navTab">
                    About Us
                  </Link>
                </li>
                {/* {profileDetail.role === "owner" ? ( */}
                <li>
                  <Link to="/search-location"> Stozy Your Stuff</Link>
                </li>
                {/* ) : null} */}

                {profileDetail.role === "owner" ? (
                  <li>
                    <Link to="/dashboard-add">List your Space</Link>
                  </li>
                ) : null}
                <li>
                  <Link
                    to={
                      profileDetail.role === "owner"
                        ? "/dashboard"
                        : "/dashboard-renter"
                    }
                  >
                    Manage Booking
                  </Link>
                </li>
                <li>
                  <Link to="/contact">Contact Us</Link>
                </li>
                {profileDetail.role !== "owner" ? (
                  <li>
                    <Link to="/host">Become a Host</Link>
                  </li>
                ) : null}
              </ul>
            </div>
            <ul className="navbar-nav w-100 login-signup-after justify-content-center align-content-center mt-2">
              {/*become a host button*/}
              {/* {role !== "owner" ? (
                <button className="nav-item">
                  <Link className="nav-link" to="/host/signup">
                    Become a Host
                  </Link>
                </button>
              ) : null} */}
              <ul className="navbar-nav ">
                <li className="nav-item dropdown">
                  <Link
                    to="#"
                    className="nav-link dropdown-toggle"
                    id="navDropDownLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span className="text-capitalize">
                      {profileDetail?.role === "owner"
                        ? profileDetail?.fullName
                        : userDetail?.name}
                    </span>
                  </Link>
                  <div
                    className="dropdown-menu  dropdown-menu-right"
                    aria-labelledby="navDropDownLink"
                  >
                    <Link
                      className="dropdown-item"
                      to={
                        profileDetail?.role === "owner"
                          ? "/dashboard-profile"
                          : "/dashboard-renter-profile"
                      }
                    >
                      Profile
                    </Link>
                    <div className="dropdown-divider"></div>
                    <Link
                      className="dropdown-item"
                      to={
                        profileDetail.role === "owner"
                          ? "/dashboard"
                          : "/dashboard-renter"
                      }
                    >
                      Dashboard
                    </Link>
                    <div className="dropdown-divider"></div>

                    {profileDetail.role === "owner" ? (
                      <>
                        <Link className="dropdown-item" to={"/host"}>
                          About Host
                        </Link>
                        <div className="dropdown-divider"></div>
                      </>
                    ) : null}

                    <a
                      className="dropdown-item"
                      data-toggle="modal"
                      data-target="#exampleModal"
                    >
                      Logout
                    </a>
                  </div>
                </li>
              </ul>
            </ul>
          </div>
        </div>
      </nav>

      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header text-center ">
              <h5 className="modal-title ml-auto">Are you sure?</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-footer  justify-content-center">
              <button
                type="button"
                onClick={logout}
                className="btn btn-primary"
              >
                Yes
              </button>

              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  getOwnerInfo: state.getOwnerInfo.getOwnerInfo,
  renterInfo: state.renterInfo.renterInfo,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(NavAfter);
