import { RENTAL_BOX_QUERY_REQUEST } from "../action/action";

const initialState = { rentalBoxQueryRequest: [] };
export const rentalBoxQueryRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case RENTAL_BOX_QUERY_REQUEST:
      return { ...state, rentalBoxQueryRequest: action.payload };

    default:
      return { ...state };
  }
};
