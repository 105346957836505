import React from "react";
import { AiOutlineRightCircle, AiOutlineLeftCircle } from "react-icons/ai";
import "./paginationStyle.css";
const PaginationPage = (props) => {
  const {
    postPerPage,
    totalPost,
    paginate,
    currentPage,
    setCurrentPage,
  } = props;

  const pageNo = [];

  for (let index = 1; index <= Math.ceil(totalPost / postPerPage); index++) {
    pageNo.push(index);
  }
  return (
    <div className="w-100 d-flex ">
      <ul className="pagination justify-content-end">
        <div className="page-item text-center">
          <li className="page-item text-center">
            <a
              disabled={currentPage === parseInt("1") ? true : false}
              style={
                currentPage === parseInt("1")
                  ? { opacity: "0.3" }
                  : { opacity: "1" }
              }
              type="button"
              onClick={() => setCurrentPage(currentPage - parseInt("1"))}
              className="page-liink"
            >
              <AiOutlineLeftCircle size="30px" color="#1d6aba" />
            </a>
          </li>
        </div>
        <li className="page-item text-center">
          <div className="paginationNumbers">
            <div
              className="otherPage"
              onClick={() =>
                paginate(
                  currentPage === parseInt("1")
                    ? null
                    : currentPage - parseInt("1")
                )
              }
            >
              {currentPage === parseInt("1")
                ? null
                : currentPage - parseInt("1")}
            </div>
            <div className="currentPage">{currentPage}</div>
            <div
              className="otherPage"
              onClick={() =>
                paginate(
                  currentPage === Math.ceil(totalPost / postPerPage)
                    ? null
                    : currentPage + parseInt("1")
                )
              }
            >
              {currentPage === Math.ceil(totalPost / postPerPage)
                ? null
                : currentPage + parseInt("1")}
            </div>
          </div>
        </li>

        <li className="page-item">
          <a
            disabled={
              currentPage === Math.ceil(totalPost / postPerPage) ? true : false
            }
            style={
              currentPage === Math.ceil(totalPost / postPerPage)
                ? { opacity: "0.3" }
                : { opacity: "1" }
            }
            type="button"
            onClick={() => setCurrentPage(currentPage + parseInt("1"))}
            className="page-liink"
          >
            <AiOutlineRightCircle size="30px" />
          </a>
        </li>
      </ul>
    </div>
  );
};
export default PaginationPage;
