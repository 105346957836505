import React, { useState } from "react";
import { Tooltip } from "antd";
import { FaArrowAltCircleRight, FaInfoCircle } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { create } from "../Axios/axiosService";
import notify from "../Notify/notify";
import PP from "../images/pp.jpg";
import HostSidebar from "../Dashboard/HostSidebar";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../service/loader/loader";
import $ from "jquery";
import { getLocalStorage, setLocalStorage } from "../Axios/tokenStorage";
import { token } from "../Axios/baseURL";

const HostReservation = (props) => {
  const [loader, setLoader] = useState(false);
  const { id } = useParams();
  // const [reservationDetail, setReservationDetail] = useState("");

  const allBookedProperty = props?.allBookedProperty;
  const reservationDetail = allBookedProperty?.filter(
    (arg) => arg?._id === id
  )[0];

  const history = useHistory();

  console.log(localStorage.getItem("role"));

  const role = getLocalStorage("role");

  if (role === "renter") {
    localStorage.clear();
    history.push("/login");
    notify.showWarning("Login as Host to proceed");
    window.location.reload();
  }

  const accept = (id) => {
    setLoader(true);
    create("api/property/acceptbooking", {
      propertyId: id,
    })
      .then((res) => {
        $("#confirmReservation").modal("hide");

        // console.log("the response is >>", res.data.accessToken);
        notify.showSuccess("You have accepeted this request, wait for payment");
        // console.log(res.data.email);
        props.refreshFunction(8);
        setLoader(false);
      })
      .catch((err) => {
        if (err.response) {
          notify.handleError(err.response.data.msg + ", Try again");
          console.log(err);
          // console.log(err.response.status);
          // console.log(err.response.headers);
          setLoader(false);
        }
      });
  };
  const reject = (id) => {
    create("api/property/rejectbooking", {
      propertyId: id,
    })
      .then((res) => {
        $("#deleteReservation").modal("hide");

        // console.log("the response is >>", res.data.accessToken);
        notify.showSuccess("Hmm, You have declined this request");
        props.refreshFunction(8);
        history.push("/dashboard-listings");
        // console.log(res.data.email);
        // setListingData(listingData.reverse());
      })
      .catch((err) => {
        if (err.response) {
          // notify.handleError(err.response.data.msg + ", Try again");
          console.log(err);
          // console.log(err.response.status);
          // console.log(err.response.headers);
        }
      });
  };

  const date = reservationDetail?.createdAt;
  const newDate = new Date(date).toDateString();

  const startDate = reservationDetail?.start_date;
  const newStartDate = new Date(startDate).toDateString();
  return (
    <>
      <section id="main-dashboard">
        <div className="sidebar-row">
          <div className="sidebar-container">
            <HostSidebar />
          </div>

          <div className="dashboard-content">
            <div className="dashboard-title">
              <h5>Dashboard | Reservations</h5>
            </div>
            <div className="row mt-5">
              <div className="col-lg-7">
                <div className="hostReservationDashboard">
                  <div className="d-flex justify-content-between">
                    <div className="reservation-title">
                      <h2>Reservation &nbsp;</h2>
                    </div>
                    <div className="reservationActions">
                      <div className="section">
                        {/* <Tooltip title="Print">
                          <Link>
                            <button className="manage-button1">
                              <FcPrint className="icon" />
                            </button>
                          </Link>
                        </Tooltip> */}
                        <Tooltip title="View Property Details">
                          <Link
                            to={{
                              pathname: `/list-details/${id}`,
                            }}
                          >
                            <button className="manage-button1">
                              <FaArrowAltCircleRight className="icon" />
                            </button>
                          </Link>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                  <hr />

                  <div className="col-lg-12  d-flex justify-content-between flex-wrap">
                    <div className="col-lg-4 col-md-12">
                      <ul className="list-unstyled ">
                        <li className="font-weight-bold">Date: </li>
                        <li style={{ color: "gray" }}> {newDate}</li>
                      </ul>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <ul className="list-unstyled text-capitalize">
                        <li className="font-weight-bold">From:</li>
                        <li style={{ color: "gray" }}>
                          {reservationDetail?.user?.name}
                        </li>
                      </ul>
                    </div>

                    <div className="col-lg-3 col-md-2">
                      <img
                        alt=""
                        src={PP}
                        style={{
                          width: "60px",
                          height: "60px",
                          borderRadius: "50%",
                          objectFit: "cover",
                          marginRight: "10px",
                        }}
                      />
                    </div>
                  </div>
                  <hr />

                  <div className="col-lg-12 d-flex justify-content-between flex-wrap pt-3">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <ul className="list-unstyled ">
                        <li>
                          <b> Check In: </b>
                          <b style={{ color: "gray" }}>{newStartDate}</b>
                        </li>
                        <li>
                          Pickup time:
                          <b style={{ color: "gray" }}>
                            &nbsp; {reservationDetail?.pickup_time}
                          </b>
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <ul className="list-unstyled text-capitalize">
                        <li className="font-weight-bold text-capitalize">
                          Payment Plan:
                          <span
                            style={{
                              color: "gray",
                            }}
                          >
                            &nbsp; {reservationDetail?.planType}
                          </span>
                        </li>
                        <li>
                          Storing Duration:
                          <b style={{ color: "gray" }}>
                            &nbsp; {reservationDetail?.storagePlan}
                          </b>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <hr />
                  <div className="col-lg-12 d-flex flex-wrap justify-content-start pt-3">
                    <div className="col-lg-2">
                      <b>Message:</b>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                      <ul className="list-unstyled text-capitalize">
                        <li>
                          <b style={{ color: "gray" }}>
                            {reservationDetail?.messageForHost}
                          </b>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <hr />
                </div>
              </div>

              <div className="col-lg-5 mt-2">
                {/* m-md-5 m-sm-4 m-md-5 */}
                <div className="hostConfirmation">
                  <div className="payment-title">
                    <h2>Total Price</h2>
                  </div>

                  <hr />
                  <div className="row">
                    <div className="col">
                      <ul className="list-unstyled ">
                        <li className="font-weight-bold">
                          <b style={{ fontSize: "20px" }}> Total</b>
                        </li>
                      </ul>
                    </div>
                    <div className="col">
                      <ul className="list-unstyled  float-right">
                        <li className="font-weight-bold">
                          <b style={{ fontSize: "20px" }}>
                            {reservationDetail?.price}$
                          </b>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="buttons align-content-end">
                    {reservationDetail?.booking_status === "accepted" ? (
                      <div className="text-center">
                        <button className="accepted">
                          {" "}
                          <h4 style={{ color: "#fff", padding: "1px 5px" }}>
                            {" "}
                            Accepted{" "}
                          </h4>
                        </button>
                        <hr />
                        <FaInfoCircle /> Dear Sir/Ma'am, Please wait for the
                        payment from Renter Side and keep checking your E-mail.
                      </div>
                    ) : (
                      <>
                        <button
                          className="signupbtnLogin w-100 mt-3"
                          data-target="#confirmReservation"
                          data-toggle="modal"
                          // onClick={() => {
                          //   accept(reservationDetail?.data?.property);
                          // }}
                        >
                          Confirm
                        </button>

                        <button
                          className="signupbtnCreate w-100 mt-3"
                          data-target="#deleteReservation"
                          data-toggle="modal"
                          // onClick={() => {
                          //   reject(reservationDetail?.data?.property);
                          // }}
                        >
                          Decline
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* confirm reservation modal */}
      <div
        className="modal fade"
        id="confirmReservation"
        tabindex="-1"
        role="dialog"
        aria-labelledby="confirmReservation"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header text-center ">
              <h5 className="modal-title ml-auto">
                Are you sure, you want to Confirm this Reservation Request?
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-footer  justify-content-center">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={() => {
                  accept(reservationDetail?.property);
                }}
              >
                Yes
              </button>

              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* delete reservation modal */}
      <div
        className="modal fade"
        id="deleteReservation"
        tabindex="-1"
        role="dialog"
        aria-labelledby="deleteReservation"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header text-center ">
              <h5 className="modal-title ml-auto">
                Are you sure, you want to Delete this Reservation Request?
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-footer  justify-content-center">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={() => {
                  reject(reservationDetail?.property);
                }}
              >
                Yes
              </button>

              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                No
              </button>
            </div>
          </div>
        </div>
        <Loader loader={loader} />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  allBookedProperty: state.allBookedProperty.allBookedProperty,
  refresh: state.refresh.refresh.refresh,
  refreshFunction: state.refresh.refresh.refreshFunction,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(HostReservation);
