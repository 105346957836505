import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { update } from "../Axios/axiosService";
import { useForm } from "react-hook-form";
import notify from "../Notify/notify";
import HostSidebar from "./HostSidebar";
import { connect } from "react-redux";
import Loader from "./../service/loader/loader";
import { base_URL } from "../Axios/baseURL";

const EditProfile = (props) => {
  const [loader, setLoader] = useState(false);
  const profile = props.getOwnerInfo;
  const { id } = useParams();
  const { register, handleSubmit, setValue } = useForm({
    mode: "onTouched",
  });
  useEffect(() => {
    setValue("email", profile.email);
    setValue("fullName", profile.fullName);
    setValue("address", profile.address);
    setValue("contactNo", profile.contactNo);
    setValue("gender", profile.gender);
    // eslint-disable-next-line
  }, [props]);

  const history = useHistory();

  const onSubmit = (data) => {
    setLoader(true);
    const myData = {
      fullName: data.fullName,
      address: data.address,
      contactNo: data.contactNo,
      gender: data.gender,
      profileImage: data.profileImage[0] || profile.profileImage,
    };

    let finalData = new FormData();
    for (const key of Object.keys(myData)) {
      finalData.append(key, myData[key]);
    }

    update(`api/owner/${id}`, finalData)
      .then((res) => {
        notify.showSuccess("Your profile is Updated successfully");
        history.push("/dashboard-profile");
        setLoader(false);
        props.refreshFunction(4);
      })
      .catch((err) => {
        if (err.response) {
          notify.handleError(err.response.data.msg + ", Try again");
          setLoader(false);
        }
      });
  };

  return (
    <>
      <section id="main-dashboard">
        <div className="sidebar-row">
          <div className="sidebar-container">
            <HostSidebar />
          </div>

          <div className="dashboard-content">
            <div className="dashboard-title">
              <h5>Dashboard | Edit Profile</h5>
            </div>
            <div className="profilePage">
              <div className="profileDetails d-flex flex-wrap justify-content-around">
                <div
                  className="text-center mr-4 "
                  style={{
                    width: "20%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    alt=""
                    src={`${base_URL}/file/images/` + profile?.profileImage}
                    style={{
                      width: "70px",
                      height: "70px",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />{" "}
                  <br /> <br />
                  <div className="form-group">
                    <label htmlFor="exampleFormControlFile1">
                      Update Profile Picture
                    </label>
                    <input
                      type="file"
                      className="form-control-file"
                      id="profileImage"
                      name="profileImage"
                      ref={register}
                      onChange={(e) => e.target.files[0]}
                    />
                  </div>
                </div>
                <div
                  style={{
                    width: "60%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row mb-4">
                      <div className="col">
                        <div className="form-outline">
                          <label className="form-label">First Name</label>
                          <input
                            type="text"
                            name="fullName"
                            className="form-control"
                            placeholder="Enter your Full Name."
                            ref={register()}
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-outline">
                          <label className="form-label">Gender</label>
                          <select
                            id="gender"
                            className="form-control"
                            ref={register}
                            name="gender"
                          >
                            <option value="">Gender</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Other</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col">
                        <div className="form-outline">
                          <label className="form-label">Address</label>
                          <input
                            type="text"
                            name="address"
                            className="form-control"
                            placeholder="Enter your Address"
                            ref={register()}
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-outline">
                          <label className="form-label">Phone Number</label>
                          <input
                            type="text"
                            name="contactNo"
                            id="contactNo"
                            className="form-control"
                            ref={register()}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col">
                        <div className="form-outline">
                          <label className="form-label">E-mail</label>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            className="form-control"
                            placeholder="Enter your E-mail."
                            ref={register()}
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <button className="signupbtnLogin w-100 mt-3">
                      Update
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Loader loader={loader} />
      <section id="profilePage"></section>
    </>
  );
};
const mapStateToProps = (state) => ({
  getOwnerInfo: state.getOwnerInfo.getOwnerInfo,
  refresh: state.refresh.refresh.refresh,
  refreshFunction: state.refresh.refresh.refreshFunction,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
