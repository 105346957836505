import React, { useState, useEffect } from "react";
import { Input, Tooltip } from "antd";
import { Link } from "react-router-dom";
import { MdDeleteForever } from "react-icons/md";
import PaginationFunction from "../Pagination/Pagination";
import "./admin.css";
import { FiLogOut } from "react-icons/fi";
import { connect } from "react-redux";
import { create, read, deleteData } from "../Axios/axiosService";
import Loader from "../service/loader/loader";
import notify from "../Notify/notify";
import { TiTick } from "react-icons/ti";
import $ from "jquery";
import { FaEye, FaDownload } from "react-icons/fa";
import { FcCancel} from "react-icons/fc";
import { base_URL } from "../Axios/baseURL";

const AdminPanel = (props) => {
  const [type, setType] = useState("box");
  const boxListing = props?.allBoxRequest?.data?.reverse();

  const spaceRequest = props?.allSpaceRequest?.data?.reverse();
  const [requestedProperty, setRequestedProperty] = useState([]);
  const [user, setUser] = useState();
  const [host, setHost] = useState();
  const [refresh, setRefresh] = useState(false);
  const [loader, setLoader] = useState(false);
  const { Search } = Input;
  const [searchterm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(parseInt("1"));
  const [postPerPage] = useState(parseInt("6"));

  useEffect(() => {
    read("/api/getallproperty/")
      .then((res) => {
        setRequestedProperty(res.data.slice().reverse());
      })
      .catch((err) => {
        console.log(err);
      });
    read("/api/admin-control/getalluser/")
      .then((res) => {
        setUser(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    read("/api/admin-control/getallhost/")
      .then((res) => {
        setHost(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [refresh]);

  const suspendUser = (id) => {
    setLoader(true);
    const suspendId = {
      userId: id,
    };
    create("/api/admin/suspend-user", suspendId)
      .then((res) => {
        $(`#suspendUser-${id}`).modal("hide");
        setRefresh(!refresh);
        notify.showSuccess("Suspended");
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };
  const activateUser = (id) => {
    setLoader(true);
    const activateId = {
      userId: id,
    };
    create("/api/admin/active-user/", activateId)
      .then((res) => {
        console.log(res);
        $(`#activateUser-${id}`).modal("hide");
        setRefresh(!refresh);

        notify.showSuccess("Re activated the user");
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const deleteUser = (id) => {
    setLoader(true);
   
    deleteData(`/api/admin-control/removerenter/${id}`)
      .then((res) => {
        console.log(res);
        $(`#deleteUser-${id}`).modal("hide");
        setRefresh(!refresh);

        notify.showSuccess("User Deleted");
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const deleteHost = (id) => {
    console.log(id)
    setLoader(true);
    
    deleteData(`/api/admin-control/removehost/${id}`)
      .then((res) => {
        console.log(res);
        $(`#deleteHost-${id}`).modal("hide");
        setRefresh(!refresh);

        notify.showSuccess("Host Deleted");
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const suspendHost = (id) => {
    setLoader(true);
    const suspendId = {
      hostId: id,
    };
    create("/api/admin/suspend-host", suspendId)
      .then((res) => {
        console.log(res);
        $(`#suspendHost-${id}`).modal("hide");
        setRefresh(!refresh);
        notify.showSuccess("Host has been suspended");
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };
  const activateHost = (id) => {
    setLoader(true);
    const activateId = {
      hostId: id,
    };
    create("/api/admin/active-host/", activateId)
      .then((res) => {
        console.log(res);
        $(`#activateHost-${id}`).modal("hide");
        setRefresh(!refresh);

        notify.showSuccess("Host has been re-activated");
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const filteredRequestedData = requestedProperty.filter(
    (arg) => arg.isVerified === null
  );
  // console.log(filteredRequestedData);
  const filterData =
    type === "box"
      ? boxListing?.filter((item) =>
          searchterm === ""
            ? item
            : item?.plan_type
                ?.toLowerCase()
                ?.includes(searchterm?.toLowerCase()) ||
              item?.requested_by?.name
                ?.toLowerCase()
                ?.includes(searchterm?.toLowerCase()) ||
              item?.storing_item
                ?.toLowerCase()
                ?.includes(searchterm?.toLowerCase()) ||
              item?.booking_status
                ?.toLowerCase()
                ?.includes(searchterm?.toLowerCase())
        )
      : type === "space"
      ? spaceRequest?.filter((item) =>
          searchterm === ""
            ? item
            : item?.plan_type
                ?.toLowerCase()
                ?.includes(searchterm?.toLowerCase()) ||
              item?.requested_by?.name
                ?.toLowerCase()
                ?.includes(searchterm?.toLowerCase()) ||
              item?.storing_item
                ?.toLowerCase()
                ?.includes(searchterm?.toLowerCase()) ||
              item?.booking_status
                ?.toLowerCase()
                ?.includes(searchterm?.toLowerCase())
        )
      : type === "hostRequest"
      ? filteredRequestedData?.filter((item) =>
          searchterm === ""
            ? item
            : item?.plan_type
                ?.toLowerCase()
                ?.includes(searchterm?.toLowerCase()) ||
              item?.owner?.fullName
                ?.toLowerCase()
                ?.includes(searchterm?.toLowerCase()) ||
              item?.storing_item
                ?.toLowerCase()
                ?.includes(searchterm?.toLowerCase()) ||
              item?.booking_status
                ?.toLowerCase()
                ?.includes(searchterm?.toLowerCase())
        )
      : type === "users"
      ? user?.filter((item) =>
          searchterm === ""
            ? item
            : item?.name?.toLowerCase()?.includes(searchterm?.toLowerCase())
        )
      : type === "hosts"
      ? host?.filter((item) =>
          searchterm === ""
            ? item
            : item?.fullName?.toLowerCase()?.includes(searchterm?.toLowerCase())
        )
      : null;

  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;

  const currentListing = filterData?.slice(indexOfFirstPost, indexOfLastPost);
  const paginateFunction = (pageNo) => {
    setCurrentPage(pageNo);
  };

  const logout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  function forceDownload(url, fileName) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = function () {
      var urlCreator = window.URL || window.webkitURL;
      var imageUrl = urlCreator.createObjectURL(this.response);
      var tag = document.createElement("a");
      tag.href = imageUrl;
      tag.download = fileName;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
    };
    xhr.send();
  }

  return (
    <>
      <section id="admin-panel">
        <div className="d-flex justify-content-between px-5 py-2">
          <div className="col-lg-7 text-right">
            <h1>Admin Panel </h1>
          </div>
          <div className="col-lg-4">
            <Link
              onClick={logout}
              className="signupbtnLogin1"
              style={{ float: "right" }}
            >
              <span>
                Logout &nbsp;
                <FiLogOut />
              </span>
            </Link>
          </div>
        </div>
        <hr />
        <div className="container">
          <ul className="nav nav-pills nav-fill navtop">
            <li className="nav-item mb-2 mb-lg-0">
              <a
                onClick={() => setType("box")}
                className="nav-link active"
                href="#menu1"
                data-toggle="tab"
              >
                Box Requests ({boxListing?.length})
              </a>
            </li>
            <li className="nav-item mb-2 mb-lg-0">
              <a
                onClick={() => setType("space")}
                className="nav-link"
                href="#menu2"
                data-toggle="tab"
              >
                Space Requests ({spaceRequest?.length})
              </a>
            </li>
            <li className="nav-item mb-2 mb-lg-0  ">
              <a
                onClick={() => setType("hostRequest")}
                className="nav-link"
                href="#menu3"
                data-toggle="tab"
              >
                Property Request ({filteredRequestedData?.length})
              </a>
            </li>
            <li className="nav-item mb-2 mb-0">
              <a
                onClick={() => setType("users")}
                className="nav-link"
                href="#menu4"
                data-toggle="tab"
              >
                Users ({user?.length})
              </a>
            </li>
            <li className="nav-item">
              <a
                onClick={() => setType("hosts")}
                className="nav-link"
                href="#menu5"
                data-toggle="tab"
              >
                Hosts ({host?.length})
              </a>
            </li>
          </ul>
          <div className="tab-content">
            {type === "box" ? (
              <div className="tab-pane active" role="tabpanel" id="menu1">
                <div className="dashboard-admin col-md-12 col-sm-12">
                  <div className="topbar d-flex justify-content-between flex-wrap mt-4 mb-4">
                    <div className="col-lg-5 col-md-12">
                      <h5 className="pl-3">Manage Box Requests</h5>
                    </div>
                    <div className="col-lg-4 col-md-12 ">
                      <Search
                        placeholder="Search Requests"
                        allowClear
                        enterButton
                        size="large"
                        onChange={(e) => {
                          setSearchTerm(e.target.value);
                        }}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div className="mylistings table-responsive">
                    {currentListing?.length > 0 ? (
                      <>
                        <table className="table  table-hover text-center table-striped text-capitalize">
                          <thead>
                            <tr>
                              <th scope="col">S.N.</th>
                              <th scope="col">Requested By</th>
                              <th scope="col">Storing Item</th>
                              <th scope="col">Box Size</th>
                              <th scope="col">Plan Type</th>
                              <th scope="col">Start Date</th>
                              <th scope="col">Is Fragile</th>
                              <th scope="col">Payment Status</th>
                              <th scope="col">Status</th>
                            </tr>
                          </thead>

                          {currentListing?.map((item, index) => (
                            <>
                              <tbody key="index">
                                <tr>
                                  <th scope="row">
                                    {indexOfFirstPost + index + 1}
                                  </th>
                                  <td>
                                    <Link to={`/request/${item._id}`}>
                                      {" "}
                                      {item?.requested_by?.name}
                                    </Link>
                                  </td>
                                  <td>{item?.storing_item}</td>
                                  <td>
                                    <b style={{ color: "#1D6ABA" }}>
                                      {item?.box_size}
                                    </b>
                                    <br />
                                  </td>
                                  <td style={{ textTransform: "capitalize" }}>
                                    <small> {item?.plan_type}</small>
                                  </td>
                                  <td style={{ textTransform: "capitalize" }}>
                                    <small>
                                      {new Date(
                                        item?.createdAt
                                      ).toLocaleDateString()}
                                    </small>
                                  </td>
                                  <td>
                                    {item?.isFragile === true ? "Yes" : "No"}
                                  </td>
                                  <td>
                                    {item?.isPaid === false
                                      ? "Not Paid"
                                      : "Paid"}
                                  </td>
                                  <td>
                                    {item?.booking_status === "booked" ? (
                                      <h5 className="accepted">Booked</h5>
                                    ) : item?.booking_status ===
                                      "payment pending" ? (
                                      <h5 className="pending">
                                        Payment Pending
                                      </h5>
                                    ) : item?.booking_status === "rejected" ? (
                                      <h5 className="rejected">Rejected</h5>
                                    ) : item?.booking_status === "requested" ? (
                                      <h5 className="requested">Requested</h5>
                                    ) : (
                                      <h5 className="cancel">Cancelled</h5>
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </>
                          ))}
                        </table>

                        <PaginationFunction
                          postPerPage={postPerPage}
                          totalPost={filterData.length}
                          paginate={paginateFunction}
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                        />
                      </>
                    ) : (
                      <h3 className="text-center p-4">No Results.</h3>
                    )}
                  </div>
                </div>
              </div>
            ) : type === "space" ? (
              <div className="tab-pane" role="tabpanel" id="menu2">
                <div className="dashboard-admin">
                  <div className="topbar d-flex justify-content-between flex-wrap mt-4 mb-4">
                    <div className="col-lg-5 col-md-12">
                      <h5 className="pl-3">Manage Space Requests</h5>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <Search
                        placeholder="Search Requests"
                        allowClear
                        enterButton
                        size="large"
                        onChange={(e) => {
                          setSearchTerm(e.target.value);
                        }}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div className="mylistings table-responsive">
                    {currentListing?.length > 0 ? (
                      <>
                        <table className="table  table-hover text-center table-striped text-capitalize">
                          <thead>
                            <tr>
                              <th scope="col">S.N.</th>
                              <th scope="col">Requested By</th>
                              <th scope="col">Storing Item</th>
                              <th scope="col">Space Size</th>
                              <th scope="col">Plan Type</th>
                              <th scope="col">Start Date</th>
                              <th scope="col">Is Fragile</th>
                              <th scope="col">Payment Status</th>
                              <th scope="col">Status</th>
                            </tr>
                          </thead>

                          {currentListing?.map((item, index) => (
                            <>
                              <tbody key="index">
                                <tr>
                                  <th scope="row">
                                    {indexOfFirstPost + index + 1}
                                  </th>
                                  <td>
                                    <Link to={`/request/${item._id}`}>
                                      {" "}
                                      {item?.requested_by?.name}
                                    </Link>
                                  </td>
                                  <td>{item?.storing_item}</td>
                                  <td>
                                    <b style={{ color: "#1D6ABA" }}>
                                      {item?.space_size}
                                    </b>
                                    <br />
                                  </td>
                                  <td style={{ textTransform: "capitalize" }}>
                                    <small> {item?.plan_type}</small>
                                  </td>
                                  <td style={{ textTransform: "capitalize" }}>
                                    <small>
                                      {new Date(
                                        item?.createdAt
                                      ).toLocaleDateString()}
                                    </small>
                                  </td>
                                  <td>
                                    {item?.isFragile === true ? "Yes" : "No"}
                                  </td>
                                  <td>
                                    {item?.isPaid === false
                                      ? "Not Paid"
                                      : "Paid"}
                                  </td>
                                  <td>
                                    {item?.booking_status === "booked" ? (
                                      <h5 className="accepted">Booked</h5>
                                    ) : item?.booking_status ===
                                      "payment pending" ? (
                                      <h5 className="pending">Pending</h5>
                                    ) : item?.booking_status === "rejected" ? (
                                      <h5 className="rejected">Rejected</h5>
                                    ) : item?.booking_status === "requested" ? (
                                      <h5 className="requested">Requested</h5>
                                    ) : (
                                      <h5 className="cancel">Cancelled</h5>
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </>
                          ))}
                        </table>

                        <PaginationFunction
                          postPerPage={postPerPage}
                          totalPost={filterData.length}
                          paginate={paginateFunction}
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                        />
                      </>
                    ) : (
                      <h3 className="text-center p-4">No Results.</h3>
                    )}
                  </div>
                </div>
              </div>
            ) : type === "hostRequest" ? (
              <div className="tab-pane" role="tabpanel" id="menu3">
                <div className="dashboard-admin">
                  <div className="topbar d-flex justify-content-between flex-wrap mt-4 mb-4">
                    <div className="col-lg-5 col-md-12">
                      <h5 className="pl-3">Manage Property Requests</h5>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <Search
                        placeholder="Search Requests"
                        allowClear
                        enterButton
                        size="large"
                        onChange={(e) => {
                          setSearchTerm(e.target.value);
                        }}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div className="mylistings table-responsive">
                    {currentListing?.length > 0 ? (
                      <>
                        <table className="table  table-hover text-center table-striped text-capitalize">
                          <thead>
                            <tr>
                              <th scope="col">S.N.</th>
                              <th scope="col">Requested By</th>
                              <th scope="col">Title</th>
                              <th scope="col">Listing Type</th>
                              <th scope="col">E-mail</th>
                              <th scope="col">Phone Number</th>
                              <th scope="col">Address</th>
                            </tr>
                          </thead>

                          {currentListing?.map((item, index) => (
                            <>
                              <tbody key="index">
                                <tr>
                                  <th scope="row">
                                    {indexOfFirstPost + index + 1}
                                  </th>
                                  <td>{item?.owner?.fullName}</td>
                                  <td>
                                    <Link
                                      to={`/host-request-detail/${item?._id}`}
                                    >
                                      {item?.title}
                                    </Link>
                                  </td>
                                  <td>{item?.typeOfListing}</td>
                                  <td>
                                    <b
                                      style={{
                                        textTransform: "lowercase",
                                      }}
                                    >
                                      {item?.owner?.email}
                                    </b>
                                    <br />
                                  </td>
                                  <td style={{ textTransform: "capitalize" }}>
                                    <small> {item?.owner?.contactNo}</small>
                                  </td>
                                  <td style={{ textTransform: "capitalize" }}>
                                    <small>
                                      {new Date(
                                        item?.createdAt
                                      ).toLocaleDateString()}
                                    </small>
                                  </td>
                                  <td></td>
                                </tr>
                              </tbody>
                            </>
                          ))}
                        </table>

                        <PaginationFunction
                          postPerPage={postPerPage}
                          totalPost={filterData.length}
                          paginate={paginateFunction}
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                        />
                      </>
                    ) : (
                      <h3 className="text-center p-4">No Requests till now.</h3>
                    )}
                  </div>
                </div>
              </div>
            ) : type === "users" ? (
              <div className="tab-pane" role="tabpanel" id="menu4">
                <div className="dashboard-admin">
                  <div className="topbar d-flex justify-content-between flex-wrap mt-4 mb-4">
                    <div className="col-lg-5 col-md-12">
                      <h5 className="pl-3">Manage Users</h5>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <Search
                        placeholder="Search User by Name"
                        allowClear
                        enterButton
                        size="large"
                        onChange={(e) => {
                          setSearchTerm(e.target.value);
                        }}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div className="mylistings table-responsive">
                    {user?.length > 0 ? (
                      <>
                        <table className="table  table-hover text-center table-striped text-capitalize">
                          <thead>
                            <tr>
                              <th scope="col">S.N.</th>
                              <th scope="col">Name</th>
                              <th scope="col">Address</th>
                              <th scope="col">E-mail</th>
                              <th scope="col">Phone</th>
                              <th scope="col">ID Image</th>
                              <th scope="col">Joined</th>
                              <th scope="col">Status</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>

                          {currentListing?.map((item, index) => (
                            <>
                              <tbody key="index">
                                <tr>
                                  <th scope="row">
                                    {indexOfFirstPost + index + 1}
                                  </th>
                                  <td>{item?.name}</td>
                                  <td>
                                    <small> {item?.address}</small>
                                  </td>
                                  <td>
                                    <b
                                      style={{
                                        textTransform: "lowercase",
                                      }}
                                    >
                                      <small>{item?.email}</small>
                                    </b>
                                    <br />
                                  </td>
                                  <td>
                                    <small> {item?.phone}</small>
                                  </td>
                                  <td>
                                    <Link
                                      data-toggle="modal"
                                      data-target={`#showId-${item._id}`}
                                    >
                                      <small>
                                        {" "}
                                        <FaEye /> View Image
                                      </small>
                                    </Link>
                                  </td>
                                  <td>
                                    {new Date(
                                      item?.createdAt
                                    ).toLocaleDateString()}
                                  </td>

                                  <td>
                                    <small>
                                      {item?.status === "active" ? (
                                        <h5 className="accepted">Active</h5>
                                      ) : (
                                        <h5 className="rejected">Suspended</h5>
                                      )}
                                    </small>
                                  </td>
                                  <td>
                                    <Tooltip title="Delete the user">
                                    <button
                                        // onClick={() =>
                                        //   activateUser(item?._id)
                                        // }
                                        className="manage-button"
                                        data-toggle="modal"
                                        data-target={`#deleteUser-${item._id}`}
                                      >
                                        <MdDeleteForever className="icon" />
                                      </button>
                                      </Tooltip>

                                    {item?.status === "active" ? (
                                      <Tooltip title="Suspend the user">
                                        <button
                                          // onClick={() => suspendUser(item?._id)}
                                          className="manage-button"
                                          data-toggle="modal"
                                          data-target={`#suspendUser-${item._id}`}
                                        >
                                          <FcCancel />
                                          {/* <MdDeleteForever className="icon" /> */}
                                        </button>
                                      </Tooltip>
                                    ) : (
                                      <Tooltip title="Activate the user">
                                        <button
                                          // onClick={() =>
                                          //   activateUser(item?._id)
                                          // }
                                          className="manage-button"
                                          data-toggle="modal"
                                          data-target={`#activateUser-${item._id}`}
                                        >
                                          <TiTick className="icon" />
                                        </button>
                                      </Tooltip>
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                              {/* national id image modal */}
                              <div
                                className="main-modal modal fade"
                                id={`showId-${item._id}`}
                                tabindex="-1"
                                role="dialog"
                                aria-labelledby="suspendUser"
                                aria-hidden="true"
                              >
                                <div
                                  className="modal-dialog modal-dialog-centered"
                                  role="document"
                                >
                                  <div className="modal-content">
                                    <div className="modal-header text-center ">
                                      <h5 className="modal-title ml-auto">
                                        National Id Image
                                      </h5>
                                      <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                      >
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                    </div>
                                    <div className="modal-body">
                                      <img
                                        src={
                                          `${base_URL}/file/images/` +
                                          item?.national_id_image[0]
                                        }
                                        alt=""
                                        style={{ border: "1px solid grey" }}
                                        width="200px"
                                      />
                                      {item?.national_id_image[0] ? (
                                        <a
                                          href="#"
                                          onClick={() =>
                                            forceDownload(
                                              `${base_URL}/file/images/` +
                                                item?.national_id_image[0],
                                              "ID Document"
                                            )
                                          }
                                        >
                                          <br />
                                          Download
                                          <FaDownload />
                                        </a>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* //Delete User modal body// */}
                              <div
                                className="main-modal modal fade"
                                id={`deleteUser-${item._id}`}
                                tabindex="-1"
                                role="dialog"
                                aria-labelledby="deleteUser"
                                aria-hidden="true"
                              >
                                <div
                                  className="modal-dialog modal-dialog-centered"
                                  role="document"
                                >
                                  <div className="modal-content">
                                    <div className="modal-header text-center ">
                                      <h5 className="modal-title ml-auto">
                                        Do you want to delete this User?
                                      </h5>
                                      <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                      >
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                    </div>
                                    <div className="modal-footer  justify-content-center">
                                      <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => deleteUser(item?._id)}
                                      >
                                        Yes
                                      </button>

                                      <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-dismiss="modal"
                                      >
                                        No
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              

                              {/* //Suspend User modal body// */}
                              <div
                                className="main-modal modal fade"
                                id={`suspendUser-${item._id}`}
                                tabindex="-1"
                                role="dialog"
                                aria-labelledby="suspendUser"
                                aria-hidden="true"
                              >
                                <div
                                  className="modal-dialog modal-dialog-centered"
                                  role="document"
                                >
                                  <div className="modal-content">
                                    <div className="modal-header text-center ">
                                      <h5 className="modal-title ml-auto">
                                        Do you want to suspend this User?
                                      </h5>
                                      <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                      >
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                    </div>
                                    <div className="modal-footer  justify-content-center">
                                      <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => suspendUser(item?._id)}
                                      >
                                        Yes
                                      </button>

                                      <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-dismiss="modal"
                                      >
                                        No
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* //activate user modal body// */}
                              <div
                                className="modal fade"
                                id={`activateUser-${item._id}`}
                                tabindex="-1"
                                role="dialog"
                                aria-labelledby="activateUser"
                                aria-hidden="true"
                              >
                                <div
                                  className="modal-dialog modal-dialog-centered"
                                  role="document"
                                >
                                  <div className="modal-content">
                                    <div className="modal-header text-center ">
                                      <h5 className="modal-title ml-auto">
                                        Do you want to reactivate this User?
                                      </h5>
                                      <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                      >
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                    </div>
                                    <div className="modal-footer  justify-content-center">
                                      <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => activateUser(item?._id)}
                                      >
                                        Yes
                                      </button>

                                      <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-dismiss="modal"
                                      >
                                        No
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                        </table>

                        <PaginationFunction
                          postPerPage={postPerPage}
                          totalPost={user.length}
                          paginate={paginateFunction}
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                        />
                      </>
                    ) : (
                      <h3 className="text-center p-4"> No Results.</h3>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="tab-pane" role="tabpanel" id="menu5">
                <div className="dashboard-admin">
                  <div className="topbar d-flex justify-content-between flex-wrap mt-4 mb-4">
                    <div className="col-lg-5 col-md-12">
                      <h5 className="pl-3 ">Manage Hosts</h5>
                    </div>
                    <div className="col-lg-4 col-md-12 ">
                      <Search
                        placeholder="Search Host by Name"
                        allowClear
                        enterButton
                        size="large"
                        onChange={(e) => {
                          setSearchTerm(e.target.value);
                        }}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div className="mylistings table-responsive">
                    {host?.length > 0 ? (
                      <>
                        <table className="table  table-hover text-center table-striped text-capitalize">
                          <thead>
                            <tr>
                              <th scope="col">S.N.</th>
                              <th scope="col">Name</th>
                              <th scope="col">Address</th>
                              <th scope="col">E-mail</th>
                              <th scope="col">Phone</th>
                              <th scope="col">ID Image</th>
                              <th scope="col">Joined</th>
                              <th scope="col">Status</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          {console.log(host)}
                          {currentListing?.map((item, index) => (
                            <>
                              <tbody key="index">
                                <tr>
                                  <th scope="row">
                                    {indexOfFirstPost + index + 1}
                                  </th>
                                  <td>{item?.fullName}</td>
                                  <td>
                                    <small> {item?.address}</small>
                                  </td>
                                  <td>
                                    <b
                                      style={{
                                        textTransform: "lowercase",
                                      }}
                                    >
                                      <small>{item?.email}</small>
                                    </b>
                                    <br />
                                  </td>
                                  <td>
                                    <small> {item?.contactNo}</small>
                                  </td>

                                  <td>
                                    <Link
                                      data-toggle="modal"
                                      data-target={`#showId-${item._id}`}
                                    >
                                      <small>
                                        {" "}
                                        <FaEye /> View Image
                                      </small>
                                    </Link>
                                  </td>
                                  <td>
                                    <small>
                                      {new Date(
                                        item?.createdAt
                                      ).toLocaleDateString()}
                                    </small>
                                  </td>
                                  <td>
                                    <small>
                                      {item?.status === "active" ? (
                                        <h5 className="accepted">Active</h5>
                                      ) : (
                                        <h5 className="rejected">Suspended</h5>
                                      )}
                                    </small>
                                  </td>
                                  <td>
                                    <Tooltip title="Delete the host">
                                      <button
                                        // onClick={() =>
                                        //   activateUser(item?._id)
                                        // }
                                        className="manage-button"
                                        data-toggle="modal"
                                        data-target={`#deleteHost-${item._id}`}
                                      >
                                        <MdDeleteForever className="icon" />
                                      </button>
                                    </Tooltip>
                                    {item?.status === "active" ? (
                                      <Tooltip title="Suspend the Host">
                                        <button
                                          // onClick={() => suspendHost(item?._id)}
                                          className="manage-button"
                                          data-toggle="modal"
                                          data-target={`#suspendHost-${item._id}`}
                                        >
                                          <FcCancel className="icon" />
                                        </button>
                                      </Tooltip>
                                    ) : (
                                      <Tooltip title="Activate the Host">
                                        <button
                                          // onClick={() =>
                                          //   activateHost(item?._id)
                                          // }
                                          className="manage-button"
                                          data-toggle="modal"
                                          data-target={`#activateHost-${item._id}`}
                                        >
                                          <TiTick className="icon" />
                                        </button>
                                      </Tooltip>
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                              {/* national id image modal */}
                              <div
                                className="main-modal modal fade"
                                id={`showId-${item._id}`}
                                tabindex="-1"
                                role="dialog"
                                aria-labelledby="suspendUser"
                                aria-hidden="true"
                              >
                                <div
                                  className="modal-dialog modal-dialog-centered"
                                  role="document"
                                >
                                  <div className="modal-content">
                                    <div className="modal-header text-center ">
                                      <h5 className="modal-title ml-auto">
                                        National Id Image
                                      </h5>
                                      <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                      >
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                    </div>
                                    <div className="modal-body">
                                      <img
                                        src={
                                          `${base_URL}/file/images/` +
                                          item?.national_id_image[0]
                                        }
                                        alt=""
                                        width="200px"
                                      />
                                      {item?.national_id_image[0] ? (
                                        <a
                                          href="#"
                                          onClick={() =>
                                            forceDownload(
                                              `${base_URL}/file/images/` +
                                                item?.national_id_image[0],
                                              "ID Document"
                                            )
                                          }
                                        >
                                          <br />
                                          Download
                                          <FaDownload />
                                        </a>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* suspend host */}
                              <div
                                className="modal fade"
                                id={`suspendHost-${item._id}`}
                                tabindex="-1"
                                role="dialog"
                                aria-labelledby="suspendHost"
                                aria-hidden="true"
                              >
                                <div
                                  className="modal-dialog modal-dialog-centered"
                                  role="document"
                                >
                                  <div className="modal-content">
                                    <div className="modal-header text-center ">
                                      <h5 className="modal-title ml-auto">
                                        Do you want to suspend this Host?
                                      </h5>
                                      <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                      >
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                    </div>
                                    <div className="modal-footer  justify-content-center">
                                      <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => suspendHost(item?._id)}
                                      >
                                        Yes
                                      </button>

                                      <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-dismiss="modal"
                                      >
                                        No
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* //Delete Host modal body// */}
                              <div
                                className="main-modal modal fade"
                                id={`deleteHost-${item._id}`}
                                tabindex="-1"
                                role="dialog"
                                aria-labelledby="deleteHost"
                                aria-hidden="true"
                              >
                                <div
                                  className="modal-dialog modal-dialog-centered"
                                  role="document"
                                >
                                  <div className="modal-content">
                                    <div className="modal-header text-center ">
                                      <h5 className="modal-title ml-auto">
                                        Do you want to delete this host?
                                      </h5>
                                      <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                      >
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                    </div>
                                    <div className="modal-footer  justify-content-center">
                                      <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => deleteHost(item?._id)}
                                      >
                                        Yes
                                      </button>

                                      <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-dismiss="modal"
                                      >
                                        No
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              
                              {/* suspend host */}
                              <div
                                className="modal fade"
                                id={`activateHost-${item._id}`}
                                tabindex="-1"
                                role="dialog"
                                aria-labelledby="activateHost"
                                aria-hidden="true"
                              >
                                <div
                                  className="modal-dialog modal-dialog-centered"
                                  role="document"
                                >
                                  <div className="modal-content">
                                    <div className="modal-header text-center ">
                                      <h5 className="modal-title ml-auto">
                                        Do you want to activate this Host?
                                      </h5>
                                      <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                      >
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                    </div>
                                    <div className="modal-footer  justify-content-center">
                                      <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => activateHost(item?._id)}
                                      >
                                        Yes
                                      </button>

                                      <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-dismiss="modal"
                                      >
                                        No
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                        </table>

                        <PaginationFunction
                          postPerPage={postPerPage}
                          totalPost={host.length}
                          paginate={paginateFunction}
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                        />
                      </>
                    ) : (
                      <h3 className="text-center p-4"> No Results.</h3>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <Loader loader={loader} />
      </section>
    </>
  );
};

const mapStateToProps = (state) => ({
  allSpaceRequest: state.allSpaceRequest.allSpaceRequest,
  allBoxRequest: state.allBoxRequest.allBoxRequest,
  refresh: state.refresh.refresh.refresh,
  refreshFunction: state.refresh.refresh.refreshFunction,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AdminPanel);
